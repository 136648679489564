import { Input, Form } from 'antd';
import { Group } from 'antd/lib/avatar';
import Label from 'components/commons/Label';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  initialValue?: string;
  hidden?: boolean;
  control: Control;
  errors: FieldErrors;
}

const TextField = ({
  name,
  label,
  initialValue,
  hidden,
  control,
  errors,
}: Props): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={initialValue}
      render={(props) => (
        <Form.Item
          hidden={hidden}
          validateStatus={errors[name] ? 'error' : ''}
          help={errors[name]?.message}
        >
          <Group>
            <Label>{label}</Label>
          </Group>
          <Input {...props} size="middle" />
        </Form.Item>
      )}
    />
  );
};

export default TextField;
