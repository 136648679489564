import React from 'react';

const CommentAddSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5905 4.71368C11.3361 5.11104 11.1306 5.54284 10.9828 6.00052L5.78571 6.00003C4.95729 6.00003 4.28571 6.67161 4.28571 7.50005V14.7858C4.28571 15.6143 4.95729 16.2859 5.78571 16.2859H7.71324L7.71416 19.5007L12.0116 16.2859H17.3571C18.1856 16.2859 18.8571 15.6143 18.8571 14.7858L18.8581 12.6577C19.3303 12.4115 19.7628 12.0996 20.1436 11.7341L20.1429 14.7858C20.1429 16.3244 18.8957 17.5716 17.3571 17.5716H12.4393L8.14173 20.7865C7.66787 21.1409 6.99643 21.0441 6.64201 20.5702C6.50345 20.385 6.42857 20.1598 6.42857 19.9287L6.4279 17.5716H5.78571C4.24721 17.5716 3 16.3244 3 14.7858V7.50005C3 5.96153 4.24721 4.7143 5.78571 4.7143L11.5905 4.71368ZM16.2857 3C18.8893 3 21 5.11068 21 7.71434C21 10.318 18.8893 12.4287 16.2857 12.4287C13.6821 12.4287 11.5714 10.318 11.5714 7.71434C11.5714 5.11068 13.6821 3 16.2857 3ZM16.2857 4.28573L16.2087 4.29263C16.0337 4.32439 15.8958 4.46233 15.864 4.63727L15.8571 4.7143L15.8563 7.28576H13.2857L13.2087 7.29267C13.0337 7.32442 12.8958 7.46236 12.864 7.6373L12.8571 7.71434L12.864 7.79138C12.8958 7.96631 13.0337 8.10426 13.2087 8.13601L13.2857 8.14291H15.8563L15.8571 10.7144L15.864 10.7914C15.8958 10.9663 16.0337 11.1043 16.2087 11.136L16.2857 11.1429L16.3628 11.136C16.5377 11.1043 16.6756 10.9663 16.7074 10.7914L16.7143 10.7144L16.7134 8.14291H19.2857L19.3628 8.13601C19.5377 8.10426 19.6756 7.96631 19.7074 7.79138L19.7143 7.71434L19.7074 7.6373C19.6756 7.46236 19.5377 7.32442 19.3628 7.29267L19.2857 7.28576H16.7134L16.7143 4.7143L16.7074 4.63727C16.6756 4.46233 16.5377 4.32439 16.3628 4.29263L16.2857 4.28573Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default CommentAddSvg;
