import React from 'react';

interface Props extends React.ComponentPropsWithoutRef<'label'> {
  className?: string;
}

const Label: React.FC<Props> = ({
  htmlFor,
  children,
  className = '',
  ...rest
}) => (
  <label htmlFor={htmlFor} className={`wac-label ${className}`} {...rest}>
    {children}
  </label>
);

export default Label;
