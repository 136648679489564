import React from 'react';

const ProceduralAnalysisSvg = (): React.ReactElement => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8949 -0.000152588H2.58681C1.1604 -0.000152588 0 1.17739 0 2.62513V17.3744C0 18.822 1.16019 19.9997 2.58681 19.9997H13.1781C14.6043 19.9997 15.7647 18.8222 15.7647 17.3744V5.52416L10.8949 -0.000152588ZM11.2355 2.26257L13.7701 5.13763H11.2355V2.26257ZM13.1781 18.7488H2.58681C1.83992 18.7488 1.2325 18.1323 1.2325 17.3744V2.62513C1.2325 1.86728 1.83992 1.25078 2.58681 1.25078H10.003V6.38856H14.5322V17.3744C14.5322 18.1323 13.9248 18.7488 13.1781 18.7488Z"
      fill="#828282"
    />
    <rect x="3" y="5.99985" width="5.28" height="1.21" fill="#828282" />
    <rect x="3" y="7.80984" width="5.28" height="1.21" fill="#828282" />
    <rect x="3" y="9.61984" width="3.65" height="1.21" fill="#828282" />
    <path
      d="M3 14.9998L4.5 16.4998L7 12.9998L10 16.9998L13 12.9998"
      stroke="#4F4F4F"
      strokeWidth="1.4"
    />
  </svg>
);

export default ProceduralAnalysisSvg;
