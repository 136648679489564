import React from 'react';

const LinkSvg = (): React.ReactElement => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.454 12.5961C10.4187 12.5612 10.3711 12.5416 10.3215 12.5416C10.2719 12.5416 10.2244 12.5612 10.1891 12.5961L7.46577 15.3194C6.20488 16.5803 4.07682 16.7139 2.68468 15.3194C1.29019 13.9249 1.42378 11.7992 2.68468 10.5383L5.40803 7.81498C5.48068 7.74233 5.48068 7.6228 5.40803 7.55015L4.47524 6.61736C4.44001 6.58247 4.39242 6.5629 4.34283 6.5629C4.29324 6.5629 4.24565 6.58247 4.21041 6.61736L1.48706 9.34071C-0.495687 11.3235 -0.495687 14.5319 1.48706 16.5124C3.46981 18.4928 6.6783 18.4951 8.6587 16.5124L11.3821 13.789C11.4547 13.7164 11.4547 13.5968 11.3821 13.5242L10.454 12.5961ZM16.5147 1.48706C14.5319 -0.495687 11.3235 -0.495687 9.34306 1.48706L6.61736 4.21041C6.58247 4.24565 6.5629 4.29324 6.5629 4.34283C6.5629 4.39242 6.58247 4.44001 6.61736 4.47524L7.5478 5.40568C7.62046 5.47834 7.73998 5.47834 7.81264 5.40568L10.536 2.68233C11.7969 1.42144 13.9249 1.28785 15.3171 2.68233C16.7116 4.07682 16.578 6.20253 15.3171 7.46343L12.5937 10.1868C12.5588 10.222 12.5393 10.2696 12.5393 10.3192C12.5393 10.3688 12.5588 10.4164 12.5937 10.4516L13.5265 11.3844C13.5992 11.4571 13.7187 11.4571 13.7913 11.3844L16.5147 8.66105C18.4951 6.6783 18.4951 3.46981 16.5147 1.48706ZM11.3 5.72677C11.2648 5.69187 11.2172 5.6723 11.1676 5.6723C11.118 5.6723 11.0704 5.69187 11.0352 5.72677L5.72677 11.0328C5.69187 11.0681 5.6723 11.1157 5.6723 11.1653C5.6723 11.2149 5.69187 11.2624 5.72677 11.2977L6.65486 12.2258C6.72752 12.2984 6.84704 12.2984 6.9197 12.2258L12.2258 6.9197C12.2984 6.84704 12.2984 6.72752 12.2258 6.65486L11.3 5.72677Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default LinkSvg;
