import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacOverlaidContent = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        OverlaidContent
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente com identificação do processo no header da aplicação, composto por:
            </div> 
            <div className='code-component'>
            {`<Text>`} {`<Box>`}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<div className="overlaid-header-content">`}
              <br />
                {`<Text className='is-white no-shrink'>`}
                <br />
                  {`Meus Processos`}
                  <br />
                {`</Text>`}
                <br />
                {`<Box className='is-header-overlaid'>`}
                <br />
                  {`5000423-69.2017.4.03.6106`}
                  <br />
                {`</Box>`}
                <br />
              {`</div>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacOverlaidContent;