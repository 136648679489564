import { Row, Col, Skeleton } from 'antd';
import { SelectValue } from 'antd/lib/select';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ContentOthers from 'screens/ProceduralFollow/components/ContentOthers';
import ContentTransactions from 'screens/ProceduralFollow/components/ContentTransactions';
import ContentProcessPart from 'screens/ProceduralFollow/components/ContentProcessPart';
import ProcessPanel from 'screens/ProceduralFollow/components/ProcessPanel';
import ContentPetitions from 'screens/ProceduralFollow/components/ContentPetitions';
import ContentUnifiedJoins from 'screens/ProceduralFollow/components/ContentUnifiedJoins';
import Sidebar from 'screens/ProceduralFollow/components/Sidebar';
import useLawsuitProgressGetDetail from 'api/queries/useLawsuitProgressGetDetail';

const ProceduraFollow = (): React.ReactElement => {
  const { url } = useRouteMatch();
  const [degree, setDegree] = useState<SelectValue>();
  const { isLoading, error, data: result } = useLawsuitProgressGetDetail({
    degree,
  });

  const onInstanceSelect = (value: SelectValue): void => setDegree(value);

  if (isLoading) {
    return (
      <Box className="is-full">
        <Skeleton active />
      </Box>
    );
  }

  if (error) {
    return <div>Erro</div>;
  }

  const lawsuit = result?.data?.lawsuit;
  const lawsuitUrl = result?.data?.url ?? '';
  const instances = result?.data?.instances ?? [];
  const currentDegree = degree ?? result?.data.currentDegree ?? '';

  const apensosLabels =
    currentDegree === 1
      ? lawsuit?.apensosEntranhadosUnificados?.labels
      : lawsuit?.apensosVinculados?.labels;

  const apensosData =
    currentDegree === 1
      ? lawsuit?.apensosEntranhadosUnificados?.dados
      : lawsuit?.apensosVinculados?.dados;

  return (
    <>
      <Box className="is-full">
        <ProcessPanel
          instances={instances}
          lawsuitNumber={lawsuit?.numero ?? ''}
          dadosProcesso={lawsuit?.dadosProcesso || []}
          onInstanceSelect={onInstanceSelect}
          degree={currentDegree}
          url={lawsuitUrl}
        />

        <Vspace />

        <Row gutter={[20, 20]}>
          <Col span={6}>
            <Sidebar degree={currentDegree} />
          </Col>
          <Col span={18}>
            <div>
              <Switch>
                <Route path={`${url}/partes-do-processo`}>
                  <ContentProcessPart data={lawsuit?.todasPartes} />
                </Route>
                <Route path={`${url}/movimentacoes`}>
                  <ContentTransactions
                    labels={lawsuit?.todosMovimentos?.labels}
                    data={lawsuit?.todosMovimentos?.dados}
                  />
                </Route>
                <Route path={`${url}/peticoes-diversas`}>
                  <ContentPetitions
                    labels={lawsuit?.peticoesDiversas?.labels}
                    data={lawsuit?.peticoesDiversas?.dados}
                  />
                </Route>
                <Route path={`${url}/apensos`}>
                  <ContentUnifiedJoins
                    labels={apensosLabels}
                    data={apensosData}
                  />
                </Route>
                <Route
                  path={`${url}/incidentes-acoes-incidentais-recursos-execuções-sentencas`}
                >
                  <ContentOthers
                    labels={lawsuit?.incidentes?.labels}
                    data={lawsuit?.incidentes?.dados}
                  />
                </Route>
                <Route path={`${url}`}>
                  <ContentProcessPart data={lawsuit?.todasPartes} />
                </Route>
              </Switch>
            </div>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default ProceduraFollow;
