import { useCallback, useEffect, useRef, useState } from 'react';
import { RECAPTCHA_PUBLIC_KEY } from 'utils/consts';

interface ReCaptchaInstance {
  ready: (cb: () => any) => any;
  execute: (options: ReCaptchaExecuteOptions) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => void;
  reset: () => void;
}

declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha: ReCaptchaInstance;
  }
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: 'invisible';
  badge: string;
}

interface CaptchaProps {
  isReady: boolean;
  executeCaptcha: () => Promise<string>;
}

const useRecaptcha = (): CaptchaProps => {
  const [isReady, setIsReady] = useState(false);
  const script = useRef<HTMLScriptElement>();
  const [widget, setWidget] = useState<HTMLDivElement>();

  const loadScript = useCallback(() => {
    window.captchaOnLoad = onLoad;

    const url = 'https://www.google.com/recaptcha/api.js';
    const queryString = '?onload=captchaOnLoad&render=explicit';
    const scriptLocal = document.createElement('script');
    scriptLocal.id = 'script-id';
    scriptLocal.type = 'text/javascript';
    scriptLocal.src = url + queryString;
    scriptLocal.async = true;
    scriptLocal.defer = true;

    script.current = document.body.appendChild(scriptLocal);
  }, []);

  const onLoad = (): void => {
    const gRecaptchaWidget = document.createElement('div');
    gRecaptchaWidget.id = 'g-recaptcha';
    setWidget(document.body.appendChild(gRecaptchaWidget));

    window.grecaptcha.ready(() => {
      window.grecaptcha.render('g-recaptcha', {
        sitekey: RECAPTCHA_PUBLIC_KEY,
        badge: 'bottomright',
        size: 'invisible',
      });
      setIsReady(true);
    });
  };

  const executeCaptcha = (): Promise<string> => {
    if (!isReady) {
      throw new Error("Captcha can be executed only when it's ready.");
    }

    return window.grecaptcha.execute({ action: 'submit' });
  };

  const cleanGrecaptcha = useCallback(() => {
    if (widget) {
      window.grecaptcha.reset();
      widget.remove();
      const _ignore = script.current?.remove();
    }
  }, [widget]);

  useEffect(() => {
    if (!widget) {
      loadScript();
    }
    return cleanGrecaptcha;
  }, [loadScript, cleanGrecaptcha, widget]);

  return {
    isReady,
    executeCaptcha,
  };
};

export default useRecaptcha;
