import React from 'react';
import { Row, Col, Select, Typography } from 'antd';

import Label from 'components/commons/Label';
import { SelectValue } from 'antd/es/select';

const { Text } = Typography;

const { Option } = Select;

interface Props {
  onInstanceSelect: (value: SelectValue) => void;
  degree: SelectValue;
  dadosProcesso: string[][];
  instances: Array<{
    degree: string;
  }>;
}

const PanelContent = ({
  onInstanceSelect,
  dadosProcesso = [],
  degree,
  instances,
}: Props): React.ReactElement => (
  <Row gutter={[0, 0]}>
    <Col span={8}>
      <Label>Instância</Label>
      <Select onChange={onInstanceSelect} className="mw186" value={degree}>
        {instances.map((instance: { degree: string }) => (
          <Option
            title={`${instance.degree}º Instância`}
            value={instance.degree}
          >
            {`${instance.degree}º Instância`}
          </Option>
        ))}
      </Select>
    </Col>
    <Col span={14}>
      <Row gutter={[16, 16]}>
        {dadosProcesso.slice(6, 12).map((dado) => (
          <Col span={12}>
            <Text>
              <strong>{`${dado[0]}: `}</strong> {`${dado[1]}`}
            </Text>
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);

export default PanelContent;
