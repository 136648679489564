import React from 'react';
import { Collapse } from 'antd';
import ProcessPanelContent from 'screens/ProceduralFollow/components/ProcessPanelContent';
import { SelectValue } from 'antd/es/select';
import GenTitleSubTitle from 'screens/ProceduralFollow/components/GenTitleSubTitle';
import GenExtra from 'screens/ProceduralFollow/components/GenExtra';

const { Panel } = Collapse;

interface Props {
  onInstanceSelect: (value: SelectValue) => void;
  degree: SelectValue;
  dadosProcesso: string[][];
  url: string;
  lawsuitNumber: string;
  instances: Array<{
    degree: string;
  }>;
}

const ProcessPanel = ({
  onInstanceSelect,
  degree,
  dadosProcesso,
  url,
  lawsuitNumber,
  instances,
}: Props): React.ReactElement => {
  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={['1']}
      /* due wrong css API in Collapse, we have to use style inline */
      style={{
        position: 'sticky',
        top: '-10px',
        zIndex: 3,
        boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.3)',
      }}
    >
      <Panel
        header={GenTitleSubTitle({ lawsuitNumber, dadosProcesso })}
        key="1"
        extra={GenExtra({ url, lawsuitNumber })}
      >
        <ProcessPanelContent
          dadosProcesso={dadosProcesso}
          onInstanceSelect={onInstanceSelect}
          degree={degree}
          instances={instances}
        />
      </Panel>
    </Collapse>
  );
};

export default ProcessPanel;
