import React from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import { BlockedIcon } from 'components/icons';
import Group from 'components/custom/Group';

const { Paragraph } = Typography;

interface State {
  visible: boolean;
}

/** Class need at least one props due typescript * */
interface Props {
  children?: React.ReactElement;
}

class ModalDelete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { visible: false };
  }

  showModal = (): void => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (): void => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (): void => {
    this.setState({
      visible: false,
    });
  };

  render(): React.ReactNode {
    const { visible } = this.state;
    return (
      <>
        <BlockedIcon onClick={this.showModal} />
        <Modal
          title="Recorte incorreto"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={
            <Group className="is-right">
              <Button className="is-secondary">Cancelar</Button>
              <Button className="is-primary">Enviar comentário</Button>
            </Group>
          }
        >
          <Paragraph>
            Ajude-nos a melhorar o Outprocess. Insira um comentário para que
            possamos com mais clareza compreender o motivo pelo qual você
            recebeu este recorte indevidamente.
          </Paragraph>
          <Input placeholder="Insira um comentário" />
        </Modal>
      </>
    );
  }
}

export default ModalDelete;
