import { LoadingOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import React from 'react';

interface Props {
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  children: React.ReactNode[] | React.ReactNode;
}

const style = { minWidth: 120 };

const Button = ({
  htmlType,
  loading = false,
  children,
  onClick,
  disabled = false,
  ...props
}: Props): React.ReactElement => {
  return (
    <AntButton
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      style={style}
      {...props}
    >
      {loading ? <LoadingOutlined /> : children}
    </AntButton>
  );
};

export default Button;
