import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacCardContentHead = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
      CardContentHead
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente filho do CardContent com informação do processo relacionado e toolbar, composto por:
            </div> 
            <div className='code-component'>
              {`<Group>`} {`<GroupChild>`} {`<Text>`} {`<Tooltip>`} {`<Box>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<Group className='is-spread'>`}
              <br />
                {`<GroupChild>`}
                <br />
                  {`<AlertCircleIcon />`}
                  <br />
                  {`<Text className='is-size-13'>`}
                  <br />
                    {`Possui 03 Recortes relacionados.`}
                    <br />
                  {`</Text>`}
                  <br />
                {`</GroupChild>`}
                <br />
                <br />
                {`<GroupChild>`}
                <br />
                  {`{itemHeadData.map(({ title, content }) => (`}
                  <br />
                  {`<Tooltip mouseEnterDelay={0.7} title={title}>`}
                  <br />
                    {`{content}`}
                    <br />
                  {`</Tooltip>`}
                  <br />
                  {`))}`}
                  <br />
                {`</GroupChild>`}
                <br />
              {`</Group>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCardContentHead;