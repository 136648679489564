import React from 'react';

const SendSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.69509 11.9999L2.3008 3.27174C2.06456 2.66427 2.6569 2.08334 3.24287 2.28983L3.33521 2.32909L21.3352 11.3291C21.8535 11.5882 21.8858 12.2977 21.4324 12.6132L21.3352 12.6707L3.33521 21.6707C2.75223 21.9622 2.11893 21.4262 2.27026 20.8237L2.3008 20.7281L5.69509 11.9999L2.3008 3.27174L5.69509 11.9999ZM4.40357 4.54032L7.01256 11.2493L13.6401 11.2499C14.0198 11.2499 14.3336 11.5321 14.3833 11.8981L14.3901 11.9999C14.3901 12.3796 14.108 12.6934 13.7419 12.7431L13.6401 12.7499L7.01256 12.7493L4.40357 19.4595L19.3228 11.9999L4.40357 4.54032Z"
      fill="#828282"
    />
  </svg>
);

export default SendSvg;
