import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { IAPIError, ILawsuitSummaryDTO } from 'types';
import { GET_ALL_LAWSUITS_SUMMARY } from 'utils/consts';
import lawsuitService from 'api/endpoints/lawsuit';

export default function useLawsuitGetSummary() {
  const query = useQuery<AxiosResponse<ILawsuitSummaryDTO>, IAPIError>(
    GET_ALL_LAWSUITS_SUMMARY,
    async () => lawsuitService.getSummary(),
    {
      refetchOnWindowFocus: true,
    },
  );

  return query;
}
