import React from 'react';
import { Result, Button } from 'antd';

import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import ProceduraFollow from 'screens/ProceduralFollow';
import Jurimetry from 'screens/Jurimetry';
import LegalAgenda from 'screens/LegalAgenda';
import Petitioning from 'screens/Petitioning';
import Petitions from 'screens/Petitions';
import ProcessCenter from 'screens/ProcessCenter';
import { PrivateRoute } from 'Routes';
import Settings from 'screens/Settings';
import PageComponents from 'screens/PageComponents';
import ClippingsAndSummons from 'screens/ClippingsAndSummons';
import ProceduralProgress from 'screens/ProceduralProgress';

const Routes = (): React.ReactElement => {
  const { url, path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={url}
        render={() => (
          <Redirect
            to={`${path}/recortes-e-intimacoes/andamento-processual/nao-lidos`}
          />
        )}
      />
      <PrivateRoute path={`${url}/agenda-juridica`} component={LegalAgenda} />
      <Route
        path={`${url}/andamento-processual`}
        component={ProceduralProgress}
      />
      <PrivateRoute
        path={`${url}/central-de-processos`}
        component={ProcessCenter}
      />
      <PrivateRoute path={`${url}/configuracoes`} component={Settings} />
      <PrivateRoute path={`${url}/jurimetria`} component={Jurimetry} />
      <PrivateRoute
        path={`${url}/pagina-de-componentes`}
        component={PageComponents}
      />
      <PrivateRoute path={`${url}/peticionamento`} component={Petitioning} />
      <PrivateRoute path={`${url}/peticoes`} component={Petitions} />
      <PrivateRoute
        path={`${url}/recortes-e-intimacoes`}
        component={ClippingsAndSummons}
      />
      <PrivateRoute
        path={`${url}/acompanhamento-processual/:topicId`}
        component={ProceduraFollow}
      />
      <Route
        render={(routerProps) => {
          return (
            <Result
              status="404"
              title="404"
              subTitle="Desculpa, pagina não encontrada. :/"
              extra={
                <Button
                  type="primary"
                  onClick={() => routerProps.history.push('/')}
                >
                  Retornar para pagina inicial
                </Button>
              }
            />
          );
        }}
      />
    </Switch>
  );
};

export default Routes;
