import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GET_ALL_LAWSUIT_PROGRESS } from 'utils/consts';
import { IUserSettingsUpdateDTO } from 'types';
import userService from 'api/endpoints/user';

export default function useUserUpdate(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IUserSettingsUpdateDTO
> {
  const client = useQueryClient();
  const mutation = useMutation(
    (params: IUserSettingsUpdateDTO): Promise<AxiosResponse<void>> => {
      return userService.updateUser(params);
    },
    {
      onSuccess: () => client.invalidateQueries(GET_ALL_LAWSUIT_PROGRESS),
    },
  );

  return mutation;
}
