import React from 'react';
import { Card, Layout, Row } from 'antd';

const Container: React.FC = ({ children }): React.ReactElement => {
  const { Content } = Layout;
  return (
    <Layout>
      <Content className="outside-container">
        <Row>
          <Card className="outside-card">{children}</Card>
        </Row>
      </Content>
    </Layout>
  );
};

export default Container;
