import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacPageHeader = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        PageHeader
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente pai do header da plaicação onde se defino a logo, composto por:
            </div> 
            <div className='code-component'>
            {`<LogoIcon />`} {`<MainNav>`}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<PageHeader`}
              <br />
                {`className="site-page-header"`}
                <br />
                {`title={<LogoIcon />}`}
                <br />
                {`subTitle="Outprocess R15"`}
                <br />
                {`extra={<MainNav heading={navTitle} />}>`}
                <br />
              {`</PageHeader>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacPageHeader;