import React, { useContext } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { AddCircleIcon } from 'components/icons';
import appMenuSidebarData from 'screens/Main/components/appMenuSidebarData';
import AppSidebarSubMenu from 'screens/Main/components/AppSidebarSubMenu';
import { NavigationContext } from 'screens/Main/components/NavigationState';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AppSidebar = (): React.ReactElement => {
  const { current, setCurrent } = useContext(NavigationContext);
  const { path } = useRouteMatch();

  // Antd doesn't have a type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any): void => setCurrent(event.key);

  return (
    <Sider className="is-app-sider-container">
      <Menu
        className="is-app-sider t10b2 il2"
        onClick={handleClick}
        selectedKeys={[current]}
        mode="vertical"
      >
        {appMenuSidebarData.map(({ key, icon, label, link, disabled }) => (
          <Menu.Item
            key={key}
            icon={icon}
            className={disabled ? 'is-disabled' : ''}
          >
            <span className="wac-menu-item-label">{label}</span>
            <Link to={`${path}${link}`} />
          </Menu.Item>
        ))}
        <SubMenu
          key="MaisServicos"
          icon={<AddCircleIcon />}
          title="Mais serviços"
        >
          <AppSidebarSubMenu />
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default AppSidebar;
