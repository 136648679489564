import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import DrawerAdd from 'screens/ProceduralProgress/components/DrawerAdd';

const { Paragraph } = Typography

const WacDrawerAdd = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        DrawerAdd
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de modal de adicão de processo, composto por: 
            </div> 
            <div className='code-component'>
              {'<Button>'} {'<Drawer>'} {'<Label>'} {'<Dropdown>'} {'<Vspace>'} {'<DatePicker>'} {'<Input>'} {'<SwitchField>'} {'<Group>'}
            </div>
            <Vspace />
            <DrawerAdd /> 
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<>`}
              <br />
                {`<Button className='is-primary' onClick={showDrawer}>`}
                <br />
                  {`Cadastrar`}
                  <br />
                {`</Button>`}
                <br />
                {`<Drawer`}
                <br />
                  {`title="Cadastrar novo processo"`}
                  <br />
                  {`placement="right"`}
                  <br />
                  {`width={340}`}
                  <br />
                  {`onClose={onClose}`}
                  <br />
                  {`visible={visible}`}
                  <br />
                  {`closeIcon={<CloseOutlined />}>`}
                  <br />
                  <br />
                  {`<Label>Caderno</Label>`}
                  <br />
                  {`<Dropdown overlay={Book} trigger={['click']}>`}
                  <br />
                    {`<Button className='is-full' key='gen1'>`}
                    <br />
                      {`Selecionar`}
                      <br />
                    {`</Button>`}
                    <br />
                  {`</Dropdown>`}
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Número do processo</Label>`}
                  <br />
                  {`<Input />`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Responsável</Label>`}
                  <br />
                  {`<Dropdown overlay={Owner} trigger={['click']}>`}
                  <br />
                    {`<Button className='is-full' key='gen2'>`}
                    <br />
                      {`Selecionar`}
                      <br />
                    {`</Button>`}
                    <br />
                  {`</Dropdown>`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Protocolado em</Label>`}
                  <br />
                  {`<DatePicker onChange={onChangeDate} />`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Tags (opcional)</Label>`}
                  <br />
                  {`<Dropdown overlay={Tags} trigger={['click']}>`}
                  <br />
                    {`<Button className='is-full' key='gen3'>`}
                    <br />
                      {`Selecionar`}
                      <br />
                    {`</Button>`}
                    <br />
                  {`</Dropdown>`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Observação (opcional)</Label>`}
                  <br />
                  {`<Input />`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Label>Monitorar processo?</Label>`}
                  <br />
                  {`<Switch defaultChecked onChange={onChangeSwitch} />`}
                  <br />
                  <br />
                  {`<Vspace />`}
                  <br />
                  <br />
                  {`<Group>`}
                  <br />
                    {`<Button className='is-secondary'>Cancelar</Button>`}
                    <br />
                    {`<Button className='is-primary'>Salvar e fechar</Button>`}
                    <br />
                  {`</Group>`}
                  <br />
                {`</Drawer>`}
                <br />
              {`</>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDrawerAdd;