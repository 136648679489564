import React, { useState } from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Button,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import DrawerDetails from 'screens/ProceduralFollow/components/DrawerDetails';

const { Paragraph } = Typography

const WacDrawerDetails = () => {


  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        DrawerDetails
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de modal de detalhes de processo, composto por: 
            </div> 
            <div className='code-component'>
              {'<Drawer>'} {'<Paragraph>'} {'<DrawerHeader>'}
            </div>
            <Vspace />
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<Drawer`}
              <br />
                {`title={DrawerHeader()}`}
                <br />
                {`placement="right"`}
                <br />
                {`width={340}`}
                <br />
                {`onClose={onClose}`}
                <br />
                {`visible={_isVisible}`}
                <br />
                {`closeIcon={<CloseOutlined />}>`}
                <Vspace />
                {`<Paragraph>`}
                <br />
                  {`{JSON.stringify(modalData)}`}
                  <br />
                {`</Paragraph>`}
                <br />
              {`</Drawer>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDrawerDetails;