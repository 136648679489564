import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import {
  ILawsuitDetailDTO,
  ILawsuitProgressDTO,
  ILawsuitProgressPageDTO,
  IShareByEmailDTO,
  IProcessListFiltersDTO,
} from 'types';
import { PAGE_SIZE } from 'utils/consts';

const lawsuitProgressService = {
  getAll: ({
    pageNumber = 1,
    pageSize = PAGE_SIZE,
    filters = {},
  }: {
    pageNumber?: number;
    pageSize?: number;
    filters?: IProcessListFiltersDTO;
  }): Promise<AxiosResponse<ILawsuitProgressPageDTO>> => {
    return http.get(`/LawsuitProgress/GetAll`, {
      params: {
        pageNumber,
        pageSize,
        ...filters,
      },
    });
  },

  getDetail: ({
    lawsuitNumber,
    degree,
  }: {
    lawsuitNumber: string;
    degree: string;
  }): Promise<AxiosResponse<ILawsuitDetailDTO>> => {
    const params = {
      lawsuitNumber,
      degree,
    };
    return http.get(`/LawsuitProgress/GetDetail`, { params });
  },

  create: (params: ILawsuitProgressDTO): Promise<AxiosResponse<void>> => {
    return http.post('/LawsuitProgress', params);
  },

  update: (params: ILawsuitProgressDTO): Promise<AxiosResponse<void>> => {
    return http.put('/LawsuitProgress', params);
  },

  shareByEmail: (params: IShareByEmailDTO): Promise<AxiosResponse<void>> => {
    return http.post('/LawsuitProgress/ShareByEmail', params);
  },

  getLawsuitDoc: ({
    lawsuitNumber,
    downloadFile = false,
  }: {
    lawsuitNumber: string;
    downloadFile?: boolean;
  }): Promise<AxiosResponse<number>> => {
    const params = {
      lawsuitNumberList: [lawsuitNumber],
      downloadFile,
    };
    return http.post('/LawsuitProgress/PrintOut', params, {
      responseType: 'arraybuffer',
    });
  },

  setRead: (
    lawsuitNumber: string,
    read: boolean,
  ): Promise<AxiosResponse<void>> => {
    return http.patch(`/LawsuitProgress/setRead`, { lawsuitNumber, read });
  },

  setStarred: (
    lawsuitNumber: string,
    starred: boolean,
  ): Promise<AxiosResponse<void>> => {
    return http.patch(`/LawsuitProgress/setStarred`, {
      lawsuitNumber,
      starred,
    });
  },
};

export default lawsuitProgressService;
