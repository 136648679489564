import React from 'react';

const LoadSvg = (): React.ReactElement => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.01197 2.75648C3.21583 3.02379 3.1644 3.40574 2.89709 3.60961C1.8459 4.4113 1.21739 5.65298 1.21739 7C1.21739 9.1288 2.77854 10.893 4.81843 11.2101L4.38693 10.7782C4.14922 10.5405 4.14922 10.1551 4.38693 9.91741C4.62464 9.6797 5.01005 9.6797 5.24776 9.91741L6.7695 11.4392C7.00721 11.6769 7.00721 12.0623 6.7695 12.3L5.24776 13.8217C5.01005 14.0594 4.62464 14.0594 4.38693 13.8217C4.14922 13.584 4.14922 13.1986 4.38693 12.9609L4.90017 12.4481C2.14635 12.1593 0 9.83031 0 7C0 5.26914 0.808847 3.67118 2.15884 2.6416C2.42615 2.43774 2.8081 2.48917 3.01197 2.75648ZM4.18702 1.70002L5.70876 0.178283C5.94647 -0.0594276 6.33188 -0.0594276 6.56959 0.178283C6.78901 0.397708 6.80589 0.742979 6.62022 0.981765L6.56959 1.03911L6.05635 1.55188C8.81017 1.84069 10.9565 4.16969 10.9565 7C10.9565 8.65555 10.2169 10.1915 8.96512 11.2255C8.70593 11.4396 8.32226 11.403 8.10817 11.1438C7.89408 10.8847 7.93063 10.501 8.18981 10.2869C9.1645 9.48177 9.73913 8.2885 9.73913 7C9.73913 4.8712 8.17798 3.10703 6.13809 2.78991L6.56959 3.22176C6.8073 3.45947 6.8073 3.84488 6.56959 4.08259C6.35016 4.30201 6.00489 4.31889 5.7661 4.13322L5.70876 4.08259L4.18702 2.56085C3.9676 2.34142 3.95072 1.99615 4.13639 1.75737L4.18702 1.70002L5.70876 0.178283L4.18702 1.70002Z"
      fill="#333333"
    />
  </svg>
);

export default LoadSvg;
