import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacAppMenu = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        AppMenu
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente do menu da navegação horizontal principal da aplicação, composto por: 
            </div> 
            <div className='code-component'>
              {`<Menu>`} {` <Menu.Item>`} {` <Tooltip>`} {` <Button>`}
            </div>
          </div>
        </Col>    
        <Col span={12}>
          <div className='code-box'>

            <Paragraph code={true}>
            <Vspace />
              {`<Menu`}
              <br />
              { `onClick={handleClick}`}
                <br />
                {`selectedKeys={[current]}`}
                <br />
                {`mode=`}{`'horizontal'`}
                <br />
                {`className="is-sub-nav">`}
                <br />
                {`{appMenuSidebarData.map(({ key, tooltip, label, link }) => (`}
                <br />
                  {`<Menu.Item key={key}>`}
                  <br />
                    {`<Tooltip mouseEnterDelay={0.7} title={tooltip}>`}
                    <br />
                      {`<span>{label}</span>`}
                      <br />
                      {`<Link to={'/'} />`}
                      <br />
                    {`</Tooltip>`}
                    <br />
                  {`</Menu.Item>`}
                  <br />
                {`))}`}
                <br />
                {`<>`}
                <br />
                  {`<Button className='is-secondary ftr'>`}
                  <br />
                    {`<span>Títulos Grifon</span>`}
                    <br />
                  {`</Button>`}
                  <br />
                {`</>`}
                <br />
              {`</Menu>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacAppMenu;