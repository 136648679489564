import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { GET_ALL_LAWSUIT_FOLDER } from 'utils/consts';
import lawsuitService from 'api/endpoints/lawsuit';
import { AxiosResponse } from 'axios';
import { ICreateFolderDTO, ILawsuitFolderDTO } from 'types';

export default function useLawsuitCreateFolder(): UseMutationResult<
  AxiosResponse<ILawsuitFolderDTO>,
  unknown,
  ICreateFolderDTO
> {
  const client = useQueryClient();
  const mutation = useMutation(
    (form: ICreateFolderDTO) => lawsuitService.createFolder(form),
    {
      onSuccess: () => client.invalidateQueries(GET_ALL_LAWSUIT_FOLDER),
    },
  );

  return mutation;
}
