import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

const usePDFWorker = (): boolean => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setLoading(false);
  }, []);

  return isLoading;
};

export default usePDFWorker;
