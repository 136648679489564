import { useMutation, UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { IAPIError, IUserSwitchCompanyDTO } from 'types';
import userService from 'api/endpoints/user';

export default function useUserSwitchCompany(): UseMutationResult<
  AxiosResponse<IUserSwitchCompanyDTO>,
  unknown,
  string
> {
  const mutation = useMutation<
    AxiosResponse<IUserSwitchCompanyDTO>,
    IAPIError,
    string
  >((companyId: string) => userService.updateLoggedUserCompany(companyId));

  return mutation;
}
