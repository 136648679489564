import {
  getTokenSession,
  getIsAuthenticatedSession,
  setIsAuthenticatedSession,
  setTokenSession,
} from 'utils/session';
import jwt from 'jwt-decode';
import React, { createContext, useContext, useState, useCallback } from 'react';
import authService from 'api/endpoints/auth';
import { IUser } from 'types';

export interface AuthState {
  user?: IUser;
  token?: string;
  authenticated: boolean;
}
export interface LoginForm {
  email: string;
  password: string;
  recaptchaToken?: string | null;
}

export interface AuthenticateResult {
  token: string;
  expiration: Date;
  authenticated: boolean;
}
export interface TwoFactorResult {
  token: string;
  expiration: string;
  authenticated: boolean;
}
interface IAuthContext extends AuthState {
  login(form: LoginForm): Promise<AuthState>;
  authenticate(authCode: string): Promise<TwoFactorResult>;
  updateUserToken(newToken: string): void;
  logout(): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>(
    (): AuthState => {
      const token = getTokenSession();
      const authenticated = getIsAuthenticatedSession();
      if (token && authenticated) {
        return { token, authenticated, user: jwt(token) };
      }
      return {
        authenticated: false,
      };
    },
  );

  const login = useCallback(
    async ({
      email,
      password,
      recaptchaToken,
    }: LoginForm): Promise<AuthState> => {
      const response = await authService.doRecaptchaAuth({
        email,
        password,
        recaptchaToken,
      });

      setIsAuthenticatedSession(response.data.authenticated);
      setTokenSession(response?.data.token ?? '');

      setAuth({
        token: response.data.token,
        authenticated: response.data.authenticated,
        user: jwt(response?.data.token ?? ''),
      });

      return auth as AuthenticateResult;
    },
    [auth],
  );

  const authenticate = useCallback(
    async (authCode: string): Promise<TwoFactorResult> => {
      const response = await authService.doTwoFactorAuth(authCode);
      setAuth({
        ...auth,
        authenticated: response?.data.authenticated ?? false,
      });

      setIsAuthenticatedSession(response?.data.authenticated);
      setTokenSession(response?.data.token);

      return auth as TwoFactorResult;
    },
    [auth],
  );

  const logout = useCallback(() => {
    setAuth({ authenticated: false });
    setIsAuthenticatedSession(false);
    setTokenSession('');
  }, []);

  const updateUserToken = useCallback((newToken: string): void => {
    setAuth((prevAuth) => {
      setTokenSession(newToken);
      return { ...prevAuth, token: newToken };
    });
  }, []);

  const authContext = {
    user: auth?.user,
    token: auth?.token,
    authenticated: !!auth?.authenticated,
    updateUserToken,
    login,
    authenticate,
    logout,
  } as IAuthContext;

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  return useContext<IAuthContext>(AuthContext);
};
