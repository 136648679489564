import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

interface INavigationContext {
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
}

export const NavigationContext = React.createContext({} as INavigationContext);

interface Props {
  children: React.ReactElement[];
}

const NavigationState: React.FC<Props> = ({ children }) => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const [current, setCurrent] = useState(() => {
    const currentPath = location?.pathname?.split(path)?.pop()?.substring(1);
    const currentLocation = currentPath?.split('/')[0];
    return currentLocation ?? '';
  });

  const value = {
    current,
    setCurrent,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationState;
