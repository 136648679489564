export const GET_ALL_LAWSUIT = 'GET_ALL_LAWSUIT';
export const GET_ALL_LAWSUIT_PROGRESS = 'GET_ALL_LAWSUIT_PROGRESS';
export const GET_LAWSUIT_PROGRESS_DETAIL = 'GET_LAWSUIT_PROGRESS_DETAIL';
export const GET_ALL_RESPONSIBLES = 'GET_ALL_RESPONSIBLES';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_FORUMS = 'GET_ALL_FORUMS';
export const GET_USERS = 'GET_USERS';
export const GET_LAWSUIT = 'GET_LAWSUIT';
export const GET_ALL_BOOKS = 'GET_ALL_BOOKS';
export const GET_ALL_COURTS = 'GET_ALL_COURTS';
export const PDF_DOCUMENT = 'PDF_DOCUMENT';
export const RECAPTCHA_PUBLIC_KEY = '6Ld5PgYaAAAAAO3A8lpm_TtyO8aKC1wttYvPXdKA';
export const GET_ALL_LAWSUITS_SUMMARY = 'GET_ALL_LAWSUITS_SUMMARY';
export const GET_ALL_LAWSUIT_FOLDER = 'GET_ALL_LAWSUIT_FOLDER';
export const GET_USER_ALL_COMPANIES = 'GET_USER_ALL_COMPANIES';

export const PAGE_SIZE = 10;

export const PAGE_SIZE_MAX = 100000;

export const ERRORS: Record<string, string> = {
  EXISTS_LAWSUIT_NUMBER: 'O número de processo já existe!',
  FORMAT_LAWSUIT_NUMBER: 'Número de processo está no formato errado!',
  GENERAL_ERROR: 'Aconteceu um erro inesperado!',
  NOT_CONFIRMED_USER: 'Usuário não confirmado!',
};

export const ROLES: Record<string, string> = {
  admin: 'Administrador',
  member: 'Membro',
};
