import { Table, Skeleton } from 'antd';
import React, { useState } from 'react';
import { ILawsuit, IProcessListFiltersDTO } from 'types';
import { PAGE_SIZE } from 'utils/consts';
import { useHistory } from 'react-router-dom';
import DrawerEdit from 'screens/ProceduralProgress/components/DrawerEdit';
import {
  columns,
  mapToTable,
} from 'screens/ProceduralProgress/components/utils';
import useLawsuitProgressGetAll from 'api/queries/useLawsuitProgressGetAll';

interface Props {
  filters: IProcessListFiltersDTO;
}

const ProcessList = (props: Props): React.ReactElement => {
  const { filters } = props;
  const [current, setCurrent] = React.useState(1);
  const history = useHistory();
  const [drawerInfo, setDrawerInfo] = useState<{
    data: ILawsuit | null;
    visible: boolean;
  }>({ data: null, visible: false });
  const { isLoading, error, data: result } = useLawsuitProgressGetAll({
    currentPage: current,
    filters,
  });

  const showDrawer = (data: ILawsuit): void => {
    setDrawerInfo({ data, visible: true });
  };

  const onClose = (): void => {
    setDrawerInfo({ data: null, visible: false });
  };

  const handleTableChange = (page: number): void => {
    setCurrent(page);
  };

  if (isLoading) {
    return <Skeleton active />;
  }

  if (error) {
    return <div>Erro</div>;
  }

  const pagination = {
    onChange: handleTableChange,
    total: result?.data.pageInfo.totalItens,
    current,
    pageSize: PAGE_SIZE,
  };

  return (
    <>
      <DrawerEdit
        data={drawerInfo.data}
        visible={drawerInfo.visible}
        onClose={onClose}
      />
      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(
                `/platform/acompanhamento-processual/${record.lawsuitNumber}`,
              );
            },
          };
        }}
        className="boxed"
        columns={columns({ onEdit: showDrawer })}
        dataSource={mapToTable(result?.data.lawsuitProgress)}
        pagination={pagination}
        showSorterTooltip={false}
      />
    </>
  );
};

export default ProcessList;
