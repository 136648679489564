import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Pagination,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const bcounter = (
  <Paragraph code={true}>
    <Vspace />
      {`<Pagination defaultCurrent={1} total={50} />`}
    <Vspace />
  </Paragraph>
);

const WacPagination = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Pagination
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Pagination defaultCurrent={1} total={50} />
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {bcounter}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacPagination;
