import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const box = (
  <Paragraph code={true}>
    <Vspace />
      {`<Box>`}
      <br />
        {`Box padrão com width 100%`}
        <br />
      {`</Box>`}
      <Vspace />
      {`<Box className='is-full'>`}
      <br />
        {`Box full para fundo de tela com height e width 100% e overflow auto`}
        <br />
      {`</Box>`}
      <Vspace />
      {`<Box className='is-square is-small'>`}
      <br />
        {`Box quadrado pequeno`}
        <br />
      {`</Box>`}
      <Vspace />
      {`<Box className='is-square'>`}
      <br />
        {`Box quadrado padrão`}
        <br />
      {`</Box>`}
      <Vspace />
      {`<Box className='is-square is-large'>`}
      <br />
        {`Box quadrado grande`}
        <br />
      {`</Box>`}
    <Vspace />
  </Paragraph>
);

const WacBox = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Box
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Box>
              Box padrão com width 100%
            </Box>
          </Box>
          <Box className='code-ex'>
            <Box className='is-full'>
              Box full para fundo de tela com height e width 100% e overflow auto
            </Box>
          </Box>
          <Box className='code-ex'>
            <Box className='is-square is-small'>
              Box quadrado pequeno
            </Box>
          </Box>
          <Box className='code-ex'>
            <Box className='is-square'>
              Box quadrado padrão
            </Box>
          </Box>
          <Box className='code-ex'>
            <Box className='is-square is-large'>
              Box quadrado grande
            </Box>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {box}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacBox;
