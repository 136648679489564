import React from 'react';

const AddFileSvg = (): React.ReactElement => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.04 8C16.227 8 18 9.79086 18 12C18 14.2091 16.227 16 14.04 16C11.853 16 10.08 14.2091 10.08 12C10.08 9.79086 11.853 8 14.04 8ZM14.04 9.45399L13.9753 9.45985C13.8283 9.48679 13.7125 9.60383 13.6858 9.75226L13.68 9.81763L13.6797 11.6358L11.8783 11.6364L11.8136 11.6422C11.6667 11.6692 11.5508 11.7862 11.5241 11.9346L11.5183 12L11.5241 12.0654C11.5508 12.2138 11.6667 12.3308 11.8136 12.3578L11.8783 12.3636L13.6805 12.3631L13.6808 14.1844L13.6866 14.2497C13.7133 14.3981 13.8291 14.5152 13.9761 14.5421L14.0408 14.548L14.1055 14.5421C14.2525 14.5152 14.3683 14.3981 14.395 14.2497L14.4008 14.1844L14.4005 12.3631L16.2033 12.3636L16.268 12.3578C16.4149 12.3308 16.5308 12.2138 16.5575 12.0654L16.5633 12L16.5575 11.9346C16.5308 11.7862 16.4149 11.6692 16.268 11.6422L16.2033 11.6364L14.3997 11.6358L14.4 9.81763L14.3942 9.75226C14.3675 9.60383 14.2517 9.48679 14.1047 9.45985L14.04 9.45399ZM5.90899 0C6.24059 0 6.56301 0.102753 6.83348 0.292617L6.94608 0.379274L8.65584 1.81818H15.66C16.4784 1.81818 17.1551 2.43125 17.2645 3.22755L17.2763 3.34251L17.28 3.45455L17.2806 8.58935C16.9608 8.27924 16.5975 8.01458 16.2008 7.80565L16.2 3.45455C16.2 3.1784 15.9969 2.95019 15.7333 2.91407L15.66 2.90909H8.65512L6.94608 4.348C6.69134 4.56243 6.37852 4.69199 6.05042 4.72103L5.90899 4.72727L1.08 4.72655V12.9091C1.08 13.1852 1.28315 13.4134 1.54673 13.4496L1.62 13.4545L9.58582 13.4549C9.71 13.8431 9.88254 14.2093 10.0963 14.5463L1.62 14.5455C0.762578 14.5455 0.0607357 13.8726 0.00373741 13.0211L0 12.9091V1.63636C0 0.770281 0.666116 0.0613492 1.50908 0.00377516L1.62 0H5.90899ZM5.90899 1.09091H1.62C1.34662 1.09091 1.12069 1.29611 1.08493 1.56235L1.08 1.63636V3.63564L5.90899 3.63636C6.01005 3.63636 6.10855 3.60773 6.19359 3.55446L6.25469 3.50994L7.61616 2.36291L6.25469 1.21733C6.17705 1.15198 6.08323 1.11028 5.98413 1.09622L5.90899 1.09091Z"
      fill="#828282"
    />
  </svg>
);

export default AddFileSvg;
