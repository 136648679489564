import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import Label from 'components/commons/Label';
import React from 'react';
import { IForumDTO } from 'types';
import useForumGetAllByCourt from 'api/queries/useForumGetAllByCourt';

interface ForumAutocompleteProps {
  courtId?: string;
  onChange: (forumId: string) => void;
}

const ForumAutocomplete: React.FC<ForumAutocompleteProps> = ({
  courtId,
  onChange,
}: ForumAutocompleteProps) => {
  const { isLoading, data: dataQuery } = useForumGetAllByCourt({ courtId });

  if (isLoading) {
    return <LoadingOutlined />;
  }

  const forums = dataQuery?.data || [];

  const forumList = forums.map((forum: IForumDTO) => {
    return { value: forum.name, label: forum.name, id: forum.id };
  });

  return (
    <>
      <Label>Forum</Label>
      <AutoComplete
        allowClear
        options={forumList}
        disabled={!courtId}
        filterOption={(inputValue, option) => {
          const labelString = option?.label as string;
          return (
            labelString.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        onSelect={(_, option) => onChange(option.id)}
      />
    </>
  );
};

export default ForumAutocomplete;
