import React from 'react';
import { useAuth } from 'context/Auth';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Result, Button } from 'antd';
import PasswordRecovery from 'screens/PasswordRecovery';
import Main from 'screens/Main';
import Login from 'screens/Login';
import TwoFactorAuth from 'screens/2fa/2fa';
import Index from 'screens/PasswordReset';
import UserConfirmation from 'screens/UserConfirmation';

export const PrivateRoute: React.FC<{
  component: React.ElementType;
  path: string;
}> = ({ component: Component, ...rest }) => {
  const { token, authenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated && !!token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

const Routes = (): React.ReactElement => {
  const { token, authenticated, logout } = useAuth();

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(routeProps) => {
          if (token && authenticated) {
            routeProps.history.push('/platform');
          } else if (token && !authenticated) {
            routeProps.history.push('/2fa');
          }
          return <Login />;
        }}
      />
      <Route
        exact
        path="/2fa"
        render={(routerProps) => {
          if (token && !authenticated) {
            return <TwoFactorAuth />;
          }
          if (token && authenticated) {
            routerProps.history.push('/platform');
          }

          return <Login />;
        }}
      />
      <Route path="/forgot-password" component={PasswordRecovery} />
      <Route path="/reset-password" component={Index} />
      <Route path="/user-confirmation" component={UserConfirmation} />

      <PrivateRoute path="/platform" component={Main} />
      <Route
        path="/logout"
        render={() => {
          logout();
          return <Redirect to="/" />;
        }}
      />
      <Route
        render={(routerProps) => {
          return (
            <Result
              status="404"
              title="404"
              subTitle="Desculpa, pagina não encontrada. :/"
              extra={
                <Button
                  type="primary"
                  onClick={() => routerProps.history.push('/')}
                >
                  Retornar para pagina inicial
                </Button>
              }
            />
          );
        }}
      />
    </Switch>
  );
};

export default Routes;
