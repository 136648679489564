import { ILawsuitCardDTO } from 'types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IToolbarContext {
  isVisible: boolean;
  toggleToolbar: () => void;
  setSelected: React.Dispatch<React.SetStateAction<ILawsuitCardDTO[]>>;
  selectedClippings: ILawsuitCardDTO[];
}
export const ToolbarContext = createContext<IToolbarContext>(
  {} as IToolbarContext,
);

export const useToolbar = (): IToolbarContext => {
  return useContext(ToolbarContext);
};

export const ToolbarProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [isVisible, setVisible] = useState(false);
  const toggleToolbar = (): void => {
    setVisible((prev: boolean) => !prev);
  };

  const [selectedClippings, setSelected] = useState<ILawsuitCardDTO[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [location]);

  return (
    <ToolbarContext.Provider
      value={{ isVisible, toggleToolbar, setSelected, selectedClippings }}
    >
      {children}
    </ToolbarContext.Provider>
  );
};
