import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { IAPIError, IForumDTO } from 'types';
import { GET_ALL_FORUMS } from 'utils/consts';
import forumService from 'api/endpoints/forum';

interface Props {
  courtId?: string;
}

export default function useForumGetAllByCourt({
  courtId,
}: Props): UseQueryResult<AxiosResponse<IForumDTO[]>> {
  const query = useQuery<AxiosResponse<Array<IForumDTO>>, IAPIError>(
    [GET_ALL_FORUMS, courtId],
    () => forumService.getAllByCourt(courtId ?? ''),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!courtId,
    },
  );

  return query;
}
