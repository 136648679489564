import React from 'react';

const OutlineEditSvg = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9.20756 3.33325H3.3966C2.95627 3.33325 2.53397 3.50885 2.22261 3.82141C1.91125 4.13397 1.73633 4.55789 1.73633 4.99992V16.6666C1.73633 17.1086 1.91125 17.5325 2.22261 17.8451C2.53397 18.1577 2.95627 18.3333 3.3966 18.3333H15.0185C15.4589 18.3333 15.8812 18.1577 16.1925 17.8451C16.5039 17.5325 16.6788 17.1086 16.6788 16.6666V10.8333"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4332 2.08344C15.7635 1.75192 16.2114 1.56567 16.6784 1.56567C17.1455 1.56567 17.5934 1.75192 17.9237 2.08344C18.2539 2.41496 18.4394 2.8646 18.4394 3.33344C18.4394 3.80228 18.2539 4.25192 17.9237 4.58344L10.0373 12.5001L6.7168 13.3334L7.54693 10.0001L15.4332 2.08344Z"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="19.9233"
          height="20"
          fill="white"
          transform="translate(0.0761719)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OutlineEditSvg;
