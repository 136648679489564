import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Modal, Form, message } from 'antd';
import { AddFileIcon } from 'components/icons';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import useLawsuitCreateFolder from 'api/mutations/useLawsuitCreateFolder';

const FolderSchema = yup.object().shape({
  name: yup.string().required('Nome da pasta é obrigatório'),
});

const ModalFolder: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { mutateAsync, isLoading } = useLawsuitCreateFolder();

  const { handleSubmit, control } = useForm<{ name: string }>({
    resolver: yupResolver(FolderSchema),
  });

  const showModal = (): void => {
    setVisible(true);
  };

  const onSubmit = async (form: {
    name: string;
    description: string;
  }): Promise<void> => {
    try {
      await mutateAsync(form);
      setVisible(false);
      message.success('Pasta criada com sucesso!');
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  return (
    <>
      <AddFileIcon onClick={showModal} />
      <Modal
        title="Nova pasta"
        visible={visible}
        onOk={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        okText="Criar pasta"
        cancelText="Cancelar"
        confirmLoading={isLoading}
      >
        <Form>
          <Controller
            control={control}
            name="name"
            render={(props) => (
              <Form.Item {...props}>
                <Input
                  size="large"
                  placeholder="Insira um nome para a nova pasta"
                />
              </Form.Item>
            )}
          />
        </Form>
      </Modal>
    </>
  );
};

export default ModalFolder;
