import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import Label from 'components/commons/Label';
import React from 'react';
import { IResponsibleInfoDTO } from 'types';
import useGetAllResponsibles from 'api/queries/useGetAllResponsibles';

const { Option } = Select;
interface Props {
  value?: SelectValue;
  onChange: (e: SelectValue) => void;
}

const ResponsibleSelect: React.FC<Props> = ({
  value,
  onChange,
}): React.ReactElement => {
  const { isLoading, data: dataQuery } = useGetAllResponsibles();

  if (isLoading) {
    return <LoadingOutlined />;
  }

  const axiosData = dataQuery?.data;
  const responsibles = axiosData?.data || [];
  return (
    <>
      <Label>Responsáveis</Label>
      <Select value={value} onChange={onChange} allowClear defaultValue="">
        {responsibles.map(({ id, name }: IResponsibleInfoDTO) => (
          <Option value={id}>{name}</Option>
        ))}
      </Select>
    </>
  );
};

export default ResponsibleSelect;
