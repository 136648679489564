import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Input
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Search, TextArea } = Input
const { Paragraph, Text } = Typography

const input = (
  <Paragraph code={true}>
    <Vspace />
      {`<Input placeholder="Input básico" />`}
      <Vspace />
      {`<TextArea`}
      <br />
      {`placeholder="Textarea"`}
      <br />
      {`rows={4} />`}
      <Vspace />
      {`<Search`}
      <br />
      {`placeholder='Pesquisar'`}
      <br />
      {`onSearch={(value) => console.log(value)}`}
      <br />
      {`className="featured" />`}
    <Vspace />
  </Paragraph>
);

const WacInput = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Input
      </Divider>
      <Text> <a href='https://ant.design/components/input/#header'>Ant Design</a></Text>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Input placeholder="Input básico" />
          </Box>
          <Box className='code-ex'>
            <TextArea placeholder="Textarea" rows={4} />
          </Box>
          <Box className='code-ex'>
            <Search
            placeholder='Pesquisar'
            onSearch={(value) => console.log(value)}
            className="featured" />
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {input}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacInput;
