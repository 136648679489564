import { Page } from 'types';
import { ERRORS } from 'utils/consts';
import { AxiosError } from 'axios';

export const hasMorePages = <K extends string, T>(
  pages: Page<K, T>[],
): boolean => !pages[pages.length - 1].pageInfo.lastPage;

export const buildError = (errorInfo: AxiosError): string => {
  const validationInfo = errorInfo?.response?.data?.details[0];
  return ERRORS[validationInfo?.code] || ERRORS.GENERAL_ERROR;
};
