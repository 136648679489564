import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Drawer, Form, message, Typography } from 'antd';
import Group from 'components/custom/Group';
import RoleSelectField from 'components/form/RoleSelectField';
import TextField from 'components/form/TextField';
import { EditIcon } from 'components/icons';
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { IUserSettings } from 'types';
import Button from 'components/commons/Button';
import useUserUpdate from 'api/mutations/useUserUpdate';

interface UserForm {
  id: string;
  name: string;
  roleId: string;
}

const { Text: TextAnt } = Typography;

const TwoFactorSchema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  roleId: yup.string(),
});

const DrawerEditUser = ({
  data,
}: {
  data: IUserSettings;
}): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const { mutateAsync: updateMutation, isLoading } = useUserUpdate();
  const { handleSubmit, control, errors, reset } = useForm<UserForm>({
    resolver: yupResolver(TwoFactorSchema),
    defaultValues: {
      id: data.id,
      name: data.name,
      roleId: data.roleId,
    },
  });

  const showDrawer = (): void => setVisible(true);

  const onClose = (): void => {
    reset();
    setVisible(false);
  };

  const onSubmit: SubmitHandler<UserForm> = async (form: UserForm) => {
    try {
      await updateMutation(form);
      message.success('Usuário atualizado com sucesso!');
      onClose();
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  const titleStyle = { fontSize: '7.13px' };

  return (
    <div>
      <EditIcon onClick={showDrawer} />
      <TextAnt strong style={titleStyle}>
        Editar
      </TextAnt>
      <Drawer
        title="Atualizar usuário"
        placement="right"
        width={340}
        onClose={onClose}
        visible={visible}
        mask={false}
        closeIcon={<CloseOutlined />}
      >
        <Form name="lawsuit" onFinish={handleSubmit(onSubmit)}>
          <TextField
            name="id"
            initialValue=""
            label="ID"
            hidden
            control={control}
            errors={errors}
          />
          <TextField
            name="name"
            initialValue=""
            label="Nome"
            control={control}
            errors={errors}
          />
          <RoleSelectField
            name="roleId"
            label="Papel"
            control={control}
            errors={errors}
          />
          <Group className="is-right">
            <Button
              disabled={isLoading}
              className="is-secondary is-small"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button htmlType="submit" className="is-primary is-small">
              {isLoading ? <LoadingOutlined /> : 'Salvar e fechar'}
            </Button>
          </Group>
        </Form>
      </Drawer>
    </div>
  );
};

export default DrawerEditUser;
