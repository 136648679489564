import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import Label from 'components/commons/Label';
import React from 'react';
import { SelectValue } from 'antd/lib/select';
import useBookGetAll from 'api/queries/useBookGetAll';

interface BookSelectProps {
  value?: SelectValue;
  onChange: (bookId: SelectValue) => void;
}

const { Option } = Select;

const BookSelect: React.FC<BookSelectProps> = ({ value, onChange }) => {
  const { isLoading, data: dataQuery } = useBookGetAll();

  if (isLoading) return <LoadingOutlined />;

  const books = dataQuery?.data || [];
  return (
    <>
      <Label>Caderno</Label>
      <Select value={value} allowClear onChange={onChange}>
        {books.map(({ id, description }) => (
          <Option key={id} value={id}>
            {description}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default BookSelect;
