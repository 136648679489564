import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { ICourtDTO } from 'types';
import { GET_ALL_COURTS } from 'utils/consts';
import { getAllCourts } from 'api/endpoints/court';

interface Props {
  implemented: boolean;
}

export default function useCourtGetAll({
  implemented,
}: Props): UseQueryResult<AxiosResponse<Array<ICourtDTO>>> {
  const query = useQuery<AxiosResponse<Array<ICourtDTO>>>(
    GET_ALL_COURTS,
    () => getAllCourts(implemented),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  return query;
}
