import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { PrintIcon } from 'components/icons';
import { Modal } from 'antd';
import Text from 'antd/lib/typography/Text';

interface Props {
  pdfData: number | undefined;
  isLoading: boolean;
  error: string | null;
}

const PDFIcon = ({ pdfData, isLoading, error }: Props): React.ReactElement => {
  const [isOpen, setOpen] = useState(false);

  if (isLoading || !pdfData) {
    return <LoadingOutlined />;
  }

  const onOkClick = (): void => setOpen(false);

  if (error) {
    return (
      <>
        <Modal
          title="Ocorreu um erro!"
          visible={isOpen}
          onOk={onOkClick}
          cancelButtonProps={{ hidden: true }}
        >
          <Text className="is-size-13">Não foi possível abrir o PDF.</Text>
          <Text className="is-size-13">
            Recarregue a página e tente novamente.
          </Text>
        </Modal>
        <PrintIcon
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            setOpen(true);
          }}
        />
      </>
    );
  }

  const byteArray = new Uint8Array(pdfData);
  const file = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  return (
    <PrintIcon
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        window.open(fileURL, '_blank');
      }}
    />
  );
};

export default PDFIcon;
