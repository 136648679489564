import React from 'react';
import Box from 'components/custom/Box';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  data?: string[];
}

const ContentProcessPart = ({ data = [] }: Props): React.ReactElement => {
  if (data.length < 1) {
    return <div>Não há dados</div>;
  }
  return (
    <>
      {data.map((part: string) => (
        <Box>
          <Text strong>{part[0]}</Text>
          <Text>{part[1]}</Text>
        </Box>
      ))}
    </>
  );
};

export default ContentProcessPart;
