import React from 'react';
import { Skeleton, Table, Typography } from 'antd';
import Vspace from 'components/custom/Vspace';
import { PAGE_SIZE } from 'utils/consts';
import DrawerAddUser from 'screens/Settings/geral/DrawerAddUser';
import { columns } from 'screens/Settings/geral/userTable';
import useUserGetAll from 'api/queries/useUserGetAll';

const Container = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement => (
  <div className="user-management">
    <Typography.Title level={4}>Gerenciar Usuários</Typography.Title>
    {children}
  </div>
);

const UserManagement = (): React.ReactElement => {
  const [current, setCurrent] = React.useState(1);
  const { isLoading, error, data: result } = useUserGetAll({
    currentPage: current,
  });

  const handleTableChange = (page: number): void => {
    setCurrent(page);
  };

  if (isLoading) {
    return (
      <Container>
        <Skeleton active />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography.Paragraph>
          Ocorreu um erro ao carregar os usuário. Tente recarregar a página.
        </Typography.Paragraph>
      </Container>
    );
  }

  const pagination = {
    onChange: handleTableChange,
    total: result?.data.pageInfo.totalItens,
    current,
    pageSize: PAGE_SIZE,
  };

  return (
    <Container>
      <Vspace />
      <DrawerAddUser />
      <Vspace />
      <Table
        className="boxed"
        columns={columns()}
        dataSource={result?.data?.data.map((info) => ({
          key: info.id,
          ...info,
        }))}
        pagination={pagination}
        showSorterTooltip={false}
      />
    </Container>
  );
};

export default UserManagement;
