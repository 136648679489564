import React, { useState } from 'react';
import { Drawer, Button, Form, message } from 'antd';
import Group from 'components/custom/Group';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from 'components/form/TextField';
import ResponsibleSelectField from 'components/form/ResponsibleSelectField';
import { ShareAndroidIcon } from 'components/icons';
import useLawsuitShareByEmail from 'api/mutations/useLawsuitShareByEmail';

interface DrawerForm {
  subject: string;
  message: string;
  lawsuitList: string[];
  usersList: string[];
}

interface Props {
  lawsuitNumber: string;
}

const ShareSchema = yup.object().shape({
  subject: yup.string(),
  message: yup.string(),
});

const DrawerShare = ({ lawsuitNumber }: Props): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const { handleSubmit, control, errors, reset } = useForm<DrawerForm>({
    resolver: yupResolver(ShareSchema),
    defaultValues: {
      message: '',
      subject: '',
      lawsuitList: [],
      usersList: [],
    },
  });
  const { mutateAsync: createMutation, isLoading } = useLawsuitShareByEmail();

  const showDrawer = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ): void => {
    event.stopPropagation();
    setVisible(true);
  };

  const onClose = (): void => {
    reset();
    setVisible(false);
  };

  const onSubmit: SubmitHandler<DrawerForm> = async (form: DrawerForm) => {
    try {
      await createMutation({ ...form, lawsuitNumberList: [lawsuitNumber] });
      message.success('Processo compartilhado com sucesso!');
      onClose();
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  return (
    <>
      <ShareAndroidIcon onClick={showDrawer} />
      <Drawer
        title="Compartilhar processo"
        placement="right"
        width={340}
        onClose={onClose}
        visible={visible}
        maskClosable={false}
        closeIcon={<CloseOutlined />}
      >
        <Form name="lawsuit" onFinish={handleSubmit(onSubmit)}>
          <ResponsibleSelectField
            name="usersList"
            label="Usuários"
            control={control}
            errors={errors}
          />
          <TextField
            name="subject"
            initialValue=""
            label="Assunto"
            control={control}
            errors={errors}
          />
          <TextField
            name="message"
            initialValue=""
            label="Messagem"
            control={control}
            errors={errors}
          />
          <Group className="is-right">
            <Button
              disabled={isLoading}
              className="is-secondary is-small"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button htmlType="submit" className="is-primary is-small">
              {isLoading ? <LoadingOutlined /> : 'Enviar'}
            </Button>
          </Group>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerShare;
