import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacFilterOneDatePicker = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
      FilterOneDatePicker
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de DatePicker do filtro avançado, atualizando o <span style={{color: "#f58220"}}>{'<DatePicker>'}</span> do antdesign.
            </div> 
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<div>`}
              <br />
                {`{label && (`}
                <br />
                  {`<>`}
                  <br />
                    {`<label>{label}</label>`}
                    <br />
                    {`<br />`}
                    <br />
                  {`</>`}
                  <br />
                {`)}`}
                <br />
                <br />
                {`<DatePicker`}
                <br />
                  {`value={state && state[field]}`}
                  <br />
                  {`style={{ width: "100%" }}`}
                  <br />
                  {`showTime={showTime ? { format: "HH:mm:ss" } : false}`}
                  <br />
                  {`placeholder={placeholder}`}
                  <br />
                  {`getPopupContainer={(trigger) => trigger.parentElement}`}
                  <br />
                {`/>`}
                <br />
              {`</div>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacFilterOneDatePicker;