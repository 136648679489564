import React from 'react';

const CertificateSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0006 9.99995C20.2101 9.99995 22.0013 11.7911 22.0013 14.0006C22.0013 15.0157 21.6232 15.9424 21.0003 16.6478L21.0002 21.2486C21.0002 21.8194 20.3957 22.1681 19.9101 21.9175L19.8206 21.8634L18.0003 20.5912L16.1806 21.8634C15.7127 22.1904 15.0807 21.8945 15.008 21.353L15.0011 21.2486L15.0001 16.6468C14.3777 15.9415 14 15.0152 14 14.0006C14 11.7911 15.7911 9.99995 18.0006 9.99995ZM19.4994 17.711C19.0365 17.8982 18.5306 18.0012 18.0006 18.0012C17.4699 18.0012 16.9633 17.8979 16.4999 17.7102L16.5003 19.8092L17.5711 19.0621C17.7968 18.9043 18.0878 18.8846 18.3298 19.0029L18.4302 19.0621L19.4993 19.8092L19.4994 17.711ZM19.25 3.00415C20.7125 3.00415 21.9084 4.14585 21.995 5.58663L22 5.75415L22.0014 11C21.5971 10.4618 21.0867 10.0078 20.5013 9.66912L20.5 5.75415C20.5 5.10694 20.0081 4.57462 19.3778 4.5106L19.25 4.50415H4.75C4.10279 4.50415 3.57047 4.99603 3.50645 5.62635L3.5 5.75415V15.2542C3.5 15.9014 3.99187 16.4337 4.62219 16.4977L4.75 16.5042L13.6716 16.5054L13.7955 16.7078L13.9291 16.9045L14 16.9979L13.999 18.0042H4.75C3.28747 18.0042 2.0916 16.8624 2.00502 15.4217L2 15.2542V5.75415C2 4.29162 3.1417 3.09575 4.58248 3.00917L4.75 3.00415H19.25ZM18.0006 11.4999C16.6196 11.4999 15.5 12.6195 15.5 14.0006C15.5 15.3817 16.6196 16.5012 18.0006 16.5012C19.3817 16.5012 20.5013 15.3817 20.5013 14.0006C20.5013 12.6195 19.3817 11.4999 18.0006 11.4999ZM11.25 12.4999C11.6642 12.4999 12 12.8357 12 13.2499C12 13.6296 11.7178 13.9434 11.3518 13.9931L11.25 13.9999H6.75C6.33579 13.9999 6 13.6642 6 13.2499C6 12.8703 6.28215 12.5565 6.64823 12.5068L6.75 12.4999H11.25ZM17.25 6.99995C17.6642 6.99995 18 7.33573 18 7.74995C18 8.12964 17.7178 8.44344 17.3518 8.4931L17.25 8.49995H6.75C6.33579 8.49995 6 8.16416 6 7.74995C6 7.37025 6.28215 7.05646 6.64823 7.00679L6.75 6.99995H17.25Z"
      fill="white"
    />
  </svg>
);

export default CertificateSvg;
