import React from 'react';

import { Typography, Tooltip } from 'antd';

import Group from 'components/custom/Group';
import { UploadIcon, CommentAddIcon, AlertCircleIcon } from 'components/icons';
import ModalDelete from 'screens/ClippingsAndSummons/components/ModalDelete';
import PDFClipping from 'screens/ClippingsAndSummons/components/PDFClipping';
import PDFModal from 'screens/ClippingsAndSummons/components/PDFModal';
import DrawerShare from 'screens/ClippingsAndSummons/components/DrawerShare';

const { Text } = Typography;

const itemHeadData = (
  lawsuitNumber: string,
): Array<{ title: string; content: React.ReactElement }> => [
  {
    title: 'Tela cheia',
    content: <PDFModal lawsuitNumber={lawsuitNumber} />,
  },
  {
    title: 'Inserir comentário',
    content: <CommentAddIcon />,
  },
  {
    title: 'Exportar',
    content: <UploadIcon />,
  },
  {
    title: 'Imprimir',
    content: <PDFClipping lawsuitNumber={lawsuitNumber} />,
  },
  {
    title: 'Compartilhar',
    content: <DrawerShare lawsuitNumber={lawsuitNumber} />,
  },
  {
    title: 'Recorte incorreto',
    content: <ModalDelete />,
  },
];

const ContentAppHead: React.FC<{
  lawsuitNumber: string;
  totalRelated: number;
}> = ({ lawsuitNumber, totalRelated }) => {
  return (
    <Group className="is-spread ">
      <Group>
        <AlertCircleIcon />
        <Text className="is-size-13 is-grey-2 is-related-clippings">
          Possui {totalRelated} Recortes relacionados.
        </Text>
      </Group>

      <Group className="gutter-3">
        {itemHeadData(lawsuitNumber).map(({ title, content }) => (
          <Tooltip key={title} mouseEnterDelay={0.1} title={title}>
            {content}
          </Tooltip>
        ))}
      </Group>
    </Group>
  );
};

export default ContentAppHead;
