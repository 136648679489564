import React from 'react';
import { Button, Popover, Tag } from 'antd';
import { DocumentIcon } from 'components/icons';
import Vspace from 'components/custom/Vspace';

const MenuAddReport = (
  <>
    <div className="wac-calendar">
      <div className="wac-calendar-header">
        <span className="ant-typography is-600">Janeiro 2020</span>
        <div className="div">
          <button type="button" className="wac-calendar-btn">
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.209378 7.73272C-0.076309 8.03264 -0.0647656 8.50738 0.235161 8.79306C0.535087 9.07875 1.00982 9.06721 1.29551 8.76728L7.25 2.516V17.25C7.25 17.6642 7.58579 18 8 18C8.41421 18 8.75 17.6642 8.75 17.25V2.51565L14.7048 8.76728C14.9905 9.06721 15.4652 9.07875 15.7652 8.79306C16.0651 8.50738 16.0766 8.03264 15.791 7.73272L8.72425 0.313787C8.56323 0.144741 8.35782 0.0447664 8.14428 0.0138645C8.09759 0.00476646 8.04935 0 8 0C7.9503 0 7.90174 0.00483322 7.85474 0.0140553C7.64168 0.0451765 7.43677 0.145086 7.27608 0.313787L0.209378 7.73272Z"
                fill="#212121"
              />
            </svg>
          </button>
          <button type="button" className="wac-calendar-btn">
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7905 10.2673C16.0762 9.96736 16.0646 9.49262 15.7647 9.20694C15.4648 8.92125 14.99 8.93279 14.7043 9.23272L8.74985 15.484V0.75C8.74985 0.335787 8.41406 0 7.99985 0C7.58563 0 7.24985 0.335787 7.24985 0.75V15.4844L1.29502 9.23272C1.00933 8.93279 0.534599 8.92125 0.234673 9.20694C-0.065254 9.49262 -0.0767973 9.96736 0.20889 10.2673L7.27559 17.6862C7.43661 17.8553 7.64203 17.9552 7.85557 17.9861C7.90226 17.9952 7.9505 18 7.99985 18C8.04954 18 8.09811 17.9952 8.1451 17.9859C8.35817 17.9548 8.56308 17.8549 8.72377 17.6862L15.7905 10.2673Z"
                fill="#212121"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="wac-calendar-body">
        <div className="calendar-row">
          <div className="wac-calendar-day weekday">D</div>
          <div className="wac-calendar-day weekday">S</div>
          <div className="wac-calendar-day weekday">T</div>
          <div className="wac-calendar-day weekday">Q</div>
          <div className="wac-calendar-day weekday">Q</div>
          <div className="wac-calendar-day weekday">S</div>
          <div className="wac-calendar-day weekday">S</div>
        </div>
        <div className="calendar-row">
          <div className="wac-calendar-day disabled">28</div>
          <div className="wac-calendar-day disabled">29</div>
          <div className="wac-calendar-day disabled">30</div>
          <div className="wac-calendar-day disabled">31</div>
          <div className="wac-calendar-day">1</div>
          <div className="wac-calendar-day">2</div>
          <div className="wac-calendar-day">3</div>
        </div>
        <div className="calendar-row">
          <div className="wac-calendar-day">4</div>
          <div className="wac-calendar-day">5</div>
          <div className="wac-calendar-day">6</div>
          <div className="wac-calendar-day">7</div>
          <div className="wac-calendar-day selected">8</div>
          <div className="wac-calendar-day selected">9</div>
          <div className="wac-calendar-day">10</div>
        </div>
        <div className="calendar-row">
          <div className="wac-calendar-day">11</div>
          <div className="wac-calendar-day">12</div>
          <div className="wac-calendar-day">13</div>
          <div className="wac-calendar-day selected">14</div>
          <div className="wac-calendar-day selected">15</div>
          <div className="wac-calendar-day selected">16</div>
          <div className="wac-calendar-day">17</div>
        </div>
        <div className="calendar-row">
          <div className="wac-calendar-day">18</div>
          <div className="wac-calendar-day selected">19</div>
          <div className="wac-calendar-day">20</div>
          <div className="wac-calendar-day">21</div>
          <div className="wac-calendar-day">22</div>
          <div className="wac-calendar-day">23</div>
          <div className="wac-calendar-day">24</div>
        </div>
        <div className="calendar-row">
          <div className="wac-calendar-day">25</div>
          <div className="wac-calendar-day">26</div>
          <div className="wac-calendar-day selected">27</div>
          <div className="wac-calendar-day selected">28</div>
          <div className="wac-calendar-day selected">29</div>
          <div className="wac-calendar-day selected">30</div>
          <div className="wac-calendar-day">31</div>
        </div>
      </div>
    </div>

    <Vspace />
    <hr />
    <Button className="is-primary is-small is-full">
      Download boletim do dia
    </Button>
  </>
);

const PopoverTitle = (
  <div className="group is-fluid">
    <Tag className="is-primary">Recortes</Tag>
    <Tag>Andamentos processuais</Tag>
  </div>
);

const PopoverAddReport = (): React.ReactElement => {
  return (
    <Popover content={MenuAddReport} title={PopoverTitle} trigger="click">
      <Button key="add-report" type="text" icon={<DocumentIcon />} />
    </Popover>
  );
};

export default PopoverAddReport;
