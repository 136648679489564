import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import {
  ILawsuitCardDTO,
  ILawsuitClippingDTO,
  ILawsuitFiltersDTO,
  ILawsuitFolderDTO,
  ILawsuitPageDTO,
  ILawsuitSummaryDTO,
  IMoveManyResultDTO,
  IShareByEmailDTO,
} from 'types';
import { PAGE_SIZE } from 'utils/consts';

const lawsuitService = {
  getSummary: (): Promise<AxiosResponse<ILawsuitSummaryDTO>> => {
    return http.get('Lawsuit/Summary');
  },
  getAll: ({
    pageNumber = 1,
    pageSize = PAGE_SIZE,
    filters = {},
  }: {
    pageNumber?: number;
    pageSize?: number;
    filters?: ILawsuitFiltersDTO;
  }): Promise<AxiosResponse<ILawsuitPageDTO>> => {
    const params = {
      ...filters,
      pageNumber,
      pageSize,
    };
    return http.get(`/Lawsuit`, { params });
  },
  shareByEmail: (params: IShareByEmailDTO): Promise<AxiosResponse<void>> => {
    return http.post('/Lawsuit/ShareByEmail', params);
  },
  getClippingDoc: ({
    lawsuitNumber,
    downloadFile = false,
  }: {
    lawsuitNumber: string;
    downloadFile?: boolean;
  }): Promise<AxiosResponse<number>> => {
    const params = {
      lawsuitList: [lawsuitNumber],
      downloadFile,
    };
    return http.post('/Lawsuit/PrintOut', params, {
      responseType: 'arraybuffer',
    });
  },
  get: (lawsuitId: string): Promise<AxiosResponse<ILawsuitClippingDTO>> => {
    return http.get(`Lawsuit/${lawsuitId}`);
  },
  setReadSingle: (
    lawsuitId: string,
  ): Promise<AxiosResponse<ILawsuitCardDTO>> => {
    return http.patch(`/Lawsuit/${lawsuitId}/ReadSingle`);
  },
  setUnreadMany: (idList: string[]): Promise<AxiosResponse<void>> => {
    return http.patch('/Lawsuit/UnreadMany', { lawsuitIds: idList });
  },
  setReadMany: (idList: string[]): Promise<AxiosResponse<void>> => {
    return http.patch('/Lawsuit/ReadMany', { lawsuitIds: idList });
  },
  createFolder: ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }): Promise<AxiosResponse<ILawsuitFolderDTO>> => {
    return http.post('/UserFolder', { name, description });
  },
  setMoveManyFolder: (
    idList: string[],
    folderId: string,
  ): Promise<AxiosResponse<IMoveManyResultDTO[]>> => {
    return http.patch('/Lawsuit/MoveMany', {
      userFolderId: folderId,
      lawsuitIds: idList,
    });
  },
  getAllFolder: (): Promise<AxiosResponse<Array<ILawsuitFolderDTO>>> => {
    return http.get('/UserFolder');
  },
};

export default lawsuitService;
