import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacMainNav = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        MainNav
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente do header da aplicação, exceto pela logo, composto por:
            </div> 
            <div className='code-component'>
              {`<OverlaidContent>`} {`<Text>`} {`<Tooltip>`} {`<Button>`} {`<DropdownUser>`}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<OverlaidContent />`}
              <br />
              {`<Text className='is-white'>`}
              <br />
                {`{heading}`}
                <br />
              {`</Text>`}
              <br />
              {`<div>`}
              <br />
                {`{mainNavData.map(({ key, title, icon, counter }) => (`}
                <br />
                  {`<Tooltip`}
                  <br />
                    {`key={key}`}
                    <br />
                    {`mouseEnterDelay={0.7}`}
                    <br />
                    {`title={title}`}
                    <br />
                    {`placement="bottomRight">`}
                    <br />
                    {`<Button`}
                    <br />
                      {`key={key}`}
                      <br />
                      {`type='text'`}
                      <br />
                      {`icon={icon}`}
                      <br />
                      {`counter={counter} />`}
                      <br />
                  {`</Tooltip>`}
                  <br />
                {`))}`}
                <br />
              {`</div>`}
              <br />
              {`<Tooltip mouseEnterDelay={0.7} title={'Seu perfil'}>`}
              <br />
                {`<DropdownUser key='more' />`}
                <br />
              {`</Tooltip>`}

              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacMainNav;