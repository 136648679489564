import React from 'react';

const DataPieSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 4.25C10.6642 4.25 11 4.58579 11 5V13H19C19.3797 13 19.6935 13.2822 19.7432 13.6482L19.75 13.75C19.75 18.7206 15.7206 22.25 10.75 22.25C5.77944 22.25 1.75 18.2206 1.75 13.25C1.75 8.27944 5.27944 4.25 10.25 4.25ZM9.5 5.787L9.29109 5.81073C5.6018 6.28155 3.25 9.43274 3.25 13.25C3.25 17.3921 6.60786 20.75 10.75 20.75C14.5673 20.75 17.7185 18.3982 18.1893 14.7089L18.212 14.5H10.25C9.8703 14.5 9.55651 14.2178 9.50685 13.8518L9.5 13.75V5.787ZM13.25 1.75C18.2206 1.75 22.25 5.77944 22.25 10.75C22.25 11.1642 21.9142 11.5 21.5 11.5H13.25C12.8358 11.5 12.5 11.1642 12.5 10.75V2.5C12.5 2.08579 12.8358 1.75 13.25 1.75ZM14 3.287V10H20.712L20.6893 9.79109C20.2585 6.41578 17.5842 3.74148 14.2089 3.31073L14 3.287Z"
      fill="#828282"
    />
  </svg>
);

export default DataPieSvg;
