import React from 'react';
import { 
  LogoIcon,
  CalendarIcon,
  DocumentCopyIcon,
  DocumentAutosaveIcon,
  DataPieIcon,
  EditIcon,
  AddCircleIcon,
  SettingsIcon,
  SendIcon,
  CutIcon,
  AlertIcon,
  CourtIcon,
  DocumentIcon,
  HelpCircleIcon,
  LoadIcon,
  EyeHideIcon,
  StarIcon,
  AddFileIcon,
  BlockedIcon,
  ShareAndroidIcon,
  PrintIcon,
  UploadIcon,
  CommentAddIcon,
  ResizeIcon,
  AlertCircleIcon,
  MicOnIcon,
  NewsIcon,
  TaskListIcon,
  CertificateIcon,
  SignedIcon,
  BookFormulaCompatibilityIcon,
  CopyMoveIcon,
  ChatHelpIcon,
  ClassIcon,
  ThreeDotsIcon,
  DocumentOnePageIcon,
  LinkIcon,
  DocumentDocIcon,
  DeleteIcon,
  OutlineEditIcon,
} from 'components/icons';
import Box from 'components/custom/Box';
import { Col, Divider, Row } from 'antd';


const WacIcon = () => {

  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Icons
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Box className='code-ex wac-icon'>
            <Row gutter={[20, 20]}>
              <Col span={8}>
                <LogoIcon /> - {`<LogoIcon />`}
                <br /> 
                <CalendarIcon /> - {`<CalendarIcon />`}
                <br />
                <DocumentCopyIcon /> - {`<DocumentCopyIcon />`}
                <br />
                <DocumentAutosaveIcon /> - {`<DocumentAutosaveIcon />`}
                <br />
                <DataPieIcon /> - {`<DataPieIcon />`}
                <br />
                <EditIcon /> - {`<EditIcon />`}
                <br />
                <AddCircleIcon /> - {`<AddCircleIcon />`}
                <br />
                <SettingsIcon /> - {`<SettingsIcon />`}
                <br />
                <SendIcon /> - {`<SendIcon />`}
                <br />
                <CutIcon /> - {`<CutIcon />`}
                <br />
                <AlertIcon /> - {`<AlertIcon />`}
                <br />
                <CourtIcon /> - {`<CourtIcon />`}
                <br />
                <DocumentIcon /> - {`<DocumentIcon />`}
                <br />
              </Col>
              <Col span={8}>
                <HelpCircleIcon /> - {`<HelpCircleIcon />`}
                <br />
                <LoadIcon /> - {`<LoadIcon />`}
                <br />
                <EyeHideIcon /> - {`<EyeHideIcon />`}
                <br />
                <StarIcon /> - {`<StarIcon />`}
                <br />
                <AddFileIcon /> - {`<AddFileIcon />`}
                <br />
                <BlockedIcon /> - {`<BlockedIcon />`}
                <br />
                <ShareAndroidIcon /> - {`<ShareAndroidIcon />`}
                <br />
                <PrintIcon /> - {`<PrintIcon />`}
                <br />
                <UploadIcon /> - {`<UploadIcon />`}
                <br />
                <CommentAddIcon /> - {`<CommentAddIcon />`}
                <br />
                <ResizeIcon /> - {`<ResizeIcon />`}
                <br />
                <AlertCircleIcon /> - {`<AlertCircleIcon />`}
                <br />
                <MicOnIcon /> - {`<MicOnIcon />`}
                <br />
                <NewsIcon /> - {`<NewsIcon />`}
                <br />
                <TaskListIcon /> - {`<TaskListIcon />`}
                <br />
              </Col>
              <Col span={8}>
                <CertificateIcon /> - {`<CertificateIcon />`}
                <br />
                <SignedIcon /> - {`<SignedIcon />`}
                <br />
                <BookFormulaCompatibilityIcon /> - {`<BookFormulaCompatibilityIcon />`}
                <br />
                <CopyMoveIcon /> - {`<CopyMoveIcon />`}
                <br />
                <ChatHelpIcon /> - {`<ChatHelpIcon />`}
                <br />
                <ClassIcon /> - {`<ClassIcon />`}
                <br />
                <ThreeDotsIcon /> - {`<ThreeDotsIcon />`}
                <br />
                <DocumentOnePageIcon /> - {`<DocumentOnePageIcon />`}
                <br />
                <LinkIcon /> - {`<LinkIcon />`}
                <br />
                <DocumentDocIcon /> - {`<DocumentDocIcon />`}
                <br />
                <DeleteIcon /> - {`<DeleteIcon />`}
                <br />
                <OutlineEditIcon /> - {`<OutlineEditIcon />`}
                <br />
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default WacIcon;