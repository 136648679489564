import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import Group from 'components/custom/Group';
import GroupChild from 'components/custom/GroupChild';

const { Paragraph } = Typography

const group = (
  <Paragraph code={true}>
    <Vspace />
      {`<Group className='is-right'>`}
      <br />
        {`<GroupChild>`}
        <br />
          {`1`}
          <br />
        {`</GroupChild>`}
        <br />
      {`</Group>`}
      <Vspace />
      {`<Group>`}
      <br />
        {`<GroupChild>`}
        <br />
          {`1`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`2`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`3`}
          <br />
        {`</GroupChild>`}
        <br />
      {`</Group>`}
      <Vspace />
      {`<Group className='is-vertical'>`}
      <br />
        {`<GroupChild>`}
        <br />
          {`1`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`2`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`3`}
          <br />
        {`</GroupChild>`}
        <br />
      {`</Group>`}
      <Vspace />
      {`<Group className='is-spread'>`}
      <br />
        {`<GroupChild>`}
        <br />
          {`1`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`2`}
          <br />
        {`</GroupChild>`}
        <br />
        {`<GroupChild>`}
        <br />
          {`3`}
          <br />
        {`</GroupChild>`}
        <br />
      {`</Group>`}
    <Vspace />
  </Paragraph>
);

const WacGroup = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          {'Group & GroupChild'}
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Group className='is-right'>
              <GroupChild>
                1
              </GroupChild>
            </Group>
          </Box>
          <Box className='code-ex'>
            <Group>
              <GroupChild>
                1
              </GroupChild>
              <GroupChild>
                2
              </GroupChild>
              <GroupChild>
                3
              </GroupChild>
            </Group>
          </Box>
          <Box className='code-ex'>
            <Group className='is-vertical'>
              <GroupChild>
                1
              </GroupChild>
              <GroupChild>
                2
              </GroupChild>
              <GroupChild>
                3
              </GroupChild>
            </Group>
          </Box>
          <Box className='code-ex'>
            <Group className='is-spread'>
              <GroupChild>
                1
              </GroupChild>
              <GroupChild>
                2
              </GroupChild>
              <GroupChild>
                3
              </GroupChild>
            </Group>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {group}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacGroup;
