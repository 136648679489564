import React from 'react';

const DocumentCopySvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM13.1284 2C13.7254 2 14.2979 2.23723 14.7199 2.65947L19.3383 7.28054C19.7599 7.70246 19.9968 8.27456 19.9968 8.87107V17.2542C19.9968 18.4969 18.9895 19.5042 17.7468 19.5042H8.75241C7.50977 19.5042 6.50241 18.4969 6.50241 17.2542V4.25C6.50241 3.00736 7.50977 2 8.75241 2H13.1284ZM12.9994 3.5H8.75241C8.33819 3.5 8.00241 3.83579 8.00241 4.25V17.2542C8.00241 17.6684 8.33819 18.0042 8.75241 18.0042H17.7468C18.161 18.0042 18.4968 17.6684 18.4968 17.2542L18.4964 9.003L15.25 9.00389C14.0591 9.00389 13.0844 8.07873 13.0052 6.90794L13 6.75389L12.9994 3.5ZM14.4994 4.561L14.5 6.75389C14.5 7.13359 14.7822 7.44738 15.1482 7.49704L15.25 7.50389L17.4404 7.503L14.4994 4.561Z"
      fill="#828282"
    />
  </svg>
);

export default DocumentCopySvg;
