import React, { useState, useEffect } from 'react';
import { Drawer, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import DrawerHeader from 'screens/ProceduralFollow/components/DrawerHeader';

const { Paragraph } = Typography;

interface Props {
  isVisible: boolean;
  modalData: string;
  onClose: () => void;
}

const DrawerDetails = ({
  isVisible,
  modalData,
  onClose,
}: Props): React.ReactElement => {
  const [_isVisible, setVisible] = useState(isVisible);
  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  return (
    <>
      <Drawer
        title={DrawerHeader()}
        placement="right"
        width={340}
        onClose={onClose}
        visible={_isVisible}
        mask={false}
        closeIcon={<CloseOutlined />}
      >
        <Paragraph>{JSON.stringify(modalData)}</Paragraph>
      </Drawer>
    </>
  );
};

export default DrawerDetails;
