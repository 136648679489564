import React from 'react';
import Icon from '@ant-design/icons';
import ClippingSvg from 'components/icons/ClippingSvg';
import ProceduralProgressSvg from 'components/icons/ProceduralProgressSvg';
import GeralSvg from 'components/icons/GeralSvg';
import ProceduralAnalysisSvg from 'components/icons/ProceduralAnalysisSvg';
import ProceduralTransmissionSvg from 'components/icons/ProceduralTransmissionSvg';
import PetitionSvg from 'components/icons/PetitionSvg';
import LogoSvg from 'components/icons/LogoSvg';
import CalendarSvg from 'components/icons/CalendarSvg';
import DocumentCopySvg from 'components/icons/DocumentCopySvg';
import DocumentAutosaveSvg from 'components/icons/DocumentAutosaveSvg';
import DataPieSvg from 'components/icons/DataPieSvg';
import EditSvg from 'components/icons/EditSvg';
import AddCircleSvg from 'components/icons/AddCircleSvg';
import SettingsSvg from 'components/icons/SettingsSvg';
import SendSvg from 'components/icons/SendSvg';
import CutSvg from 'components/icons/CutSvg';
import AlertSvg from 'components/icons/AlertSvg';
import CourtSvg from 'components/icons/CourtSvg';
import DocumentSvg from 'components/icons/DocumentSvg';
import HelpCircleSvg from 'components/icons/HelpCircleSvg';
import LoadSvg from 'components/icons/LoadSvg';
import EyeSvg from 'components/icons/EyeSvg';
import EyeHideSvg from 'components/icons/EyeHideSvg';
import StarSvg from 'components/icons/StarSvg';
import StarMarkedSvg from 'components/icons/StarMarkedSvg';
import AddFileSvg from 'components/icons/AddFileSvg';
import CommentAddSvg from 'components/icons/CommentAddSvg';
import UploadSvg from 'components/icons/UploadSvg';
import PrintSvg from 'components/icons/PrintSvg';
import BlockedSvg from 'components/icons/BlockedSvg';
import ShareAndroidSvg from 'components/icons/ShareAndroidSvg';
import ResizeSvg from 'components/icons/ResizeSvg';
import AlertCircleSvg from 'components/icons/AlertCircleSvg';
import MicOnSvg from 'components/icons/MicOnSvg';
import NewsSvg from 'components/icons/NewsSvg';
import TaskListSvg from 'components/icons/TaskListSvg';
import CertificateSvg from 'components/icons/CertificateSvg';
import BookFormulaCompatibilitySvg from 'components/icons/BookFormulaCompatibilitySvg';
import SignedSvg from 'components/icons/SignedSvg';
import CopyMoveSvg from 'components/icons/CopyMoveSvg';
import ChatHelpSvg from 'components/icons/ChatHelpSvg';
import ClassSvg from 'components/icons/ClassSvg';
import ThreeDotsSvg from 'components/icons/ThreeDotsSvg';
import DocumentOnePageSvg from 'components/icons/DocumentOnePageSvg';
import LinkSvg from 'components/icons/LinkSvg';
import DocumentDocSvg from 'components/icons/DocumentDocSvg';
import DeleteSvg from 'components/icons/DeleteSvg';
import OutlineEditSvg from 'components/icons/OutlineEditSvg';
import ChevronDownSvg from 'components/icons/ChevronDownSvg';

interface IconProps {
  onClick?: React.ReactEventHandler;
  href?: string;
  className?: string;
}

export const ProceduralAnalysisIcon = (
  props: IconProps,
): React.ReactElement => <Icon component={ProceduralAnalysisSvg} {...props} />;

export const ClippingIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ClippingSvg} {...props} />
);

export const ProceduralProgressIcon = (
  props: IconProps,
): React.ReactElement => <Icon component={ProceduralProgressSvg} {...props} />;

export const ProceduralTransmissionIcon = (
  props: IconProps,
): React.ReactElement => (
  <Icon component={ProceduralTransmissionSvg} {...props} />
);

export const PetitionIcon = (props: IconProps): React.ReactElement => (
  <Icon component={PetitionSvg} {...props} />
);

export const GeralIcon = (props: IconProps): React.ReactElement => (
  <Icon component={GeralSvg} {...props} />
);

export const LogoIcon = (props: IconProps): React.ReactElement => (
  <Icon component={LogoSvg} {...props} />
);

export const CalendarIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CalendarSvg} {...props} />
);

export const DocumentCopyIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DocumentCopySvg} {...props} />
);

export const DocumentAutosaveIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DocumentAutosaveSvg} {...props} />
);

export const DataPieIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DataPieSvg} {...props} />
);

export const EditIcon = (props: IconProps): React.ReactElement => (
  <Icon component={EditSvg} {...props} />
);

export const AddCircleIcon = (props: IconProps): React.ReactElement => (
  <Icon component={AddCircleSvg} {...props} />
);

export const SettingsIcon = (props: IconProps): React.ReactElement => (
  <Icon component={SettingsSvg} {...props} />
);

export const SendIcon = (props: IconProps): React.ReactElement => (
  <Icon component={SendSvg} {...props} />
);

export const CutIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CutSvg} {...props} />
);

export const AlertIcon = (props: IconProps): React.ReactElement => (
  <Icon component={AlertSvg} {...props} />
);

export const CourtIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CourtSvg} {...props} />
);

export const DocumentIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DocumentSvg} {...props} />
);

export const HelpCircleIcon = (props: IconProps): React.ReactElement => (
  <Icon component={HelpCircleSvg} {...props} />
);

export const LoadIcon = (props: IconProps): React.ReactElement => (
  <Icon component={LoadSvg} {...props} />
);

export const EyeIcon = (props: IconProps): React.ReactElement => (
  <Icon component={EyeSvg} {...props} />
);

export const EyeHideIcon = (props: IconProps): React.ReactElement => (
  <Icon component={EyeHideSvg} {...props} />
);

export const StarIcon = (props: IconProps): React.ReactElement => (
  <Icon component={StarSvg} {...props} />
);

export const StarMarkedIcon = (props: IconProps): React.ReactElement => (
  <Icon component={StarMarkedSvg} {...props} />
);

export const AddFileIcon = (props: IconProps): React.ReactElement => (
  <Icon component={AddFileSvg} {...props} />
);

export const BlockedIcon = (props: IconProps): React.ReactElement => (
  <Icon component={BlockedSvg} {...props} />
);

export const ShareAndroidIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ShareAndroidSvg} {...props} />
);

export const PrintIcon = (props: IconProps): React.ReactElement => (
  <Icon component={PrintSvg} {...props} />
);

export const UploadIcon = (props: IconProps): React.ReactElement => (
  <Icon component={UploadSvg} {...props} />
);

export const CommentAddIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CommentAddSvg} {...props} />
);

export const ResizeIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ResizeSvg} {...props} />
);

export const AlertCircleIcon = (props: IconProps): React.ReactElement => (
  <Icon component={AlertCircleSvg} {...props} />
);

export const MicOnIcon = (props: IconProps): React.ReactElement => (
  <Icon component={MicOnSvg} {...props} />
);

export const NewsIcon = (props: IconProps): React.ReactElement => (
  <Icon component={NewsSvg} {...props} />
);

export const TaskListIcon = (props: IconProps): React.ReactElement => (
  <Icon component={TaskListSvg} {...props} />
);

export const CertificateIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CertificateSvg} {...props} />
);

export const SignedIcon = (props: IconProps): React.ReactElement => (
  <Icon component={SignedSvg} {...props} />
);

export const BookFormulaCompatibilityIcon = (
  props: IconProps,
): React.ReactElement => (
  <Icon component={BookFormulaCompatibilitySvg} {...props} />
);

export const CopyMoveIcon = (props: IconProps): React.ReactElement => (
  <Icon component={CopyMoveSvg} {...props} />
);

export const ChatHelpIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ChatHelpSvg} {...props} />
);

export const ClassIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ClassSvg} {...props} />
);
export const ThreeDotsIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ThreeDotsSvg} {...props} />
);

export const DocumentOnePageIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DocumentOnePageSvg} {...props} />
);

export const LinkIcon = (props: IconProps): React.ReactElement => (
  <Icon component={LinkSvg} {...props} />
);

export const DocumentDocIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DocumentDocSvg} {...props} />
);

export const DeleteIcon = (props: IconProps): React.ReactElement => (
  <Icon component={DeleteSvg} {...props} />
);

export const OutlineEditIcon = (props: IconProps): React.ReactElement => (
  <Icon component={OutlineEditSvg} {...props} />
);

export const ChevronDownIcon = (props: IconProps): React.ReactElement => (
  <Icon component={ChevronDownSvg} {...props} />
);
