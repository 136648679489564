import React from 'react';
import { Menu, Typography } from 'antd';
import {
  CalendarIcon,
  ClippingIcon,
  GeralIcon,
  PetitionIcon,
  ProceduralAnalysisIcon,
  ProceduralProgressIcon,
  ProceduralTransmissionIcon,
  SettingsIcon,
} from 'components/icons';

interface Props {
  current: string;
  // Antd doesn't have a type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: (e: any) => void;
}

const Sidebar = ({ current, handleClick }: Props): React.ReactElement => {
  return (
    <div className="sidebar-settings">
      <Typography.Title
        level={5}
        className="d-flex justify-content-start w-100 title "
      >
        <SettingsIcon className="mr-5" />
        Configurações
      </Typography.Title>
      <Menu onClick={handleClick} selectedKeys={[current]}>
        <Menu.Item key="geral">
          <div className="option">
            <GeralIcon /> Geral
          </div>
        </Menu.Item>
        <Menu.Item key="clipping">
          <div className="option disabled">
            <ClippingIcon /> Recortes
          </div>
        </Menu.Item>
        <Menu.Item key="proceduralProgress">
          <div className="option disabled">
            <ProceduralProgressIcon /> Andamento Processual
          </div>
        </Menu.Item>
        <Menu.Item key="agenda">
          <div className="option disabled">
            <CalendarIcon /> Agenda
          </div>
        </Menu.Item>
        <Menu.Item key="proceduralAnalysis">
          <div className="option disabled">
            <ProceduralAnalysisIcon /> Análise de Processo
          </div>
        </Menu.Item>
        <Menu.Item key="proceduralTransmission">
          <div className="option disabled">
            <ProceduralTransmissionIcon /> Transmissão Processual
          </div>
        </Menu.Item>
        <Menu.Item key="petition">
          <div className="option disabled">
            <PetitionIcon /> Petição
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
