import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';

import lawsuitProgressService from 'api/endpoints/lawsuitProgress';
import { PDF_DOCUMENT } from 'utils/consts';

interface Props {
  lawsuitNumber: string;
}

export default function useLawsuitProgressGetLawsuitDoc({
  lawsuitNumber,
}: Props): UseQueryResult<AxiosResponse<number>, string> {
  const query = useQuery<AxiosResponse<number>, string, AxiosResponse<number>>(
    [PDF_DOCUMENT, lawsuitNumber],
    async () => lawsuitProgressService.getLawsuitDoc({ lawsuitNumber }),
    { keepPreviousData: true },
  );

  return query;
}
