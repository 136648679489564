import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacCardList = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        CardList
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de lista de cards representando processos, contendo header, 
              content, dropdown e link, com variações de estado para danger, 
              warning e success, composto por:
            </div> 
            <div className='code-component'>
              {`<Card>`} {`<Group>`} {`<Checkbox>`} {`<CardListDropdown>`} {`<Vspace>`} {`<Link>`} {`<Paragraph>`} {`<Tag>`}.
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<Card key={id} className={deadline}>`}
              <br />
                {`<Group className='is-spread'>`}
                <br />
                  {`{<Checkbox onChange={onChange}}>`}
                  <br />
                    {`{id}`}
                    <br />
                  {`</Checkbox>`}
                  <br />
                  {`<CardListDropdown />`}
                  <br />
                {`</Group>`}
                <br />
                {`<Vspace />`}
                <br />
                {`<Link to={'/'}>`}
                <br />
                  {`<Paragraph className='is-600'>`}
                  <br />
                    {`{title}`}
                    <br />
                  {`</Paragraph>`}
                  <br />
                {`</Link>`}
                <br />
                {`<Paragraph className='is-size-14'>`}
                <br />
                  {`{subtitle}`}
                  <br />
                {`</Paragraph>`}
                <br />
                {`<Group className='is-right'>`}
                <br />
                  {`<Tag className={deadline}>{prazo}</Tag>`}
                  <br />
                {`</Group>`}
                <br />
              {`</Card>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCardList;