import React from 'react';

const UploadSvg = (): React.ReactElement => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4396 8.25C15.8028 8.25 16.1022 8.53953 16.1431 8.91253L16.1479 9V15C16.1479 16.6066 14.9552 17.9183 13.4562 17.9963L13.3148 18H3.399C1.88175 18 0.643064 16.737 0.569385 15.1497L0.565918 15V9C0.565918 8.58579 0.883022 8.25 1.27419 8.25C1.63742 8.25 1.93678 8.53953 1.9777 8.91253L1.98246 9V15C1.98246 15.7908 2.56032 16.4386 3.29328 16.4959L3.399 16.5H13.3148C14.0616 16.5 14.6734 15.8881 14.7275 15.1119L14.7313 15V9C14.7313 8.58579 15.0484 8.25 15.4396 8.25ZM3.34867 4.99264L7.85608 0.21967C8.1114 -0.0506931 8.51315 -0.0714902 8.791 0.157278L8.85772 0.21967L13.3651 4.99264C13.6417 5.28553 13.6417 5.76041 13.3651 6.0533C13.1098 6.32366 12.7081 6.34446 12.4302 6.11569L12.3635 6.0533L9.06517 2.5605V12C9.06517 12.3846 8.79175 12.7016 8.4395 12.745L8.3569 12.75C7.99367 12.75 7.69431 12.4605 7.65339 12.0875L7.64863 12V2.5605L4.35031 6.0533C4.09499 6.32366 3.69324 6.34446 3.41539 6.11569L3.34867 6.0533C3.09335 5.78294 3.07371 5.35751 3.28975 5.0633L3.34867 4.99264L7.85608 0.21967L3.34867 4.99264Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default UploadSvg;
