import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import Group from 'components/custom/Group';

const { Paragraph } = Typography

const avatar = (
  <Paragraph code={true}>
    <Vspace />
      {`<Group className='is-around'>`}
      <br />
        {`<Avatar size={64} icon={<UserOutlined />} />`}
        <br />
        {`<Avatar size="large" icon={<UserOutlined />} />`}
        <br />
        {`<Avatar icon={<UserOutlined />} />`}
        <br />
        {`<Avatar size="small" icon={<UserOutlined />} />`}
        <br />
      {`</Group>`}
      <Vspace />
      {`<Group className='is-around'>`}
      <br />
        {`<Avatar shape="square" size={64} icon={<UserOutlined />} />`}
        <br />
        {`<Avatar shape="square" size="large" icon={<UserOutlined />} />`}
        <br />
        {`<Avatar shape="square" icon={<UserOutlined />} />`}
        <br />
        {`<Avatar shape="square" size="small" icon={<UserOutlined />} />`}
        <br />
      {`</Group>`}
    <Vspace />
  </Paragraph>
);

const WacAvatar = () => {
  
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Avatar
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Group className='is-around'>
              <Avatar size={64} icon={<UserOutlined />} />
              <Avatar size="large" icon={<UserOutlined />} />
              <Avatar icon={<UserOutlined />} />
              <Avatar size="small" icon={<UserOutlined />} />
            </Group>
          </Box>
          <Box className='code-ex'>
            <Group className='is-around'>
              <Avatar shape="square" size={64} icon={<UserOutlined />} />
              <Avatar shape="square" size="large" icon={<UserOutlined />} />
              <Avatar shape="square" icon={<UserOutlined />} />
              <Avatar shape="square" size="small" icon={<UserOutlined />} />
            </Group>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {avatar}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacAvatar;
