import React, { useContext, useEffect } from 'react';
import { Card, Checkbox, Typography, Empty, Skeleton } from 'antd';
import Group from 'components/custom/Group';
import Vspace from 'components/custom/Vspace';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useRouteMatch,
  RouteComponentProps,
  useLocation,
  Link,
} from 'react-router-dom';

import { ProcessContext } from 'context/Process';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { hasMorePages } from 'utils/handlers';
import { ILawsuitCard, ILawsuitPage } from 'types';
import { useToolbar } from 'context/Toolbar';
import CardListDropdown from 'screens/ClippingsAndSummons/components/CardListDropdown';

const { Title, Text } = Typography;

export interface CardListLocationState {
  pageNumber: number;
}

interface CardListProps extends RouteComponentProps<{ topicId: string }> {
  nextHandler: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult>;
  isLoading: boolean;
  pages: ILawsuitPage[] | undefined;
}

const CardList = ({
  nextHandler,
  pages,
  isLoading,
  history,
}: CardListProps): React.ReactElement<CardListProps> => {
  const location = useLocation();
  const routeMatch = useRouteMatch();
  const { setSelected } = useToolbar();
  const { setProcess } = useContext(ProcessContext);

  const urlId = [...location.pathname.split('/')].pop();
  const cardColour = (lawsuit: ILawsuitCard): string => {
    if (lawsuit.id === urlId) {
      return 'is-selected';
    }
    if (!lawsuit.isRead) {
      return 'is-not-readed';
    }
    return '';
  };
  const onChange = (e: CheckboxChangeEvent, lawsuit: ILawsuitCard): void => {
    if (!e.target.checked) {
      setSelected((prev: ILawsuitCard[]) =>
        prev.filter((card: ILawsuitCard) => {
          return card.id !== lawsuit.id;
        }),
      );
    } else {
      setSelected((prev: ILawsuitCard[]) => [...prev, lawsuit]);
    }
  };

  useEffect(() => {
    if (!isLoading && pages) {
      history.push(`${routeMatch.url}/${pages[0].lawsuits[0].id}`, {
        pageNumber: 0,
      });
    }
  }, [isLoading, routeMatch.url, pages, history]);

  if (isLoading) return <Skeleton active />;
  if ((pages && pages[0].pageInfo.totalItens === 0) || !pages) return <Empty />;

  return (
    <InfiniteScroll
      dataLength={pages.reduce(
        (acc: number, page: ILawsuitPage) => acc + page.lawsuits.length,
        0,
      )}
      next={nextHandler}
      hasMore={hasMorePages(pages)}
      loader={<h4>Carregando...</h4>}
      scrollableTarget="clipping-card-list"
    >
      {pages.map((page: ILawsuitPage, index: number) =>
        page.lawsuits.map((lawsuit: ILawsuitCard) => (
          <Card key={lawsuit.id} className={cardColour(lawsuit)}>
            <Group className="is-spread">
              <Checkbox
                className="is-primary"
                onChange={(e: CheckboxChangeEvent) => onChange(e, lawsuit)}
              >
                {lawsuit.header}
              </Checkbox>
              <CardListDropdown lawsuit={lawsuit} />
            </Group>
            <Vspace />
            <Link
              onClick={() => {
                setProcess(lawsuit.lawsuitNumber);
              }}
              to={{
                pathname: `${routeMatch.url}/${lawsuit.id}`,
                state: {
                  pageNumber: index,
                },
              }}
            >
              <Title className="is-size-13 is-600">{lawsuit.title}</Title>
            </Link>
            <Text>{lawsuit.content}</Text>
          </Card>
        )),
      )}
    </InfiniteScroll>
  );
};

export default CardList;
