import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import http from 'api/endpoints/http';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

type ConfirmationStates = 'PENDING' | 'LOADING' | 'DONE' | 'FAILURE';
interface UserConfirmationStatus {
  state: ConfirmationStates;
  token?: string;
}

const UserConfirmation: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [status, setStatus] = useState<UserConfirmationStatus>({
    state: 'PENDING',
  });

  const userId = new URLSearchParams(search).get('user');
  const keyConfirmEmail = new URLSearchParams(search).get('key');

  useEffect(() => {
    const confirmUser = async (): Promise<void> => {
      try {
        setStatus({ state: 'LOADING' });
        const response = await http.post('user-confirmation', {
          userId,
          keyConfirmEmail,
        });

        const { token } = response.data;

        setStatus({ state: 'DONE', token });
        message.success(
          'Usuario autenticado com sucesso! Voce será redirecionado para a pagina de login.',
        );
      } catch (errorDescription) {
        setStatus({ state: 'FAILURE' });
        message.error(errorDescription);
        history.push('/');
      }
    };
    confirmUser().then();
  }, [setStatus, userId, keyConfirmEmail, history]);

  const { state, token } = status;
  return (
    <>
      {state === 'LOADING' || state === 'PENDING' ? (
        <>
          <span>Verificando usuario...</span>
          <LoadingOutlined />
        </>
      ) : (
        <Redirect to={`/reset-password?key=${token}`} />
      )}
    </>
  );
};

export default UserConfirmation;
