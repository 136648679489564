import React from 'react';
import PDFIcon from 'components/commons/PDFMenu/PDFIcon';
import useLawsuitGetClippingDoc from 'api/queries/useLawsuitGetClippingDoc';

interface Props {
  lawsuitNumber: string;
}

const PDFClipping = ({ lawsuitNumber }: Props): React.ReactElement => {
  const { isLoading, error, data: response } = useLawsuitGetClippingDoc({
    lawsuitNumber,
  });

  return (
    <PDFIcon pdfData={response?.data} isLoading={isLoading} error={error} />
  );
};

export default PDFClipping;
