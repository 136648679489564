import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacAppSidebar = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        AppSidebar
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente da sidebar principal de ícones da aplicação, composto por: 
            </div> 
            <div className='code-component'>
              {`<Sider>`} {`<Menu>`} {`<Menu.Item>`} {`<SubMenu>`} {`<Link>`} {`<AppSidebarSubMenu>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<Sider className='is-app-sider-container'>`}
              <br />
                {`<Menu`}
                <br />
                  {`className='is-app-sider'`}
                  <br />
                  {`onClick={handleClick}`}
                  <br />
                  {`selectedKeys={[current]}`}
                  <br />
                  {`mode='vertical'>`}
                  <br />
                  {`{appMenuSidebarData.map(({ key, icon, label, link }) => (`}
                  <br />
                    {`<Menu.Item key={key} icon={icon}>`}
                    <br />
                      {`<span className="wac-menu-item-label">{label}</span>`}
                      <br />
                      {`<Link to={'/'} />`}
                      <br />
                    {`</Menu.Item>`}
                    <br />
                  {`))}`}
                  <br />
                  {`<SubMenu className='wac-submenu is-invert' key="MaisServicos" icon={<AddCircleIcon />} title="Mais serviços" onTitleClick={['title']}>`}
                  <br />
                    {`<AppSidebarSubMenu />`}
                    <br />
                  {`</SubMenu>`}
                  <br />
                {`</Menu>`}
                <br />
              {`</Sider>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacAppSidebar;