import { Switch as AntSwitch, Form } from 'antd';
import Label from 'components/commons/Label';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  initialValue?: boolean;
  control: Control;
  errors: FieldErrors;
}

const SwitchField = ({
  name,
  label,
  initialValue = true,
  control,
  errors,
}: Props): React.ReactElement => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value, ...props }) => {
        const onChangeSwitch = (checked: boolean): void => {
          onChange(checked);
        };
        return (
          <Form.Item
            validateStatus={errors[name] ? 'error' : ''}
            help={errors[name]?.message}
          >
            <Label>{label}</Label>
            <AntSwitch
              {...props}
              checked={value}
              defaultChecked={initialValue}
              onChange={onChangeSwitch}
            />
          </Form.Item>
        );
      }}
    />
  );
};

export default SwitchField;
