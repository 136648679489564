import { format } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';

export const renderAmount = (value: string): string =>
  value ? `R$ ${value}` : '-';

export const renderDegree = (value: string): string =>
  value ? `${value}º instância` : '-';

export const renderDate = (value: string): string =>
  value ? format(new Date(value), 'dd/MM/yyyy') : '-';

export const buildColumns = (
  labels: Array<string>,
): Array<{ title: string; dataIndex: string }> =>
  labels.map((label) => ({
    title: label,
    dataIndex: label,
  }));

interface Column {
  dataIndex: string;
  title: string;
}

export function buildData(
  columns: Array<Column>,
  dados: string[][],
): ColumnsType<{ title: string }> {
  const data = [];
  for (let i = 0; i < dados.length; i += 1) {
    let insert = {};
    for (let j = 0; j < columns.length; j += 1) {
      insert = { ...insert, [columns[j].dataIndex]: dados[i][j] || '-' };
    }
    data.push(insert);
  }
  return data;
}
