import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Drawer, Form, message } from 'antd';
import Group from 'components/custom/Group';
import RoleSelectField from 'components/form/RoleSelectField';
import TextField from 'components/form/TextField';
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import Button from 'components/commons/Button';
import useUserCreate from 'api/mutations/useUserCreate';

interface UserForm {
  name: string;
  email: string;
  roleId: string;
}

const TwoFactorSchema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  email: yup.string().required('O email é obrigatório.'),
  roleId: yup.string().required('O papel é obrigatório.'),
});

const DrawerAddUser = (): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const { handleSubmit, control, errors, reset } = useForm<UserForm>({
    resolver: yupResolver(TwoFactorSchema),
    defaultValues: {
      name: '',
      email: '',
      roleId: '',
    },
  });
  const { mutateAsync: createMutation, isLoading } = useUserCreate();

  const showDrawer = (): void => setVisible(true);

  const onClose = (): void => {
    reset();
    setVisible(false);
  };

  const onSubmit: SubmitHandler<UserForm> = async (form: UserForm) => {
    try {
      await createMutation(form);
      message.success('Usuário cadastrado com sucesso!');
      onClose();
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  return (
    <>
      <Button className="is-primary" onClick={showDrawer}>
        Cadastrar
      </Button>
      <Drawer
        title="Cadastrar novo usuário"
        placement="right"
        width={340}
        onClose={onClose}
        visible={visible}
        mask={false}
        closeIcon={<CloseOutlined />}
      >
        <Form name="lawsuit" onFinish={handleSubmit(onSubmit)}>
          <TextField
            name="name"
            label="Nome"
            control={control}
            errors={errors}
          />
          <TextField
            name="email"
            label="E-mail"
            control={control}
            errors={errors}
          />
          <RoleSelectField
            name="roleId"
            label="Papel"
            control={control}
            errors={errors}
          />
          <Group className="is-right">
            <Button
              disabled={isLoading}
              className="is-secondary is-small"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button htmlType="submit" className="is-primary is-small">
              {isLoading ? <LoadingOutlined /> : 'Salvar e fechar'}
            </Button>
          </Group>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerAddUser;
