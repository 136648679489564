import React from 'react';

const ClippingSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1636 11.0805L12.7903 13.0534L12.7617 13.0477L12.756 13.0187L14.7002 9.59472L13.6833 8.99994L11.5266 12.7979L11.5045 12.7936L10.6005 13.2819C10.5768 13.2242 10.5504 13.1672 10.5211 13.1112C10.186 12.4724 9.5345 12.0755 8.821 12.0755C8.50805 12.0755 8.19609 12.1545 7.91835 12.3044C7.46459 12.5496 7.13189 12.9595 6.98187 13.4586C6.83166 13.9577 6.88212 14.4862 7.12368 14.9466C7.45852 15.5854 8.10983 15.9825 8.82354 15.9825C9.13668 15.9825 9.44903 15.9033 9.72696 15.7532C10.1805 15.508 10.5132 15.0981 10.6632 14.5992C10.6634 14.5984 10.6636 14.5976 10.6638 14.5968L11.6862 14.0447L11.764 14.0604L11.7797 14.14L11.2344 15.1798C10.7532 15.3285 10.3407 15.6633 10.0966 16.1282C9.59807 17.0791 9.95443 18.2645 10.8911 18.7704C11.1688 18.9207 11.4812 18.9999 11.7943 18.9999C12.5078 18.9999 13.1594 18.6028 13.4942 17.9638C13.993 17.0134 13.6368 15.8282 12.6997 15.3214C12.645 15.2918 12.5878 15.2672 12.5305 15.243L13.0125 14.3238L13.008 14.3008L16.75 12.1122L16.1636 11.0805ZM9.54057 14.2515C9.48209 14.4461 9.35241 14.6061 9.1756 14.7018C9.06548 14.7614 8.94676 14.7915 8.82334 14.7915C8.54443 14.7915 8.28997 14.6367 8.15932 14.3871C8.06504 14.2075 8.04529 14.0014 8.10397 13.8064C8.16264 13.6119 8.29232 13.4517 8.46913 13.356C8.57925 13.2966 8.69758 13.2667 8.8208 13.2667C9.09971 13.2667 9.35437 13.4215 9.48502 13.671C9.57949 13.8507 9.59905 14.0568 9.54057 14.2515ZM12.4582 17.4043C12.3273 17.6539 12.0729 17.8089 11.7939 17.8089C11.6707 17.8089 11.5526 17.7787 11.4423 17.7192C11.0767 17.5219 10.9379 17.0593 11.1323 16.6882C11.2631 16.4387 11.5178 16.2838 11.7965 16.2838C11.9197 16.2838 12.0378 16.3138 12.1476 16.3732C12.5135 16.5709 12.6528 17.0335 12.4582 17.4043Z"
      fill="#828282"
    />
    <path
      d="M14.8361 1.99998H6.52798C5.10156 1.99998 3.94116 3.17753 3.94116 4.62527V19.3746C3.94116 20.8221 5.10136 21.9999 6.52798 21.9999H17.1193C18.5455 21.9999 19.7059 20.8223 19.7059 19.3746V7.5243L14.8361 1.99998ZM15.1766 4.26271L17.7113 7.13776H15.1766V4.26271ZM17.1193 20.7489H6.52798C5.78108 20.7489 5.17366 20.1324 5.17366 19.3746V4.62527C5.17366 3.86741 5.78108 3.25091 6.52798 3.25091H13.9441V8.38869H18.4734V19.3746C18.4734 20.1324 17.8659 20.7489 17.1193 20.7489Z"
      fill="#828282"
    />
  </svg>
);

export default ClippingSvg;
