import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const Petitions = (): React.ReactElement => {
  return <Title>Petições</Title>;
};

export default Petitions;
