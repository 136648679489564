import { LoadingOutlined } from '@ant-design/icons';
import { Form, Select as AntSelect } from 'antd';
import { Group } from 'antd/lib/avatar';
import Label from 'components/commons/Label';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

const { Option } = AntSelect;

interface OptionType {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label: string;
  initialValue?: string;
  options: OptionType[];
  control: Control;
  errors: FieldErrors;
  isLoading?: boolean;
  mode?: 'tags' | 'multiple' | undefined;
}

const SelectField = ({
  name,
  label: selectLabel,
  mode = undefined,
  initialValue,
  options = [],
  control,
  errors,
  isLoading,
}: Props): React.ReactElement => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ ...props }) => (
        <Form.Item
          validateStatus={errors[name] ? 'error' : ''}
          help={errors[name]?.message}
        >
          <Group>
            <Label>{selectLabel}</Label>
            {isLoading && <LoadingOutlined />}
          </Group>
          <AntSelect defaultValue={initialValue} {...props} mode={mode}>
            {options.map(({ value, label }: OptionType) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </AntSelect>
        </Form.Item>
      )}
    />
  );
};

export default SelectField;
