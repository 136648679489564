import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from 'context/Auth/index';
import GrifonRoutes from 'Routes';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import { ProcessProvider } from 'context/Process';
import 'components/styles/_theme.less';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

const queryClient = new QueryClient({ queryCache: new QueryCache() });

const App = (): React.ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router>
          <AuthProvider>
            <ProcessProvider>
              <ConfigProvider locale={ptBR}>
                <GrifonRoutes />
              </ConfigProvider>
            </ProcessProvider>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
