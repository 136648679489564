import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { IResponsiblePageDTO } from 'types';
import { GET_ALL_RESPONSIBLES } from 'utils/consts';
import userService from 'api/endpoints/user';

export default function useUserGetAllResponsibles(): UseQueryResult<
  AxiosResponse<IResponsiblePageDTO>
> {
  const query = useQuery<AxiosResponse<IResponsiblePageDTO>>(
    [GET_ALL_RESPONSIBLES, { page: 1 }],
    userService.getAllResponsibles,
  );

  return query;
}
