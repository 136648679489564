import React from 'react';

const BlockedSvg = (): React.ReactElement => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7788 7C14.7788 3.13401 11.6448 0 7.77881 0C3.91282 0 0.778809 3.13401 0.778809 7C0.778809 10.866 3.91282 14 7.77881 14C11.6448 14 14.7788 10.866 14.7788 7ZM13.3788 7C13.3788 10.0928 10.8716 12.6 7.77881 12.6C6.48469 12.6 5.29311 12.161 4.34482 11.4239L12.2027 3.56601C12.9398 4.5143 13.3788 5.70589 13.3788 7ZM11.2127 2.57607L3.35488 10.4339C2.61775 9.48564 2.17881 8.29408 2.17881 7C2.17881 3.90721 4.68601 1.4 7.77881 1.4C9.07289 1.4 10.2645 1.83895 11.2127 2.57607Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default BlockedSvg;
