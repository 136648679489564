import { Form } from 'antd';
import FilterText from 'components/commons/Filter/FilterText';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

interface Props {
  name: string;
  label: string;
  mask: string;
  disabled?: boolean;
  control: Control;
  errors: FieldErrors;
}

const TextMaskField = ({
  name,
  label,
  mask,
  disabled = false,
  control,
  errors,
}: Props): React.ReactElement => {
  return (
    <Form.Item
      validateStatus={errors[name] ? 'error' : ''}
      help={errors[name]?.message}
    >
      <Controller
        name={name}
        control={control}
        render={({ value, onChange }) => (
          <ReactInputMask
            disabled={disabled}
            mask={mask}
            value={value}
            onChange={onChange}
          >
            <FilterText value={value} label={label} onChange={onChange} />
          </ReactInputMask>
        )}
      />
    </Form.Item>
  );
};

export default TextMaskField;
