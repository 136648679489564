import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import Label from 'components/commons/Label';
import React from 'react';
import { SelectValue } from 'antd/lib/select';
import useCourtGetAll from 'api/queries/useCourtGetAll';

const { Option } = Select;
interface Props {
  value?: SelectValue;
  onChange: (courtId: SelectValue) => void;
  implemented?: boolean;
}

const CourtSelect: React.FC<Props> = ({
  onChange,
  value,
  implemented = true,
}): React.ReactElement => {
  const { isLoading, data: queryData } = useCourtGetAll({ implemented });

  if (isLoading) {
    return <LoadingOutlined />;
  }

  const courts = queryData?.data || [];

  return (
    <>
      <Label>Tribunal</Label>
      <Select value={value} onChange={onChange} allowClear defaultValue="">
        {courts.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CourtSelect;
