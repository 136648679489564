import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacCardContent = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        CardContent
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente do conteúdo do CardList contendo header, conteúdo e footer, composto por:
            </div> 
            <div className='code-component'>
              {`<Box>`} {`<CardContentHead>`} {`<Vspace>`} {`<Title>`} {`<Paragraph>`} {`<CardContentFooter>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<>`}
              <br />
                {`<Box className='is-full'>`}
                <br />
                  {`<CardContentHead />`}
                  <br />
                  {`<Vspace />`}
                  <br />
                  {`<Title level={4}>`}
                  <br />
                    {`{PContent.title}`}
                    <br />
                  {`</Title>`}
                  <br />
                    {`{PContent.subtitle}`}
                    <br />
                  {`<Vspace />`}
                  <br />
                  {`<Paragraph>`}
                  <br />
                    {`{PContent.text}`}
                    <br />
                  {`</Paragraph>`}
                  <br />
                {`</Box>`}
                <br />
                {`<Vspace />`}
                <br />
                {`<Title className='is-size-12  is-primary'>`}
                <br />
                  {`03 Recortes relacionados:`}
                  <br />
                {`</Title>`}
                <br />
                {`<CardContentFooter topicId={topicId} />`}
                <br />
              {`</>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCardContent;