import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Card,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const card = (
  <Paragraph code={true}>
    <Vspace />
    {` <Card className='is-success'>`}
    <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
    {`</Card>`}
    <Vspace />
    {`<Card className='is-danger'>`}
    <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
    {`</Card>`}
    <Vspace />
    {`<Card className='is-warning'>`}
    <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
      {`<p>Card content</p>`}
      <br />
    {`</Card>`}
    <Vspace />
  </Paragraph>
);

const WacCard = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Card
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Card className='is-success'>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </Box>
          <Box className='code-ex'>
            <Card className='is-danger'>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </Box>
          <Box className='code-ex'>
            <Card className='is-warning'>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {card}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCard;
