import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacAppSidebarSubMenu = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        AppSidebarSubMenu
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente dinâmico, filho da sidebar de ícones, composto por: 
            </div> 
            <div className='code-component'>
              {`<Menu.Item>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`{appSidebarSubMenuData.map(({ key, icon, label }) => (`}
              <br />
                {`<Menu.Item key={key} icon={icon}>`}
                <br />
                  {`<span className="wac-menu-item-label">`}
                  <br />
                    {`{label}`}
                    <br />
                  {`</span>`}
                  <br />
                {`</Menu.Item>`}
                <br />
              {`))}`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacAppSidebarSubMenu;