import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import lawsuitService from 'api/endpoints/lawsuit';
import { GET_ALL_LAWSUIT } from 'utils/consts';

export default function useLawsuitUnreadMany(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  string[]
> {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (idList: string[]): Promise<AxiosResponse<void>> =>
      lawsuitService.setUnreadMany(idList),
    {
      onSuccess: () => queryClient.invalidateQueries(GET_ALL_LAWSUIT),
    },
  );

  return mutation;
}
