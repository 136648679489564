import userService from 'api/endpoints/user';
import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

export default function useUserActivate(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  string
> {
  const mutation = useMutation(
    (params: string): Promise<AxiosResponse<void>> => {
      return userService.activateUser(params);
    },
  );

  return mutation;
}
