import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Button,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import { AlertCircleIcon } from 'components/icons';

const { Paragraph } = Typography

const bcounter = (
  <Paragraph code={true}>
    <Vspace />
    {`<Button`}
    <br />
      {`key={'nav-alert'}`}
      <br />
      {`type='text'`}
      <br />
      {`icon={<AlertCircleIcon />}`}
      <br />
      {`counter={6} />`}
    <Vspace />
  </Paragraph>
);


const WacCounter = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Button Counter
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
          <Button
            key={'nav-alert'}
            type='text'
            icon={<AlertCircleIcon />}
            counter={6} />
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {bcounter}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCounter;
