import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import Label from 'components/commons/Label';

const { Paragraph } = Typography

const label = (
  <Paragraph code={true}>
    <Vspace />
    {`<Label>`}
    <br />
      {`Label padrão`}
      <br />
    {`</Label>`}
    <Vspace />
    {`<Label className='is-secondary is-small'>`}
    <br />
      {`Label secundária pequena`}
      <br />
    {`</Label>`}
    <Vspace />
    {`<Label className='is-large is-centralized'>`}
    <br />
      {`Label grande centralizada`}
      <br />
    {`</Label>`}
    <Vspace />
  </Paragraph>
);

const WacLabel = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Label
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Label>
              Label padrão
            </Label>
          </Box>
          <Box className='code-ex'>
            <Label className='is-secondary is-small'>
              Label secundária pequena
            </Label>
          </Box>
          <Box className='code-ex'>
            <Label className='is-large is-centralized'>
              Label grande centralizada
            </Label>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {label}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacLabel;
