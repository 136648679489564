import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacDrawerHeader = () => {


  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        DrawerHeader
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente do header do drawer lateral de detalhes de processo, composto por: 
            </div> 
            <div className='code-component'>
              {'<Title>'} {'<TextField>'} {'<Group>'} {'<GroupChild>'} {'<Tooltip>'}
            </div>
            <Vspace />
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<>`}
              <br />
                {`<Title>`}
                <br />
                  {`Visualização de arquivo`}
                  <br />
                {`</Title>`}
                <br />
                {`<Text>`}
                <br />
                  {`Nome: Petição número 123242`}
                  <br />
                {`</Text>`}
                <br />
                {`<Text>`}
                <br />
                  {`Tipo:: Word`}
                  <br />
                {`</Text>`}
                <br />
                {`<Text>`}
                <br />
                  {`Tamanho:33kb`}
                  <br />
                {`</Text>`}
                <br />
                {`<Group className='is-spread'>`}
                <br />
                  {`<GroupChild>`}
                  <br />
                    {`{itemHeadData.map(({ title, content }) => (`}
                    <br />
                    {`<Tooltip mouseEnterDelay={0.7} title={title}>`}
                    <br />
                      {`{content}`}
                      <br />
                    {`</Tooltip>`}
                    <br />
                    {`))}`}
                    <br />
                  {`</GroupChild>`}
                  <br />
                {`</Group>`}
                <br />
              {`</>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDrawerHeader;