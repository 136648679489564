import { AuthState, LoginForm, useAuth } from 'context/Auth';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import useRecaptcha from 'hooks/useRecaptcha';

export default function useLogin(): UseMutationResult<
  AuthState,
  unknown,
  LoginForm
> {
  const { login } = useAuth();
  const { executeCaptcha, isReady } = useRecaptcha();

  const mutation = useMutation<AuthState, AxiosError, LoginForm>(
    async (form: LoginForm) => {
      const recaptchaToken = await executeCaptcha();
      return login({ ...form, recaptchaToken });
    },
  );

  return {
    ...mutation,
    isLoading: !isReady || mutation.isLoading,
  };
}
