import React from 'react';
import {
  Row,
  Col,
  Divider,
  Button,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import { DocumentIcon } from 'components/icons'
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const button = (
  <Paragraph code={true}>
    <Vspace />
    {`<Button className='is-primary is-full is-rounded'>`}
    <br />
      {`Primário full arredondado`}
      <br />
    {`</Button>`}
    <Vspace />
    {`<Button className='is-primary'>`}
    <br />
      {`<DocumentIcon />`}
      <br />
      {`Primário ícone`}
      <br />
    {`</Button>`}
    <Vspace />
    {`<Button className='is-primary is-small'>`}
    <br />
      {`Primário small`}
      <br />
    {`</Button>`}
    <Vspace />
    {`<Button className='is-secondary'>`}
    <br />
      {`Secundário`}
      <br />
    {`</Button>`}
    <Vspace />
    {`<Button className='is-secondary is-bare'>`}
    <br />
      {`Secundário bare`}
      <br />
    {`</Button>`}
    <Vspace />
  </Paragraph>
);

const WacButton = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Button
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Button className='is-primary is-full is-rounded'>
              Primário full arredondado
            </Button>
          </Box>
          <Box className='code-ex'>
            <Button className='is-primary'>
              <DocumentIcon />
              Primário ícone
            </Button>
          </Box>
          <Box className='code-ex'>
            <Button className='is-primary is-small'>
              Primário small
            </Button>
          </Box>
          <Box className='code-ex'>
            <Button className='is-secondary'>
              Secundário
            </Button>
          </Box>
          <Box className='code-ex'>
            <Button className='is-secondary is-bare'>
              Secundário bare
            </Button>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {button}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacButton;
