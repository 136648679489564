import React, { useEffect } from 'react';
import { Drawer, Form, message, Button } from 'antd';
import Group from 'components/custom/Group';
import { CloseOutlined } from '@ant-design/icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from 'components/form/TextField';
import SwitchField from 'components/form/SwitchField';
import TextMaskField from 'components/form/TextMaskField';
import ResponsibleSelectField from 'components/form/ResponsibleSelectField';
import { ILawsuit } from 'types';
import useLawsuitProgressUpdate from 'api/mutations/useLawsuitProgressUpdate';

interface DrawerForm {
  lawsuitNumber: string;
  note: string;
  monitoring: boolean;
  responsibleUserId: string;
}

interface Props {
  data: ILawsuit | null;
  visible: boolean;
  onClose: () => void;
}

const TwoFactorSchema = yup.object().shape({
  lawsuitNumber: yup.string().required('O número do processo é obrigatório'),
  monitoring: yup.boolean(),
});

const DrawerEdit: React.FC<Props> = ({
  data,
  visible,
  onClose,
}): React.ReactElement => {
  const { lawsuitNumber, observation: note, monitoring, responsibleUserId } =
    data ?? ({} as ILawsuit);
  const { handleSubmit, control, errors, reset } = useForm<DrawerForm>({
    resolver: yupResolver(TwoFactorSchema),
  });
  const { mutateAsync: updateMutation } = useLawsuitProgressUpdate();

  useEffect(() => {
    reset({
      lawsuitNumber,
      note,
      monitoring,
      responsibleUserId: responsibleUserId?.toLowerCase(),
    });
  }, [visible, reset, lawsuitNumber, note, monitoring, responsibleUserId]);

  const onSubmit: SubmitHandler<DrawerForm> = async (form: DrawerForm) => {
    try {
      await updateMutation(form);
      message.success('Processo atualizado com sucesso!');
      onClose();
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  return (
    <Drawer
      title="Editar processo"
      placement="right"
      width={340}
      closable
      onClose={onClose}
      visible={visible}
      closeIcon={<CloseOutlined />}
    >
      <Form name="lawsuit" onFinish={handleSubmit(onSubmit)}>
        <TextMaskField
          disabled
          name="lawsuitNumber"
          mask="9999999-99.9999.9.99.9999"
          label="Número do Processo"
          control={control}
          errors={errors}
        />
        <ResponsibleSelectField
          name="responsibleUserId"
          label="Responsável"
          control={control}
          errors={errors}
        />
        <TextField
          name="note"
          label="Observação (opcional)"
          control={control}
          errors={errors}
        />
        <SwitchField
          name="monitoring"
          control={control}
          label="Monitorar processo?"
          errors={errors}
        />
        <Group className="is-right">
          <Button className="is-secondary is-small" onClick={onClose}>
            Cancelar
          </Button>
          <Button htmlType="submit" className="is-primary is-small">
            Salvar e fechar
          </Button>
        </Group>
      </Form>
    </Drawer>
  );
};

export default DrawerEdit;
