import React, { useState } from 'react';
import Sidebar from 'screens/Settings/Sidebar';
import GeralMenu from 'screens/Settings/geral/GeralMenu';

const enabledItems = ['geral'];

const Settings = (): React.ReactElement => {
  const [current, setCurrent] = useState('geral');

  // Antd doesn't have a type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any): void => {
    if (enabledItems.includes(e.key)) {
      setCurrent(e.key);
    }
  };

  const centerMenu: Record<string, React.ReactElement> = {
    geral: <GeralMenu />,
  };

  return (
    <div className="d-flex justify-content-start min-h-100">
      <Sidebar current={current} handleClick={handleClick} />
      {centerMenu[current]}
    </div>
  );
};

export default Settings;
