import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Select,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const tag = (
  <Paragraph code={true}>
    <Vspace />
    {`<Select`}
    <br />
      {`mode="multiple"`}
      <br />
      {`allowClear`}
      <br />
      {`placeholder="Please select"`}
      <br />
      {`defaultValue={['tag01', 'tag02']}>`}
      <br />
      {`<Option key={'tag01'}>tag 01</Option>`}
      <br />
      {`<Option key={'tag02'}>tag 02</Option>`}
      <br />
      {`<Option key={'tag03'}>tag 03</Option>`}
      <br />
      {`<Option key={'tag04'}>tag 04</Option>`}
      <br />
      {`<Option key={'tag05'}>tag 05</Option>`}
      <br />
    {`</Select>`}
    <Vspace />
  </Paragraph>
);

const WacTagSelect = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Tag Select
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select"
            defaultValue={['tag01', 'tag02']}>
            <Option key={'tag01'}>tag 01</Option>
            <Option key={'tag02'}>tag 02</Option>
            <Option key={'tag03'}>tag 03</Option>
            <Option key={'tag04'}>tag 04</Option>
            <Option key={'tag05'}>tag 05</Option>
          </Select>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {tag}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacTagSelect;
