import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  DatePicker,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const datepicker = (
  <Paragraph code={true}>
    <Vspace />
      {`<DatePicker onChange={onChangeDate} />`}
    <Vspace />
  </Paragraph>
);

const WacDatePicker = () => {
  
  function onChangeDate(date, dateString) {
    console.log(date, dateString);
  }

  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          DatePicker
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <DatePicker onChange={onChangeDate} />
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {datepicker}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDatePicker;
