import React from 'react';

interface Props {
  className?: string;
}

const Box: React.FC<Props> = ({ className, children }) => (
  <div className={`wac-box ${className}`}>{children}</div>
);

export default Box;
