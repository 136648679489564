import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Select,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const select = (
  <Paragraph code={true}>
    <Vspace />
    {`<Select defaultValue="Selecionar">`}
    <br />
      {`<Option value="Tribunal 1">Tribunal 1</Option>`}
      <br />
      {`<Option value="Tribunal 2">Tribunal 2</Option>`}
      <br />
      {`<Option value="Tribunal 3">Tribunal 3</Option>`}
      <br />
    {`</Select>`}
    <Vspace />
    {`<Select `}
    <br />
      {`allowClear`}
      <br />
      {`placeholder="Selecionar">`}
      <br />
      {`<Option value="Tribunal 1">Tribunal 1</Option>`}
      <br />
      {`<Option value="Tribunal 2">Tribunal 2</Option>`}
      <br />
      {`<Option value="Tribunal 3">Tribunal 3</Option>`}
      <br />
    {`</Select>`}
    <Vspace />
  </Paragraph>
);

const WacSelect = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Select
      </Divider>
      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Select defaultValue="Selecionar">
              <Option value="Tribunal 1">Tribunal 1</Option>
              <Option value="Tribunal 2">Tribunal 2</Option>
              <Option value="Tribunal 3">Tribunal 3</Option>
            </Select>
          </Box>
          <Box className='code-ex'>
            <Select 
              allowClear
              placeholder="Selecionar">
              <Option value="Tribunal 1">Tribunal 1</Option>
              <Option value="Tribunal 2">Tribunal 2</Option>
              <Option value="Tribunal 3">Tribunal 3</Option>
            </Select>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {select}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacSelect;
