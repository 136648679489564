import { AxiosResponse } from 'axios';
import { LoginForm } from 'context/Auth';
import http from 'api/endpoints/http';
import { IRecaptchaInfoDTO, ITwoFactorDTO } from 'types';

const authService = {
  doRecaptchaAuth: (
    loginForm: LoginForm,
  ): Promise<AxiosResponse<IRecaptchaInfoDTO>> => {
    return http.post('recaptcha-auth', loginForm);
  },

  doTwoFactorAuth: (
    authCode: string,
  ): Promise<AxiosResponse<ITwoFactorDTO>> => {
    return http.post('two-factor-auth', { code: authCode });
  },
};

export default authService;
