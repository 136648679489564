import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { PDF_DOCUMENT } from 'utils/consts';
import lawsuitService from 'api/endpoints/lawsuit';

interface Props {
  lawsuitNumber: string;
}

export default function useLawsuitGetClippingDoc({
  lawsuitNumber,
}: Props): UseQueryResult<AxiosResponse<number>, string> {
  const query = useQuery<AxiosResponse<number>, string, AxiosResponse<number>>(
    [PDF_DOCUMENT, lawsuitNumber],
    async () => lawsuitService.getClippingDoc({ lawsuitNumber }),
    { keepPreviousData: true },
  );

  return query;
}
