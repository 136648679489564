import React from 'react';
import { DatePicker } from 'antd';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import Label from 'components/commons/Label';
import { Moment } from 'moment';

interface Props {
  value: Moment | undefined;
  label: string;
  placeholder?: string;
  onChange: (value: Moment | null) => void;
  showTime?: boolean;
}

const FilterOneDatePicker = ({
  value,
  label,
  placeholder = '',
  onChange,
  showTime = false,
}: Props): React.ReactElement => (
  <div>
    {label && <Label>{label}</Label>}
    <DatePicker
      allowClear
      value={value}
      locale={locale}
      style={{ width: '100%' }}
      showTime={showTime ? { format: 'HH:mm:ss' } : false}
      placeholder={placeholder}
      onChange={onChange}
    />
  </div>
);

export default FilterOneDatePicker;
