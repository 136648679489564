import React, { useState } from 'react';
import { Typography } from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import Group from 'components/custom/Group';
import { IProcessListFiltersDTO } from 'types';
import Filter from 'screens/ProceduralProgress/components/Filter';
import ProcessList from 'screens/ProceduralProgress/components/ProcessList';
import DrawerAdd from 'screens/ProceduralProgress/components/DrawerAdd';

const { Title } = Typography;

const ProceduralProgress = (): React.ReactElement => {
  const [filters, setFilters] = useState<IProcessListFiltersDTO>({});
  const onFilter = (newFilters: IProcessListFiltersDTO): void =>
    setFilters(newFilters);

  const processListKey = Object.keys(filters).join(',');
  return (
    <>
      <Box className="is-full">
        <Group className="is-spread">
          <Title className="is-size-6">Processos monitorados</Title>
          <DrawerAdd />
        </Group>

        <Vspace />

        <Filter onFilter={onFilter} />

        <Vspace />

        <ProcessList key={processListKey} filters={filters} />
      </Box>
    </>
  );
};

export default ProceduralProgress;
