import React from 'react';

interface Props {
  className?: string;
}

const Vspace: React.FC<Props> = ({ className }) => (
  <div className={`wac-vspace ${className}`} />
);

export default Vspace;
