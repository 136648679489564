import React from 'react';
import { Table } from 'antd';
import { buildColumns, buildData } from 'utils/table';

interface Props {
  labels?: string[];
  data?: string[][];
}

const ContentPetitions = ({
  labels = [],
  data = [],
}: Props): React.ReactElement => {
  const columns = buildColumns(labels);
  if (columns.length < 1) {
    return <div>Não há dados</div>;
  }
  return (
    <div>
      <Table
        className="boxed"
        columns={columns}
        dataSource={buildData(columns, data)}
        showSorterTooltip={false}
      />
    </div>
  );
};

export default ContentPetitions;
