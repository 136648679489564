import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacFilterText = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
      FilterText
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de pesquisa do filtro avançado, compostor por:
            </div> 
            <div className='code-component'>
              {'<Label>'} {'<Input>'}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<div className="wac-control">`}
              <br />
                {`<Label>{label}</Label>`}
                <br />
                {`<Input`}
                <br />
                  {`value={value}`}
                  <br />
                  {`onChange={(e) => setValue(e.target.value)}`}
                  <br />
                  {`onBlur={(e) => setState({ ...state, [field]: e.target.value })}`}
                  <br />
                {`/>`}
                <br />
              {`</div>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacFilterText;