import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacCardListDropdown = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        CardListDropdown
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
            Componente com o dropdown de opções do CardList, composto por:
            </div> 
            <div className='code-component'>
            {`<Menu>`} {`<Menu.Item>`} {`<Dropdown>`}
            </div>
          </div>
        </Col> 
        <Col span={12}> 
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`{const CardDropdownData = (`}
              <br />
                {`<Menu>`}
                <br />
                  {`{itemCardDropdownData.map(({ key, icon, label }) => (`}
                  <br />
                    {`<Menu.Item key={ key }>`}
                    <br />
                      {`{icon}`}
                      <br />
                      {`{label}`}
                      <br />
                    {`</Menu.Item>`}
                    <br />
                  {`))}`}
                  <br />
                {`</Menu>`}
                <br />
              {`);`}
              <br />
              <br />
              {`const CardListDropdown = () => {`}
              <br />
                {`return (`}
                <br />
                  {`<Dropdown key='more' overlay={CardDropdownData} trigger={['click']}>`}
                  <br />
                    {`<ThreeDotsIcon />`}
                    <br />
                  {`</Dropdown>`}
                  <br />
                {`);`}
                <br />
              {`};`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCardListDropdown;