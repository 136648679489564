import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import lawsuitProgressService from 'api/endpoints/lawsuitProgress';
import { GET_ALL_LAWSUIT_PROGRESS } from 'utils/consts';
import { IUserHistory } from 'types';

interface IUpdateParams {
  lawsuitNumber: string;
  userHistories: IUserHistory[];
}

export default function useLawsuitProgressSetStarred(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IUpdateParams
> {
  const client = useQueryClient();
  const mutation = useMutation(
    (params: IUpdateParams): Promise<AxiosResponse<void>> => {
      return lawsuitProgressService.setStarred(
        params.lawsuitNumber,
        !params.userHistories[0]?.starred,
      );
    },
    {
      onSuccess: () => client.invalidateQueries(GET_ALL_LAWSUIT_PROGRESS),
    },
  );

  return mutation;
}
