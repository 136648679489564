const AUTHENTICATED = '@Outprocess:authenticated';
const TOKEN = '@Outprocess:token';

export const setIsAuthenticatedSession = (authenticated: boolean): void => {
  sessionStorage.setItem(AUTHENTICATED, String(authenticated));
};

export const setTokenSession = (token: string): void => {
  sessionStorage.setItem(TOKEN, token);
};

export const getTokenSession = (): string => {
  return sessionStorage.getItem(TOKEN) || '';
};

export const getIsAuthenticatedSession = (): boolean => {
  return sessionStorage.getItem(AUTHENTICATED) === 'true';
};
