import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { IRoleDTO } from 'types';
import { GET_ALL_ROLES } from 'utils/consts';
import roleService from 'api/endpoints/role';

export default function useRoleGetAll(): UseQueryResult<
  AxiosResponse<Array<IRoleDTO>>
> {
  const query = useQuery<AxiosResponse<Array<IRoleDTO>>>(
    GET_ALL_ROLES,
    roleService.getAll,
  );

  return query;
}
