import React from 'react';

const GeralSvg = (): React.ReactElement => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.125 4.5H2.875M20.125 12H2.875M20.125 19.5H2.875M17.25 2.25V6.75M5.75 9.75V14.25M14.375 17.25V21.75"
      stroke="#4F4F4F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GeralSvg;
