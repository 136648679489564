import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import {
  Layout,
  Menu,
  Collapse,
  Tag,
  Typography,
  Skeleton,
  Button,
} from 'antd';
import { AxiosResponse } from 'axios';
import Group from 'components/custom/Group';
import GroupChild from 'components/custom/GroupChild';
import Vspace from 'components/custom/Vspace';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch, Link } from 'react-router-dom';
import { ILawsuitSummaryDTO } from 'types';
import { GET_ALL_LAWSUIT } from 'utils/consts';
import ModalFolder from 'screens/ClippingsAndSummons/components/ModalFolder';
import useLawsuitGetSummary from 'api/queries/useLawsuitGetSummary';

const { Text } = Typography;
const { Panel } = Collapse;
const { Sider } = Layout;
const { SubMenu } = Menu;

const HeaderSider: React.FC<{
  isLoading: boolean;
  numberClippings: number;
}> = ({ isLoading, numberClippings }) => (
  <Group className="is-spread">
    Recortes
    <Tag className="is-primary is-rounded">
      {isLoading ? <LoadingOutlined /> : <>{`${numberClippings}`}</>}
    </Tag>
  </Group>
);

const TopNavInfo: React.FC<{
  query?: AxiosResponse<ILawsuitSummaryDTO>;
  loading: boolean;
}> = (props) => {
  const { loading, query } = props;
  let read = 0;
  let unread = 0;
  if (query) {
    read = query?.data.read;
    unread = query?.data.unread;
  }

  return (
    <>
      <Group className="is-spread">
        <Text>
          <Link to="/platform/recortes-e-intimacoes/andamento-processual/nao-lidos">
            Não Lidos
          </Link>
        </Text>
        {loading ? <LoadingOutlined /> : <Text>{unread}</Text>}
      </Group>
      <Vspace />
      <Group className="is-spread">
        <Text>
          <Link to="/platform/recortes-e-intimacoes/andamento-processual/lidos">
            Lidos
          </Link>
        </Text>
        {loading ? <LoadingOutlined /> : <Text>{read}</Text>}
      </Group>
      <Vspace />
    </>
  );
};

const Sidebar: React.FC = () => {
  const { path } = useRouteMatch();
  const dataClient = useQueryClient();
  const {
    isLoading: isSummaryLoading,
    data: queryData,
  } = useLawsuitGetSummary();

  const folders = queryData?.data.userFolderSummaries;

  if (isSummaryLoading) {
    return (
      <Sider width="220" className="wac-content-sider">
        <Skeleton paragraph />
      </Sider>
    );
  }

  const totalClipping: number =
    (queryData?.data?.read ?? 0) + (queryData?.data?.unread ?? 0);

  const handleRefreshButton = (): Promise<void> =>
    dataClient.invalidateQueries(GET_ALL_LAWSUIT);

  return (
    <Sider width="220" className="wac-content-sider">
      <div className="wac-content-sider-header" />
      <Button type="link" onClick={handleRefreshButton}>
        <RedoOutlined />
        Atualizar
      </Button>
      <Collapse className="is-sider-nav" defaultActiveKey={['1']}>
        <Panel
          header={
            <HeaderSider
              isLoading={isSummaryLoading}
              numberClippings={totalClipping}
            />
          }
          key="1"
        >
          <TopNavInfo query={queryData} loading={isSummaryLoading} />
          <Menu
            className="is-sider-nav"
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
          >
            {/*
            <SubMenu key="sub2" title="Totais">
              <Menu.Item key="3">
                Recortes
                <Link to="/recortes-e-intimacoes/recortes" />
              </Menu.Item>
              <Menu.Item key="4">
                Processos
                <Link to="/recortes-e-intimacoes/processos" />
              </Menu.Item>
            </SubMenu>
						*/}

            <SubMenu
              key="sub3"
              title={
                <Group className="is-spread">
                  <GroupChild>
                    <Text className="is-size-15 is-600">Pastas</Text>
                  </GroupChild>
                  <GroupChild>
                    <ModalFolder />
                  </GroupChild>
                </Group>
              }
            >
              {folders?.map((folder) => (
                <Menu.Item key={folder.userFolder.id}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {folder.userFolder.name}
                    <Link
                      to={`${path}/andamento-processual/${folder.userFolder.id}`}
                    />
                    <Tag className="is-rounded">{folder.new}</Tag>
                  </div>
                </Menu.Item>
              ))}
            </SubMenu>
          </Menu>
        </Panel>
      </Collapse>
    </Sider>
  );
};

export default Sidebar;
