import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { IUserSettingsPageDTO } from 'types';
import { GET_USERS } from 'utils/consts';
import userService from 'api/endpoints/user';

interface Props {
  currentPage: number;
}

export default function useUserGetAll({
  currentPage,
}: Props): UseQueryResult<AxiosResponse<IUserSettingsPageDTO>> {
  const query = useQuery<AxiosResponse<IUserSettingsPageDTO>>(
    [GET_USERS, { pageNumber: currentPage }],
    ({ queryKey }): Promise<AxiosResponse<IUserSettingsPageDTO>> => {
      const [_ignore, { pageNumber }] = queryKey;
      return userService.getAllUsers({ pageNumber: pageNumber - 1 });
    },
  );

  return query;
}
