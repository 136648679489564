import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import { PAGE_SIZE_MAX } from 'utils/consts';
import { IRoleDTO } from 'types';

const roleService = {
  getAll: (): Promise<AxiosResponse<Array<IRoleDTO>>> => {
    return http.get('/role', { params: { pageSize: PAGE_SIZE_MAX } });
  },
};

export default roleService;
