import React from 'react';

const DeleteSvg = (): React.ReactElement => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.59985H17.1379"
      stroke="#BDBDBD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.48261 4.6V2.8C5.48261 2.32261 5.67153 1.86477 6.0078 1.52721C6.34407 1.18964 6.80015 1 7.27571 1H10.8619C11.3375 1 11.7935 1.18964 12.1298 1.52721C12.4661 1.86477 12.655 2.32261 12.655 2.8V4.6M15.3446 4.6V17.2C15.3446 17.6774 15.1557 18.1352 14.8195 18.4728C14.4832 18.8104 14.0271 19 13.5515 19H4.58607C4.11051 19 3.65443 18.8104 3.31815 18.4728C2.98188 18.1352 2.79297 17.6774 2.79297 17.2V4.6H15.3446Z"
      stroke="#BDBDBD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.27539 9.09985V14.4999"
      stroke="#BDBDBD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8623 9.09985V14.4999"
      stroke="#BDBDBD"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteSvg;
