import React from 'react';
import { Row, Col } from 'antd';
import Box from 'components/custom/Box';
import WacButton from './Components/WacButton';
import WacTypography from './Components/WacTypography';
import WacIcon from './Components/WacIcon';
import WacDropdown from './Components/WacDropdown'
import Title from 'antd/lib/typography/Title';
import WacAppMenu from './CustomComponents/WacAppMenu';
import WacAppSidebar from './CustomComponents/WacAppSidebar';
import WacOverlaidContent from './CustomComponents/WacOverlaidContent';
import WacMainNav from './CustomComponents/WacMainNav';
import WacAppSidebarSubMenu from './CustomComponents/WacAppSidebarSubMenu';
import WacDropdownUser from './CustomComponents/WacDropdownUser';
import WacCardList from './CustomComponents/WacCardList';
import WacCardContent from './CustomComponents/WacCardContent';
import WacCardContentFooter from './CustomComponents/WacCardContentFooter';
import WacCardContentHead from './CustomComponents/WacCardContentHead';
import WacCardListDropdown from './CustomComponents/WacCardListDropdown';
import WacProcessList from './CustomComponents/WacProcessList';
import WacDrawerAdd from './Modais/WacDrawerAdd';
import WacAdvancedFilter from './CustomComponents/WacAdvancedFilter';
import WacFilterMultiSelect from './CustomComponents/WacFilterMultiSelect';
import WacFilterOneDatePicker from './CustomComponents/WacFilterOneDatePicker';
import WacFilterText from './CustomComponents/WacFilterText';
import WacProcessPanel from './CustomComponents/WacProcessPanel';
import WacCard from './Components/WacCard';
import WacLabel from './Components/WacLabel';
import WacInput from './Components/WacInput';
import WacTag from './Components/WacTag';
import WacBox from './Components/WacBox';
import WacSelect from './Components/WacSelect';
import WacDrawerDetails from './Modais/WacDrawerDetails';
import WacTagSelect from './Components/WacTagSelect';
import WacDatePicker from './Components/WacDatePicker';
import WacAvatar from './Components/WacAvatar';
import WacDrawerHeader from './Modais/WacDrawerHeader';
import WacModalDelete from './Modais/WacModalDelete';
import WacPageHeader from './CustomComponents/WacPageHeader';
import WacTooltip from './Components/WacTooltip';
import WacCheckbox from './Components/WacCheckbox';
import WacGroup from './Components/WacGroup';
import WacSwitch from './Components/WacSwitch';
import WacCounter from './Components/WacCounter';
import WacPagination from './Components/WacPagination/Index';


const PaginaDeComponentes = () => {
  return (
    <Row>
      <Col span={24}>
        <Box className='is-full'>
          <Title>
            Componentes
          </Title>
          <WacButton />
          <WacInput />
          <WacDropdown />
          <WacDatePicker />
          <WacSelect />
          <WacTagSelect />
          <WacTag />
          <WacLabel />
          <WacCard />
          <WacCheckbox />
          <WacSwitch />
          <WacCounter />
          <WacPagination />
          <WacAvatar />
          <WacTooltip />
          <WacBox />
          <WacGroup />
          <WacIcon />
          <WacTypography />

          <Title>
            Componentes Personalizados
          </Title>
          <WacPageHeader />
          <WacMainNav />
          <WacDropdownUser />
          <WacOverlaidContent />
          <WacAppMenu />
          <WacAppSidebar />
          <WacAppSidebarSubMenu />
          <WacDrawerAdd />
          <WacDrawerDetails />
          <WacDrawerHeader />
          <WacModalDelete />
          <WacCardList />
          <WacCardContent />
          <WacCardContentFooter />
          <WacCardContentHead />
          <WacCardListDropdown />
          <WacProcessList />
          <WacAdvancedFilter />
          <WacFilterMultiSelect />
          <WacFilterOneDatePicker />
          <WacFilterText />
          <WacProcessPanel />
        </Box>
      </Col>
    </Row>
  );
}

export default PaginaDeComponentes;