import React from 'react';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, message, Typography } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import Container from 'screens/Login/components/Container';
import * as yup from 'yup';
import useForgotPassword from 'api/mutations/useForgotPassword';

const { Title } = Typography;

interface PasswordRecoveryForm {
  email: string;
}

const passRequestSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const PasswordRecovery: React.FC = () => {
  const {
    mutateAsync: forgotPasswordMutation,
    isLoading,
  } = useForgotPassword();
  const {
    setError,
    control,
    handleSubmit,
    errors,
  } = useForm<PasswordRecoveryForm>({
    resolver: yupResolver(passRequestSchema),
  });

  const onSubmit = async (form: PasswordRecoveryForm): Promise<void> => {
    try {
      await forgotPasswordMutation(form);
      message.success(
        'Foi enviado um e-mail com as informações para recuperação da senha!',
      );
    } catch (error) {
      setError('email', {
        message:
          'Houve algum erro. Por favor, verifique se o e-mail utilizado está correto e cadastrado no sistema',
      });
    }
  };

  return (
    <Container>
      <Title level={5}>
        Será enviado um código de confirmação para o e-mail solicitado.
      </Title>
      <Form onFinish={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={(props) => (
            <Form.Item
              validateStatus={errors.email ? 'error' : ''}
              help={errors.email?.message}
            >
              <Input
                {...props}
                placeholder="E-mail cadastrado"
                prefix={<UserOutlined />}
              />
            </Form.Item>
          )}
        />
        <Button className="is-primary" htmlType="submit">
          {isLoading ? <LoadingOutlined /> : 'Recuperar senha!'}
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordRecovery;
