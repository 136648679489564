import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Switch,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

function onChangeSwitch(checked) {
  console.log(`switch to ${checked}`);
}

const wacswitch = (
  <Paragraph code={true}>
    <Vspace />
      {`<Switch defaultChecked onChange={onChangeSwitch} />`}
    <Vspace />
  </Paragraph>
);

const WacSwitch = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Switch
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Switch defaultChecked onChange={onChangeSwitch} />
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {wacswitch}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacSwitch;
