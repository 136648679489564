import { DownOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button } from 'antd';
import AdvancedFilter from 'components/commons/AdvancedFilter/AdvancedFilter';
import BookSelect from 'components/commons/Filter/BookSelect';
import CourtSelect from 'components/commons/Filter/CourtSelect';
import FilterText from 'components/commons/Filter/FilterText';
import ForumAutocomplete from 'components/commons/Filter/ForumAutocomplete';
import Group from 'components/custom/Group';
import Vspace from 'components/custom/Vspace';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useProcess } from 'context/Process';
import { SelectValue } from 'antd/lib/select';

const { Search } = Input;

interface ProceduralProgressFilterState {
  NumberFormatted?: string;
  bookId?: string;
  forumId?: string;
  courtId?: string;
  isRead?: boolean;
  userFolderId?: string;
}
interface ProceduralProgressFilterProps {
  onFilter: (filters: ProceduralProgressFilterState) => void;
  onResetFilter: () => void;
}

const Filter: React.FC<ProceduralProgressFilterProps> = (props) => {
  const { onFilter, onResetFilter } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [filters, setFilters] = useState<ProceduralProgressFilterState>({});
  useProcess<ProceduralProgressFilterState>({
    callback: (procId: string) =>
      setFilters((prev) => ({ ...prev, NumberFormatted: procId })),
    filters,
  });

  const onArrowClick = (): void => setIsActive((prev) => !prev);
  const onFilterClick = (): void => {
    onFilter(filters);
  };

  const onChangeLawsuitNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    setFilters((prev) => ({ ...prev, NumberFormatted: value }));
  };

  const onChangeCourt = (courtId: SelectValue): void =>
    setFilters((prev) => ({ ...prev, courtId: courtId?.toString() }));

  const onChangeBook = (bookId: SelectValue): void =>
    setFilters((prev) => ({ ...prev, bookId: bookId?.toString() }));

  const onChangeForum = (forumId: string): void =>
    setFilters((prev) => ({ ...prev, forumId }));

  const handleResetFilter = (): void => {
    setFilters({});
    onResetFilter();
  };

  return (
    <>
      <Group>
        <InputMask
          mask="9999999-99.9999.9.99.9999"
          placeholder="Pesquisar"
          onChange={onChangeLawsuitNumber}
          value={filters.NumberFormatted}
          className="search-ftd"
        >
          <Search onSearch={onFilterClick} />
        </InputMask>
        <Button type="text" onClick={onArrowClick}>
          Filtrar <DownOutlined />
        </Button>
      </Group>
      <AdvancedFilter isActive={isActive}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <InputMask
              mask="9999999-99.9999.9.99.9999"
              placeholder="Número do processo"
              value={filters.NumberFormatted}
              onChange={onChangeLawsuitNumber}
              className="search-ftd"
            >
              <FilterText label="Número do processo" />
            </InputMask>
          </Col>
          <Col span={24}>
            <CourtSelect
              value={filters.courtId}
              onChange={onChangeCourt}
              implemented={false}
            />
          </Col>
          <Col span={12}>
            <ForumAutocomplete
              courtId={filters.courtId}
              onChange={onChangeForum}
            />
          </Col>

          <Col span={12}>
            <BookSelect value={filters.bookId} onChange={onChangeBook} />
          </Col>
          <Col span={6}>
            <Button onClick={onFilterClick} type="primary">
              Filtrar
            </Button>
          </Col>
          {Object.keys(filters).length > 0 && (
            <Col span={3}>
              <Button type="default" onClick={handleResetFilter}>
                Limpar
              </Button>
            </Col>
          )}
        </Row>
        <Vspace />
      </AdvancedFilter>
    </>
  );
};

export default Filter;
