import React, { useState, useRef, RefObject } from 'react';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, Space, message } from 'antd';
import {
  Controller,
  SubmitHandler,
  useForm,
  ControllerRenderProps,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Container from 'screens/Login/components/Container';
import useAuthenticateTwoFactor from 'api/mutations/useAuthenticateTwoFactor';

const { Link } = Typography;

interface TwoFactorAuthForm {
  input0: string;
  input1: string;
  input2: string;
  input3: string;
}

const TwoFactorSchema = yup.object().shape({
  input0: yup
    .number()
    .typeError('Código de autenticação é numérico')
    .required('Entre com o codigo de autenticação'),
  input1: yup
    .number()
    .typeError('Código de autenticação é numérico')
    .required('Entre com o codigo de autenticação'),
  input2: yup
    .number()
    .typeError('Código de autenticação é numérico')
    .required('Entre com o codigo de autenticação'),
  input3: yup
    .number()
    .typeError('Código de autenticação é numérico')
    .required('Entre com o codigo de autenticação'),
});

const TwoFactorAuth: React.FC = () => {
  const {
    handleSubmit,
    setError,
    control,
    errors,
  } = useForm<TwoFactorAuthForm>({
    resolver: yupResolver(TwoFactorSchema),
  });
  const {
    mutateAsync: authenticateTwoFactorMutation,
    isLoading,
  } = useAuthenticateTwoFactor();

  const onSubmit: SubmitHandler<TwoFactorAuthForm> = async (
    form: TwoFactorAuthForm,
  ) => {
    try {
      await authenticateTwoFactorMutation(form);
      message.success('Autenticado com sucesso!', 4);
    } catch (error) {
      setError('input0', {
        message: 'Código de autenticação incorreto! ',
      });
    }
  };

  const input0Ref = useRef<Input>(null);
  const input1Ref = useRef<Input>(null);
  const input2Ref = useRef<Input>(null);
  const input3Ref = useRef<Input>(null);

  const refs = [input0Ref, input1Ref, input2Ref, input3Ref];

  const [inputState, setInputState] = useState<{
    currentRefIndex: number;
    currentRef: RefObject<Input>;
  }>({
    currentRefIndex: 0,
    currentRef: input0Ref,
  });
  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement>,
    inputIndex: number,
  ): void => {
    const currentRef = refs[inputState.currentRefIndex]?.current;
    const { currentRefIndex } = inputState;
    const inputRef = currentRef?.input;

    if (
      inputRef?.maxLength === inputRef?.value.length &&
      currentRefIndex < refs.length - 1
    ) {
      const ref = refs[inputState.currentRefIndex + 1];
      const _ignore = ref?.current?.input.focus();
      setInputState({ currentRefIndex: inputIndex + 1, currentRef: ref });
    }
  };

  const handleBackspaceInput = (
    e: React.KeyboardEvent<HTMLInputElement>,
    inputIndex: number,
  ): void => {
    if (e.key === 'Backspace') {
      if (
        inputState.currentRef.current?.input.value.length === 0 &&
        inputIndex > 0
      ) {
        setInputState({
          currentRefIndex: inputIndex - 1,
          currentRef: refs[inputIndex - 1],
        });
        const _ignore = refs[inputIndex - 1]?.current?.input.focus();
      }
    }
  };

  const handleInputRender = (
    props: ControllerRenderProps,
    inputIndex: number,
  ): React.ReactElement => {
    const p = {
      ref: refs[inputIndex],
    };
    return (
      <Input
        {...props}
        {...p}
        style={{ width: '2rem' }}
        placeholder="X"
        maxLength={1}
        onFocus={() => {
          setInputState({
            currentRef: refs[inputIndex],
            currentRefIndex: inputIndex,
          });
        }}
        onInput={(e: React.FormEvent<HTMLInputElement>) => {
          handleInputChange(e, inputIndex);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          handleBackspaceInput(e, inputIndex);
        }}
      />
    );
  };

  return (
    <Container>
      <h2>Insira o código que foi enviado para seu e-mail</h2>
      <Form onFinish={handleSubmit(onSubmit)}>
        <Input.Group compact className="input-group-2fa">
          <Form.Item
            validateStatus={
              errors.input0 || errors.input1 || errors.input2 || errors.input3
                ? 'error'
                : ''
            }
            help={errors.input0?.message}
          >
            <Controller
              name="input0"
              control={control}
              render={(props) => handleInputRender(props, 0)}
            />

            <Controller
              name="input1"
              control={control}
              render={(props) => handleInputRender(props, 1)}
            />

            <Controller
              name="input2"
              control={control}
              render={(props) => handleInputRender(props, 2)}
            />

            <Controller
              name="input3"
              control={control}
              render={(props) => handleInputRender(props, 3)}
            />
            <Button className="is-primary" htmlType="submit">
              <CheckOutlined />
              Autenticar
            </Button>
            {isLoading && <LoadingOutlined />}
          </Form.Item>
        </Input.Group>

        <Space direction="vertical">
          <Link href="/404">Não recebi o link, clique aqui para reenviar!</Link>
        </Space>
      </Form>
    </Container>
  );
};

export default TwoFactorAuth;
