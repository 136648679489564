import React, { useEffect, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu, Button, Tooltip } from 'antd';
import appMenuSidebarData from 'screens/Main/components/appMenuSidebarData';
import { NavigationContext } from 'screens/Main/components/NavigationState';

const MenuApp = (): React.ReactElement => {
  const { path } = useRouteMatch();
  const { current, setCurrent } = useContext(NavigationContext);

  useEffect(() => {
    if (!current) setCurrent(appMenuSidebarData[0].key);
  }, [current, setCurrent]);

  // Antd doesn't have a type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any): void => setCurrent(event.key);

  return (
    <div className="menu-container">
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        className="is-sub-nav w-100"
      >
        {appMenuSidebarData.map(({ key, tooltip, label, link, disabled }) => (
          <Menu.Item key={key} className={disabled ? 'is-disabled' : ''}>
            <Tooltip mouseEnterDelay={0.7} title={tooltip} placement="bottom">
              <div className="wac-menu-item">
                <span>{label}</span>
                <Link to={`${path}${link}`} />
              </div>
            </Tooltip>
          </Menu.Item>
        ))}
      </Menu>
      <Button className="is-secondary is-rounded ftr">
        <span>Títulos Grifon</span>
      </Button>
    </div>
  );
};

export default MenuApp;
