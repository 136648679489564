/* eslint-disable jsx-a11y/anchor-is-valid */
import { FlagFilled } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Popover,
  Menu,
  Collapse,
  Popconfirm,
  message,
  List,
} from 'antd';
import { useAuth } from 'context/Auth/index';
import React from 'react';
import { handleColorForUser } from 'screens/ProceduralProgress/components/utils';
import { ICompanyDTO } from 'types';
import useUserSwitchCompany from 'api/mutations/useUserSwitchCompany';
import useUserGetAllCompanies from 'api/queries/useUserGetAllCompanies';

const { Panel } = Collapse;

const MenuUser = (
  companies: ICompanyDTO[],
  mutate: (companyId: string) => void,
): React.ReactElement => {
  return (
    <div className="user-dropdown is-menu-container">
      <Collapse accordion bordered={false} ghost className="user-dropdown">
        <Panel header="Trocar de empresa" showArrow={false} key="1">
          <List
            dataSource={companies}
            renderItem={(company) => (
              <Popconfirm
                title="Deseja trocar de Empresa?"
                onConfirm={() => mutate(company.id)}
                placement="leftTop"
                icon={<></>}
              >
                {company.isDefault ? (
                  <List.Item>
                    <FlagFilled /> {company.firstName}
                  </List.Item>
                ) : (
                  <List.Item>
                    <a className="is-company-option">{company.firstName}</a>
                  </List.Item>
                )}
              </Popconfirm>
            )}
          />
        </Panel>
      </Collapse>
      <Menu>
        <Menu.Item>
          <a rel="#" href="/logout">
            Sair do Outprocess
          </a>
        </Menu.Item>
      </Menu>
    </div>
  );
};

const DropdownUser: React.FC = () => {
  const { user, updateUserToken } = useAuth();
  const { mutateAsync: userSwitchCompanyMutation } = useUserSwitchCompany();
  const { data: resultQuery } = useUserGetAllCompanies();

  return (
    <Popover
      trigger="click"
      content={() => {
        return MenuUser(
          resultQuery?.data ?? [],
          async (companyId: string): Promise<void> => {
            try {
              const response = await userSwitchCompanyMutation(companyId);
              updateUserToken(response.data.token);
              message.success('Troca de compania realizada com sucesso!');
              window.location.reload();
            } catch (errorDescription) {
              message.error(errorDescription);
            }
          },
        );
      }}
      placement="bottomRight"
    >
      <Button className="is-bare has-status is-online">
        <Avatar style={{ backgroundColor: handleColorForUser(user) }}>
          {user?.UserInitials}
        </Avatar>
      </Button>
    </Popover>
  );
};

export default DropdownUser;
