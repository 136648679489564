import {
  InfiniteData,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { GET_ALL_LAWSUIT, GET_ALL_LAWSUITS_SUMMARY } from 'utils/consts';
import { ILawsuitCardDTO, ILawsuitFiltersDTO, ILawsuitPageDTO } from 'types';
import lawsuitService from 'api/endpoints/lawsuit';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { CardListLocationState } from 'screens/ClippingsAndSummons/components/CardList';

interface IHookInterface {
  filters: ILawsuitFiltersDTO;
}

type Props = Partial<RouteComponentProps<{ status: string }>> & IHookInterface;

export default function useLawsuitSetReadSingle({
  match,
  filters,
}: Props): UseMutationResult<AxiosResponse<ILawsuitCardDTO>, unknown, string> {
  const client = useQueryClient();
  const location = useLocation<CardListLocationState>();
  const mutation = useMutation<
    AxiosResponse<ILawsuitCardDTO>,
    AxiosError,
    string
  >((lawsuitId: string) => lawsuitService.setReadSingle(lawsuitId), {
    onSuccess: ({ data }, lawsuitId) => {
      const cache = client.getQueryData<InfiniteData<ILawsuitPageDTO>>([
        GET_ALL_LAWSUIT,
        filters,
        match?.params.status,
      ]);

      const { pageNumber } = location.state;
      const updateCachePageReaded = cache?.pages[pageNumber].lawsuits.map(
        (lawsuit) => {
          if (lawsuit.id === lawsuitId) {
            return { lawsuit, ...data };
          }
          return lawsuit;
        },
      );
      if (cache) {
        cache.pages[pageNumber].lawsuits = updateCachePageReaded ?? [];
      }
      client.setQueryData([GET_ALL_LAWSUIT, {}], cache);

      return client.invalidateQueries(GET_ALL_LAWSUITS_SUMMARY);
    },
  });

  return mutation;
}
