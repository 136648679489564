import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacDropdownUser = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        DropdownUser
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente do avatar do usuário com dropdown no header da aplicação, composto por:
            </div> 
            <div className='code-component'>
              {`<Dropdown>`} {`<Button>`} {`<Avatar>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<Dropdown key='more' overlay={MenuUser} trigger={['click']}>`}
              <br />
                {`<Button className='is-bare has-status is-online'>`}
                <br />
                  {`<Avatar src='.public/image/Persona-image_028.png' />`}
                  <br />
                {`</Button>`}
                <br />
              {`</Dropdown>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDropdownUser;