import React from 'react';
import { Input } from 'antd';
import Label from 'components/commons/Label';

interface Props {
  value?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterText = ({
  value,
  label,
  onChange,
  ...props
}: Props): React.ReactElement => {
  return (
    <div className="wac-control">
      <Label>{label}</Label>
      <Input {...props} value={value} onChange={onChange} />
    </div>
  );
};

export default FilterText;
