import React, { useState, useEffect } from 'react';
import { Menu, Button, Modal, Select } from 'antd';
import { useQueryClient } from 'react-query';
import { GET_ALL_LAWSUIT_FOLDER } from 'utils/consts';
import { AxiosResponse } from 'axios';
import { ILawsuitFolderDTO } from 'types';
import { ChevronDownIcon } from 'components/icons';
import { FolderOpenOutlined } from '@ant-design/icons';
import { useToolbar } from 'context/Toolbar';
import useLawsuitReadMany from 'api/mutations/useLawsuitReadMany';
import useLawsuitUnreadMany from 'api/mutations/useLawsuitUnreadMany';
import useLawsuitMoveManyFolder from 'api/mutations/useLawsuitMoveManyFolder';

const Option = Select;

export const toolbarData = [
  {
    type: 'text',
    key: 'tool-share',
    content: 'Compartilhar',
    icon: <ChevronDownIcon />,
    disabled: true,
  },
  {
    type: 'text',
    key: 'tool-move-folder',
    content: 'Mover para pasta',
    icon: <FolderOpenOutlined />,
    disabled: false,
  },
  {
    type: 'text',
    key: 'tool-att',
    content: 'Atualizar',

    disabled: true,
  },
  {
    type: 'text',
    key: 'tool-search',
    content: 'Buscar/Filtrar',
    icon: <ChevronDownIcon />,

    disabled: true,
  },
  {
    type: 'text',
    key: 'tool-mark-as-unread',
    content: 'Marcar como lido/Não lido',
    disabled: false,
  },
  {
    type: 'text',
    key: 'tool-add-process',
    content: 'Cadastrar nº do processo',
    disabled: true,
  },
  {
    type: 'text',
    key: 'tool-notification',
    content: 'Notificações',
    disabled: true,
  },
  {
    type: 'text',
    key: 'tool-settings',
    content: 'Configurações',
    disabled: true,
  },
];

const Toolbar: React.FC = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: readManyMutation } = useLawsuitReadMany();
  const { mutateAsync: unreadManyMutation } = useLawsuitUnreadMany();
  const { mutateAsync: moveFolderMutation } = useLawsuitMoveManyFolder();
  const [isModalFolder, setModalFolder] = useState(false);
  const [folderId, setFolderId] = useState('');
  const { isVisible, selectedClippings, toggleToolbar } = useToolbar();

  useEffect(() => {
    if (isVisible && selectedClippings.length === 0) {
      toggleToolbar();
    } else if (!isVisible && selectedClippings.length > 0) {
      toggleToolbar();
    }
  }, [selectedClippings, isVisible, toggleToolbar]);

  const folders =
    queryClient.getQueryData<AxiosResponse<ILawsuitFolderDTO[]>>(
      GET_ALL_LAWSUIT_FOLDER,
    )?.data ?? [];

  return (
    <Menu
      className={
        isVisible
          ? 'is-secondary wac-toolbar'
          : 'is-secondary wac-toolbar is-hidden'
      }
      mode="horizontal"
    >
      {toolbarData.map(({ key, content, icon, disabled }) => (
        <Menu.Item key={key}>
          <Button
            disabled={disabled}
            type="text"
            key={key}
            onClick={() => {
              if (key === 'tool-mark-as-unread') {
                const areSelectedReaded = selectedClippings.reduce(
                  (total, clip) => total || clip.isRead,
                  false,
                );
                if (!areSelectedReaded) {
                  return readManyMutation(
                    selectedClippings.map((clip) => clip.id),
                  );
                }
                return unreadManyMutation(
                  selectedClippings.map((clip) => clip.id),
                );
              }
              if (key === 'tool-move-folder') {
                return setModalFolder(true);
              }
              return () => null;
            }}
          >
            {icon}
            {content}
          </Button>
        </Menu.Item>
      ))}
      <Modal
        title="Selecione a pasta para mover os recortes"
        visible={isModalFolder}
        onOk={() => {
          return moveFolderMutation({
            idList: selectedClippings.map((clip) => clip.id),
            folderKey: folderId,
          });
        }}
        onCancel={() => setModalFolder(false)}
      >
        <Select onSelect={(id: string) => setFolderId(id)}>
          {folders.map((folder: ILawsuitFolderDTO) => (
            <Option key={folder.id} value={folder.id}>
              {folder.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </Menu>
  );
};

export default Toolbar;
