import React from 'react';

interface Props {
  className?: string;
}

const Group: React.FC<Props> = ({ className, children }) => (
  <div className={`wac-group ${className}`}>{children}</div>
);

export default Group;
