import { useMutation, UseMutationResult } from 'react-query';
import http from 'api/endpoints/http';
import { AxiosResponse } from 'axios';

interface IPasswordRecoveryDTO {
  email: string;
}

export default function useForgotPassword(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IPasswordRecoveryDTO
> {
  const mutation = useMutation((form: IPasswordRecoveryDTO) => {
    return http.post('forgot-password', form);
  });

  return mutation;
}
