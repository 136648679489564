import React from 'react';
import { RightOutlined } from '@ant-design/icons';

interface Props {
  name: string;
  title: string;
  onClick: (name: string) => void;
  current: string;
  disabled?: boolean;
}

const Option = ({
  name,
  title,
  onClick,
  current,
  disabled,
}: Props): React.ReactElement => {
  const handleClick = (): void => {
    onClick(name);
  };

  const dis = disabled ? '  disabled' : '';
  const selected = current === name ? ' selected' : '';
  const className = `geral-option${selected}${dis}`;

  return (
    <button type="button" onClick={handleClick} className={className}>
      {title}
      <RightOutlined />{' '}
    </button>
  );
};

export default Option;
