import React, { useState } from 'react';
import { message, Modal, Select } from 'antd';
import { useQueryClient } from 'react-query';
import { GET_ALL_LAWSUIT_FOLDER } from 'utils/consts';
import { AxiosResponse } from 'axios';
import { ILawsuitFolderDTO } from 'types';
import { useToolbar } from 'context/Toolbar';
import useLawsuitMoveManyFolder from 'api/mutations/useLawsuitMoveManyFolder';

const Option = Select;

const useMoveFolderModal = (): {
  isModalFolder: boolean;
  showModal: () => void;
  handleCancel: () => void;
  MoveFolderModal: React.FC<{ lawsuits?: string[] }>;
} => {
  const [isModalFolder, setModalFolder] = useState(false);

  const MoveFolderModal: React.FC<{
    lawsuits?: string[];
  }> = ({ lawsuits }) => {
    const queryClient = useQueryClient();

    const [folderId, setFolderId] = useState('');
    const { selectedClippings } = useToolbar();

    const {
      mutateAsync: lawsuitMoveManyFolderMutation,
    } = useLawsuitMoveManyFolder();

    const folders =
      queryClient?.getQueryData<AxiosResponse<ILawsuitFolderDTO[]>>(
        GET_ALL_LAWSUIT_FOLDER,
      )?.data ?? [];

    let clippings: string[];
    if (selectedClippings.length === 0 && lawsuits) {
      clippings = [...lawsuits];
    } else {
      clippings = selectedClippings.map((clip) => clip.id);
    }

    return (
      <>
        <Modal
          title="Selecione a pasta para mover os recortes"
          visible={isModalFolder}
          onOk={async () => {
            try {
              await lawsuitMoveManyFolderMutation({
                idList: clippings,
                folderKey: folderId,
              });
              const folderName = folders.find(
                (folder) => folder.id === folderId,
              )?.name;
              message.success(
                `Os recortes selecionados foram movidos para a pasta ${folderName}`,
              );
            } catch (errorDescription) {
              message.error(errorDescription);
            }
          }}
          onCancel={() => setModalFolder(false)}
        >
          <Select onSelect={(id: string) => setFolderId(id)}>
            {folders.map((folder: ILawsuitFolderDTO) => (
              <Option key={folder.id} value={folder.id}>
                {folder.name}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    );
  };
  const showModal = (): void => {
    setModalFolder(true);
  };
  const handleCancel = (): void => {
    setModalFolder(false);
  };

  return { isModalFolder, showModal, handleCancel, MoveFolderModal };
};
export default useMoveFolderModal;
