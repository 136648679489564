import React from 'react';
import { Select } from 'antd';
import Label from 'components/commons/Label';
import { SelectValue } from 'antd/lib/select';

const { Option } = Select;

interface Props {
  onChange: (e: SelectValue) => void;
  value?: SelectValue;
}

const DegreeSelect = ({ value, onChange }: Props): React.ReactElement => (
  <>
    <Label>Instância</Label>
    <Select value={value} onChange={onChange} allowClear defaultValue="">
      <Option value={1}>Instância 1</Option>
      <Option value={2}>Instância 2</Option>
    </Select>
  </>
);

export default DegreeSelect;
