import React from 'react';
import SelectField from 'components/form/SelectField';
import { ROLES } from 'utils/consts';
import { Control, FieldErrors } from 'react-hook-form';
import useRoleGetAll from 'api/queries/useRoleGetAll';

interface Props {
  name: string;
  label: string;
  control: Control;
  errors: FieldErrors;
}

const RoleSelectField = ({
  name,
  label,
  control,
  errors,
}: Props): React.ReactElement => {
  const { isLoading, data: queryResult } = useRoleGetAll();

  if (isLoading) {
    return (
      <SelectField
        name=""
        initialValue=""
        label={label}
        options={[]}
        control={control}
        errors={errors}
        isLoading={isLoading}
      />
    );
  }

  const roles = queryResult?.data || [];
  return (
    <SelectField
      name={name}
      label={label}
      options={roles.map(
        ({ id, name: value }: { id: string; name: string }) => ({
          value: id.toLowerCase(),
          label: ROLES[value.toLowerCase()],
        }),
      )}
      control={control}
      errors={errors}
    />
  );
};

export default RoleSelectField;
