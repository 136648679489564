import React from 'react';

const ShareAndroidSvg = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7827 5.50151C12.7827 4.11996 13.9027 3 15.2842 3C16.6658 3 17.7857 4.11996 17.7857 5.50151C17.7857 6.88305 16.6658 8.00302 15.2842 8.00302C14.4842 8.00302 13.772 7.62749 13.3141 7.0431L8.69772 9.35127C8.75316 9.55816 8.78271 9.77563 8.78271 10C8.78271 10.2243 8.75318 10.4417 8.69779 10.6485L13.3154 12.9573C13.7731 12.3744 14.4842 12 15.2827 12C16.6634 12 17.7827 13.1193 17.7827 14.5C17.7827 15.8807 16.6634 17 15.2827 17C13.902 17 12.7827 15.8807 12.7827 14.5C12.7827 14.2757 12.8123 14.0583 12.8677 13.8514L8.25013 11.5427C7.79244 12.1256 7.08131 12.5 6.28271 12.5C4.902 12.5 3.78271 11.3807 3.78271 10C3.78271 8.61929 4.902 7.5 6.28271 7.5C7.08121 7.5 7.79226 7.87435 8.24996 8.45712L12.8672 6.1485C12.8121 5.94213 12.7827 5.72525 12.7827 5.50151ZM15.2842 4C14.455 4 13.7827 4.67225 13.7827 5.50151C13.7827 6.33077 14.455 7.00302 15.2842 7.00302C16.1135 7.00302 16.7857 6.33077 16.7857 5.50151C16.7857 4.67225 16.1135 4 15.2842 4ZM15.2827 13C14.4543 13 13.7827 13.6716 13.7827 14.5C13.7827 15.3284 14.4543 16 15.2827 16C16.1111 16 16.7827 15.3284 16.7827 14.5C16.7827 13.6716 16.1111 13 15.2827 13ZM4.78271 10C4.78271 10.8284 5.45429 11.5 6.28271 11.5C7.11114 11.5 7.78271 10.8284 7.78271 10C7.78271 9.17157 7.11114 8.5 6.28271 8.5C5.45429 8.5 4.78271 9.17157 4.78271 10Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default ShareAndroidSvg;
