import { DownOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button } from 'antd';
import { SelectValue } from 'antd/lib/select';
import AdvancedFilter from 'components/commons/AdvancedFilter/AdvancedFilter';
import FilterOneDatePicker from 'components/commons/AdvancedFilter/FilterOneDatePicker';
import CourtSelect from 'components/commons/Filter/CourtSelect';
import FilterText from 'components/commons/Filter/FilterText';
import Group from 'components/custom/Group';
import { Moment } from 'moment';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { IProcessListFiltersDTO } from 'types';
import { useProcess } from 'context/Process';
import ResponsibleSelect from 'screens/ProceduralProgress/components/ResponsibleSelect';
import DegreeSelect from 'screens/ProceduralProgress/components/DegreeSelect';

const { Search } = Input;
interface Props {
  onFilter: (filters: IProcessListFiltersDTO) => void;
}

interface ProcessListFilters {
  lawsuitNumber?: string;
  courtId?: SelectValue;
  responsibleUserId?: SelectValue;
  degree?: SelectValue;
  creationDate?: Moment;
  lastMovimentationDate?: Moment;
}

function Filter(props: Props): React.ReactElement {
  const { onFilter } = props;
  const [isActive, setIsActive] = useState(false);
  const [filters, setFilters] = useState<ProcessListFilters>({});

  useProcess<ProcessListFilters>({
    filters,
    callback: (processId: string) =>
      setFilters((prev) => ({ ...prev, lawsuitNumber: processId })),
  });

  const onArrowClick = (): void => setIsActive((prev) => !prev);
  const onFilterClick = (): void => {
    const hasNumber = filters.lawsuitNumber?.replace(/\.|_|-/g, '');
    onFilter({
      ...filters,
      creationDate: filters.creationDate?.toISOString(),
      lastMovimentationDate: filters.lastMovimentationDate?.toISOString(),
      lawsuitNumber: hasNumber ? filters.lawsuitNumber : undefined,
    });
  };

  const onClear = (): void => {
    setFilters({});
    onFilter({});
  };

  const onChangeLawsuitNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    setFilters((prev: ProcessListFilters) => ({
      ...prev,
      lawsuitNumber: value,
    }));
  };

  const onChangeCourt = (value: SelectValue): void =>
    setFilters((prev: ProcessListFilters) => ({ ...prev, courtId: value }));

  const onChangeResponsible = (value: SelectValue): void =>
    setFilters((prev: ProcessListFilters) => ({
      ...prev,
      responsibleUserId: value,
    }));

  const onChangeDegree = (value: SelectValue): void =>
    setFilters((prev: ProcessListFilters) => ({ ...prev, degree: value }));

  const onChangeCreationDate = (value: Moment | null): void =>
    setFilters((prev: ProcessListFilters) => ({
      ...prev,
      creationDate: value || undefined,
    }));

  const onChangeLastMovimentationDate = (value: Moment | null): void =>
    setFilters((prev: ProcessListFilters) => ({
      ...prev,
      lastMovimentationDate: value || undefined,
    }));

  return (
    <>
      <Group>
        <InputMask
          mask="9999999-99.9999.9.99.9999"
          placeholder="Pesquisar"
          onChange={onChangeLawsuitNumber}
          value={filters.lawsuitNumber}
          className="search-ftd"
        >
          <Search onSearch={onFilterClick} />
        </InputMask>
        <Button type="text" onClick={onArrowClick}>
          Filtrar <DownOutlined />
        </Button>
      </Group>
      <AdvancedFilter isActive={isActive}>
        <Row>
          <Col span={22}>
            <Row gutter={[16, 16]}>
              <Col span={5}>
                <InputMask
                  mask="9999999-99.9999.9.99.9999"
                  value={filters.lawsuitNumber}
                  onChange={onChangeLawsuitNumber}
                >
                  <FilterText label="Número do processo" />
                </InputMask>
              </Col>
              <Col span={11}>
                <CourtSelect value={filters.courtId} onChange={onChangeCourt} />
              </Col>
              <Col span={8}>
                <DegreeSelect
                  value={filters.degree}
                  onChange={onChangeDegree}
                />
              </Col>
              <Col span={5}>
                <FilterOneDatePicker
                  label="Data do protocolo"
                  value={filters.creationDate}
                  onChange={onChangeCreationDate}
                />
              </Col>
              <Col span={6}>
                <ResponsibleSelect
                  value={filters.responsibleUserId}
                  onChange={onChangeResponsible}
                />
              </Col>
              {/* TODO waiting backend */}
              {/* <Col span={5}>
                <Label>Tag</Label>
                <SelectField
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Selecione uma tag"
                  defaultValue={['Tag 00']}
                >
                  <Option value="china" label="China">
                    <div className="demo-option-label-item">Tag 01</div>
                  </Option>
                  <Option value="usa" label="USA">
                    <div className="demo-option-label-item">Tag 02</div>
                  </Option>
                  <Option value="japan" label="Japan">
                    <div className="demo-option-label-item">Tag 03</div>
                  </Option>
                  <Option value="korea" label="Korea">
                    <div className="demo-option-label-item">Tag 04</div>
                  </Option>
                </SelectField>
              </Col> */}
              <Col span={5}>
                <FilterOneDatePicker
                  label="Movimentação do dia"
                  value={filters.lastMovimentationDate}
                  onChange={onChangeLastMovimentationDate}
                />
              </Col>

              <Col span={3} className="align-bottom">
                <Button
                  onClick={onFilterClick}
                  className="btn-filter"
                  type="primary"
                >
                  Filtrar
                </Button>
              </Col>
              {Object.keys(filters).length > 0 && (
                <Col span={3} className="align-bottom">
                  <Button type="default" onClick={onClear}>
                    Limpar
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </AdvancedFilter>
    </>
  );
}

export default Filter;
