import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { SelectValue } from 'antd/es/select';

const { Sider } = Layout;

interface Props {
  degree: SelectValue;
}

const Sidebar = ({ degree }: Props): React.ReactElement => {
  const { url } = useRouteMatch();
  return (
    <Sider
      /* due wrong css API in Collapse, we have to use style inline */
      style={{ position: 'sticky', top: '225px' }}
      className="wac-nav-sider nsss"
    >
      <Menu className="is-sider-nav" defaultSelectedKeys={['2']}>
        <Menu.Item key="1">
          Partes do Processo
          <Link to={`${url}/partes-do-processo`} />
        </Menu.Item>
        <Menu.Item key="2">
          Movimentações
          <Link to={`${url}/movimentacoes`} />
        </Menu.Item>
        <Menu.Item key="3">
          Petições diversas
          <Link to={`${url}/peticoes-diversas`} />
        </Menu.Item>
        <Menu.Item key="4">
          {degree === 1
            ? 'Apensos, entranhados e unificados'
            : 'Apensos Vinculados'}
          <Link to={`${url}/apensos`} />
        </Menu.Item>
        <Menu.Item key="5">
          Incidentes, ações incidentais, recursos e execuções de sentenças
          <Link
            to={`${url}/incidentes-acoes-incidentais-recursos-execuções-sentencas`}
          />
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
