import { AxiosResponse } from 'axios';
import lawsuitProgressService from 'api/endpoints/lawsuitProgress';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GET_ALL_LAWSUIT_PROGRESS } from 'utils/consts';
import { ILawsuitProgressDTO } from 'types';

export default function useLawsuitProgressCreate(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  ILawsuitProgressDTO
> {
  const client = useQueryClient();
  const mutation = useMutation(
    (params: ILawsuitProgressDTO): Promise<AxiosResponse<void>> => {
      return lawsuitProgressService.create(params);
    },
    {
      onSuccess: () => client.invalidateQueries(GET_ALL_LAWSUIT_PROGRESS),
    },
  );

  return mutation;
}
