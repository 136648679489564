import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, message } from 'antd';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import Container from 'screens/Login/components/Container';
import useResetPassword from 'api/mutations/useResetPassword';

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

// interface ResetPasswordProps {
//   token?: string;
// }

const passResetSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'As senhas digitadas não coincídem')
    .required(),
});

const PasswordReset: React.FC = () => {
  const { mutateAsync: resetPasswordMutation } = useResetPassword();
  const {
    control,
    setError,
    handleSubmit,
    errors,
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(passResetSchema),
  });

  const onSubmit = async (form: ResetPasswordForm): Promise<void> => {
    try {
      await resetPasswordMutation(form);
      message.success('Senha alterada com sucesso!');
    } catch (errorDescription) {
      setError('password', {
        message:
          'Houve algum erro durante a requisição. Verifique se as senhas estao corretas!',
      });
    }
  };

  return (
    <Container>
      <Form onFinish={handleSubmit(onSubmit)}>
        <Form.Item
          help={errors.password?.message}
          validateStatus={errors.password ? 'error' : ''}
        >
          <Controller
            name="password"
            control={control}
            as={<Input.Password placeholder="password" />}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={(renderProps) => (
              <Form.Item
                help={errors.confirmPassword?.message}
                validateStatus={errors.confirmPassword ? 'error' : ''}
              >
                <Input.Password {...renderProps} placeholder="password" />
              </Form.Item>
            )}
          />
        </Form.Item>
        <Button className="is-primary" htmlType="submit">
          Resetar senha!
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordReset;
