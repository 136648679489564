import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ICompanyDTO } from 'types';
import { GET_USER_ALL_COMPANIES } from 'utils/consts';
import userService from 'api/endpoints/user';

export default function useUserGetAllCompanies(): UseQueryResult<
  AxiosResponse<ICompanyDTO[]>
> {
  const query = useQuery(
    GET_USER_ALL_COMPANIES,
    userService.getAllUserCompanies,
    { retry: false },
  );

  return query;
}
