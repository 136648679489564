import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacAdvancedFilter = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
      AdvancedFilter
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
            Componente pai com estilos do filtro avançado.
            </div> 
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<div className="advanced-filter">`}
              <br />
                {`<div className="advanced-filter-content">`}
                <br />
                  {`{children} `}
                  <br />
                {`</div>`}
                <br />
              {`</div>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacAdvancedFilter;