import React from 'react';
import { DownOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import Button from 'components/commons/Button';
import { Checkbox, Form, Input, message, Typography } from 'antd';
import { LoginForm } from 'context/Auth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useLogin from 'api/mutations/useLogin';
import Container from './components/Container';

const { Link } = Typography;

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail não valido')
    .required('Entre com o seu e-mail cadastrado!'),
  password: yup.string().required('Entre com a senha!'),
});

const Login: React.FC = () => {
  const { mutateAsync: loginMutation, isLoading } = useLogin();
  const { handleSubmit, errors, control, setError } = useForm<LoginForm>({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<LoginForm> = async (form: LoginForm) => {
    try {
      await loginMutation(form);
      message.success('Autenticado com sucesso!', 4);
    } catch (errorDescription) {
      setError('email', {});
      setError('password', {
        message:
          'Houve algum erro na autenticaçao! Confira a senha e o e-mail!',
      });
    }
  };

  return (
    <Container>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="email"
          render={(props) => (
            <Form.Item
              {...props}
              validateStatus={errors.email ? 'error' : ''}
              help={errors.email?.message}
            >
              <Input
                size="large"
                placeholder="E-mail"
                prefix={<UserOutlined />}
              />
            </Form.Item>
          )}
        />

        <Controller
          control={control}
          name="password"
          render={(props) => (
            <Form.Item
              {...props}
              validateStatus={errors.password ? 'error' : ''}
              help={errors.password?.message}
            >
              <Input.Password
                size="large"
                placeholder="Senha"
                prefix={<KeyOutlined />}
              />
            </Form.Item>
          )}
        />
        {errors?.email?.message}
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Lembrar</Checkbox>
        </Form.Item>

        <Form.Item>
          <div
            className="login-buttons"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button htmlType="reset">Limpar</Button>
            <Button
              className="is-primary"
              htmlType="submit"
              loading={isLoading}
            >
              <DownOutlined />
              Entrar
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Link href="/forgot-password">Esqueci a minha senha</Link>
    </Container>
  );
};

export default Login;
