import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacProcessList = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        ProcessList
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de tabela com checkbox e row clicável atualizada a partir 
              da <span style={{color: "#f58220"}}>{`<Table>`}</span> do antdesign.
            </div> 
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
                {`const columns = [`}
                <br />
                  {`{`}
                  <br />
                    {`title: 'Nome',`}
                    <br />
                    {`dataIndex: 'name',`}
                    <br />
                  {`},`}
                  <br />
                  {`{`}
                  <br />
                    {`title: 'Número',`}
                    <br />
                    {`dataIndex: 'number',`}
                    <br />
                  {`},`}
                  <br />
                {`];`}
                <br />
                <br />
                {`const data = [];`}
                <br />
                {`for (let i = 0; i < 15; i++) {`}
                <br />
                  {`data.push({`}
                  <br />
                    {`key: i,`}
                    <br />
                    {`name: 'A',`}
                    <br />
                    {`number: '1',`}
                    <br />
                  {`});`}
                  <br />
                {`}`}
                <br />
                <br />
                {`const rowSelection = {`}
                <br />
                  {`onChange: (selectedRowKeys, selectedRows) => {`}
                  <br />
                    {`console.log(' ');`}
                    <br />
                  {`},`}
                  <br />
                  <br />
                  {`getCheckboxProps: (record) => ({`}
                  <br />
                    {`disabled: record.name === 'Disabled User',`}
                    <br />
                    {`name: record.name,`}
                    <br />
                  {`}),`}
                  <br />
                {`};`}
                <br />
                <br />
                {`const ProcessList = () => {`}
                <br />
                <br />
                  {`const [selectionType] = useState('checkbox');`}
                  <br />
                  <br />
                  {`return (`}
                  <br />
                    {`<div>`}
                    <br />
                      {`<Table `}
                      <br />
                        {`className="boxed"`}
                        <br />
                        {`rowSelection={{`}
                        <br />
                          {`type: selectionType,`}
                          <br />
                          {`...rowSelection,`}
                          <br />
                        {`}}`}
                        <br />
                        {`columns={columns} `}
                        <br />
                        {`dataSource={data}`}
                        <br />
                        {`onRow={() => {`}
                          {`onClick: event => {`}
                            {`push('/');`}
                          {`}`}
                        {`}} />`}
                        <br />
                    {`</div>`}
                    <br />
                  {`);`}
                  <br />
                {`};`}

              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacProcessList;