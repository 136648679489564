import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

const { Title, Text } = Typography;

interface Props {
  lawsuitNumber: string;
  dadosProcesso: string[][];
}

const buttonStyles = { marginTop: '65px' };

const GenTitleSubTitle = ({
  lawsuitNumber = 'Não fornecido',
  dadosProcesso,
}: Props): React.ReactElement => {
  const { push } = useHistory();
  return (
    <Row gutter={[0, 0]}>
      <Col span={8}>
        <div>
          <Title className="is-size-8">Acompanhamento processual</Title>
          <Text className="is-size-13">Processo: {lawsuitNumber}</Text>
        </div>
      </Col>
      <Col span={14}>
        <Row gutter={[16, 16]}>
          {dadosProcesso.slice(0, 6).map((dado) => (
            <Col span={12}>
              <Text>
                <strong>{`${dado[0]}: `}</strong> {`${dado[1]}`}
              </Text>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={2} className="align-right">
        <Button
          style={buttonStyles}
          className="is-secondary"
          onClick={() => {
            push(`/platform/andamento-processual`);
          }}
        >
          Voltar
        </Button>
      </Col>
    </Row>
  );
};

export default GenTitleSubTitle;
