import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacFilterMultiSelect = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        FilterMultiSelect
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de select do filtro avançado, compostor por:
            </div> 
            <div className='code-component'>
            {'<Label>'} {'<SelectField>'}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<div>`}
              <br />
                {`<Label>{label}</Label>`}
                <br />
                {`<Select`}
                <br />
                  {`mode={!isNotMultiple ? 'multiple' : ''}`}
                  <br />
                  {`showSearch={true}`}
                  <br />
                  {`allowClear={true}`}
                  <br />
                  {`showArrow={true}`}
                  <br />
                  {`maxTagCount={2}`}
                  <br />
                    {`maxTagTextLength={8}>`}
                    <br />
                {`</Select>`}
                <br />
              {`</div>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacFilterMultiSelect;