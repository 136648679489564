import { LoadingOutlined } from '@ant-design/icons';
import { message, Switch, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { ColumnsType } from 'antd/lib/table';
import Group from 'components/custom/Group';
import { DeleteIcon } from 'components/icons';
import React from 'react';
import DrawerEditUser from 'screens/Settings/geral/DrawerEditUser';
import { ROLES } from 'utils/consts';
import { IUserSettings } from 'types';
import useUserActivate from 'api/mutations/useUserActivate';
import useUserDeactivate from 'api/mutations/useUserDeactivate';

const { Text } = Typography;

export const columns = (): ColumnsType<IUserSettings> => [
  {
    title: 'Nome',
    dataIndex: 'name',
    render: (value) => value || '-',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    render: (value) => value || '-',
  },
  {
    title: 'Cargo',
    dataIndex: 'roleName',
    render: (value) => (value ? ROLES[value.toLowerCase()] : '-'),
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    render: renderActions(),
  },
  {
    title: 'Ativo',
    dataIndex: 'activateUser',
    render: enableAction(),
  },
];

const enableAction = () => (_omit: boolean, user: IUserSettings) => {
  const {
    mutateAsync: enableUser,
    isLoading: isEnableLoading,
  } = useUserActivate();
  const {
    mutateAsync: disableUser,
    isLoading: isDisableLoading,
  } = useUserDeactivate();

  const onActive = (): void => {
    confirm({
      title: 'Ativar usuário',
      icon: <DeleteIcon />,
      content: `Tem certeza que deseja ativar o usuário ${user.name}?`,
      cancelButtonProps: {
        className: 'is-secondary',
        ...(isEnableLoading && { disabled: true }),
      },
      okButtonProps: {
        ...(isEnableLoading && { children: <LoadingOutlined /> }),
      },
      onOk: async (close) => {
        try {
          await enableUser(user.id);
          message.success('Usuário ativado com sucesso!');
        } catch (errorDescription) {
          message.error(errorDescription);
        } finally {
          close();
        }
      },
    });
  };

  const onDeactive = (): void => {
    confirm({
      title: 'Desativar usuário',
      icon: <DeleteIcon />,
      content: `Tem certeza que deseja desativar o usuário ${user.name}?`,
      cancelButtonProps: {
        className: 'is-secondary',
        ...(isDisableLoading && { disabled: true }),
      },
      okButtonProps: {
        ...(isDisableLoading && { children: <LoadingOutlined /> }),
      },
      onOk: async (close) => {
        try {
          await disableUser(user.id);
          message.success('Usuário desativado com sucesso!');
        } catch (errorDescription) {
          message.error(errorDescription);
        } finally {
          close();
        }
      },
    });
  };

  const onChange = (newValue: boolean): void => {
    if (newValue) {
      onActive();
    } else {
      onDeactive();
    }
  };

  return (
    <Group>
      <Switch size="small" checked={user.active} onChange={onChange} />
      <Text>{user.active ? 'Ativado' : 'Desativado'}</Text>
    </Group>
  );
};

const renderActions = () => (_omit: boolean, user: IUserSettings) => {
  return (
    <Group>
      <DrawerEditUser data={user} />
    </Group>
  );
};
