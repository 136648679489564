import React from 'react';
import {
  Row,
  Col,
  Divider,
  Dropdown,
  Menu,
  Typography,
  Button,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Box from 'components/custom/Box';
import { CalendarIcon, DataPieIcon, SettingsIcon } from 'components/icons';
import Group from 'components/custom/Group';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const options = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://ant.design/components/dropdown/">
        Doc. dropdown
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://ant.design/components/dropdown/">
        Doc. ant
      </a>
    </Menu.Item>
    <Menu.Item danger>Alerta</Menu.Item>
  </Menu>
);

const dropdown = (
  <Paragraph code={true}>
      <Vspace />
      {'<'}Dropdown overlay={'options'}{'>'}
      <br />
        {'<'}a{'>'}
        <br />
          Dropdown Hover {'<'}DownOutlined /{'>'}
          <br />
        {'<'}/a{'>'}
        <br />
      {'<'}/Dropdown{'>'}

      <Vspace />
      {'<'}Dropdown overlay={'options'} trigger={['click']} {'>'}
      <br />
        {'<'}Button{'>'}
        <br />
          Dropdown Button {'<'}DownOutlined /{'>'}
          <br />
        {'<'}/Button{'>'}
        <br />
      {'<'}/Dropdown{'>'}

      <Vspace />
      {'<'}Group className='is-around'{'>'}
      <br />
      <br />
        {'<'}Dropdown overlay={'options'}{'>'}
        <br />
          {'<'}DataPieIcon /{'>'}
          <br />
        {'<'}/Dropdown{'>'}
        <br />
        <br />
        {'<'}Dropdown overlay={'options'}{'>'}
        <br />
          {'<'}CalendarIcon /{'>'}
          <br />
        {'<'}/Dropdown{'>'}
        <br />
        <br />
        {'<'}Dropdown overlay={'options'}{'>'}
        <br />
          {'<'}SettingsIcon /{'>'}
          <br />
        {'<'}/Dropdown{'>'}
        <br />
        <br />
      {'<'}/Group{'>'}
      <Vspace />
  </Paragraph>
);

const WacDropdown = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Dropdown
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Dropdown overlay={options}>
              <a>
                Dropdown Hover <DownOutlined />
              </a>
            </Dropdown>
          </Box>
          <Box className='code-ex'>
            <Dropdown overlay={options} trigger={['click']}>
              <Button>
                Dropdown Button <DownOutlined />
              </Button>
            </Dropdown>
          </Box>
          <Box className='code-ex'>
            <Group className='is-around'>
              <Dropdown overlay={options}>
                <DataPieIcon />
              </Dropdown>
              <Dropdown overlay={options}>
                <CalendarIcon />
              </Dropdown>
              <Dropdown overlay={options}>
                <SettingsIcon />
              </Dropdown>
            </Group>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {dropdown}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacDropdown;