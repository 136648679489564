import { Row, Col } from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  Route,
  Switch,
  useRouteMatch,
  RouteComponentProps,
} from 'react-router-dom';
import lawsuitService from 'api/endpoints/lawsuit';
import { GET_ALL_LAWSUIT } from 'utils/consts';

import { ILawsuitFiltersDTO, ILawsuitPageDTO } from 'types';

import { useToolbar } from 'context/Toolbar';
import CardList from 'screens/ClippingsAndSummons/components/CardList';
import CardContent, {
  CardContentProps,
} from 'screens/ClippingsAndSummons/components/CardContent';
import Filter from 'screens/ClippingsAndSummons/components/Filter';

export interface ClippingProps {
  status: string;
  topicId: string;
}

interface ClippingDetailProps extends RouteComponentProps<ClippingProps> {
  readStatus?: string;
}

const ClippingDetail = (props: ClippingDetailProps): React.ReactElement => {
  const { path, params } = useRouteMatch<{ status: string }>();

  const [filters, setFilter] = useState<ILawsuitFiltersDTO>({
    isRead: false,
  });
  const onFilter = (newFilters: ILawsuitFiltersDTO): void =>
    setFilter({ ...newFilters });

  const { selectedClippings } = useToolbar();

  useEffect(() => {
    if (params.status === 'nao-lidos') {
      setFilter(() => ({ isRead: false }));
    } else if (params.status === 'lidos') {
      setFilter(() => ({ isRead: true }));
    } else {
      setFilter(() => ({ userFolderId: params.status }));
    }
  }, [params]);

  const {
    data: query,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<ILawsuitPageDTO>(
    [GET_ALL_LAWSUIT, filters, params.status],
    async ({ pageParam = 0 }) => {
      const numberFormatted = filters?.NumberFormatted?.match(/\d+/g)?.join('');
      const { data } = await lawsuitService.getAll({
        filters: {
          ...filters,
          ...(numberFormatted && { NumberFormatted: numberFormatted }),
        },
        pageNumber: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.pageNumber,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );

  const handleResetFilter = (): void => {
    if (params.status === 'nao-lidos') {
      setFilter(() => ({ isRead: false }));
    } else if (params.status === 'lidos') {
      setFilter(() => ({ isRead: true }));
    } else {
      setFilter(() => ({ userFolderId: params.status }));
    }
  };

  return (
    <>
      <Row>
        <Col span={10}>
          <Box className="lightest-background ">
            <Filter onFilter={onFilter} onResetFilter={handleResetFilter} />
            <Vspace />
            <div
              className="clipping-card-list lightest-background"
              id="clipping-card-list"
            >
              <CardList
                {...props}
                nextHandler={fetchNextPage}
                isLoading={isFetching && !isFetchingNextPage}
                pages={query?.pages}
              />
            </div>
          </Box>
        </Col>
        <Col span={14}>
          <Switch>
            <Box className="is-content">
              {selectedClippings.length > 0 ? (
                <>
                  {selectedClippings.map((clipping) => (
                    <div>
                      <Vspace className="is-large" />
                      <CardContent clipping={clipping} filters={filters} />
                    </div>
                  ))}
                  <Vspace className="is-xlarge" />
                </>
              ) : (
                <Route
                  path={`${path}/:topicId`}
                  render={(
                    renderProps: RouteComponentProps<CardContentProps>,
                  ) => <CardContent filters={filters} {...renderProps} />}
                />
              )}
            </Box>
          </Switch>
        </Col>
      </Row>
    </>
  );
};

export default ClippingDetail;
