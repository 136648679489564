import React from 'react';
import { Typography, Skeleton, Empty } from 'antd';

import Vspace from 'components/custom/Vspace';
import Box from 'components/custom/Box';
import { RouteComponentProps } from 'react-router-dom';

import CardContentHead from 'screens/ClippingsAndSummons/components/CardContentHead';
import { ILawsuitCardDTO, ILawsuitFiltersDTO } from 'types';
import CardContentFooter from 'screens/ClippingsAndSummons/components/CardContentFooter';
import useLawsuitGetById from 'api/queries/useLawsuitGetById';

const { Paragraph, Title } = Typography;

export interface CardContentProps {
  status: string;
  topicId: string;
}
interface CardContent {
  filters: ILawsuitFiltersDTO;
  clipping?: ILawsuitCardDTO;
}

type CardContentRouteProps = Partial<RouteComponentProps<CardContentProps>> &
  CardContent;

const CardContent = ({
  clipping,
  match,
  filters,
}: CardContentRouteProps): React.ReactElement => {
  let topicId = match?.params.topicId || null;
  if (clipping) {
    topicId = clipping.id;
  }
  const { data, isFetching } = useLawsuitGetById({ topicId, filters, match });

  if (!topicId) return <Empty />;
  if (isFetching) return <Skeleton active />;
  if (!data) return <Empty />;

  const { totalRelated, lastClipping, relatedClippings } = data;

  if (!lastClipping) return <Empty />;

  return (
    <>
      <Box className="bg-white">
        <CardContentHead lawsuitNumber={data?.id} totalRelated={totalRelated} />
        <Vspace />
        <Title level={1} className="is-size-12 is-600">
          {lastClipping.title}
        </Title>
        <Title level={3} className="is-size-13 is-600 is-pre-wrap">
          {lastClipping.subtitle}
        </Title>
        <Vspace />
        <Paragraph className="is-justify ">{lastClipping.content}</Paragraph>
      </Box>
      <Vspace />

      {relatedClippings && relatedClippings.length > 0 ? (
        <Title level={5} className="is-size-14 is-primary">
          {relatedClippings?.length} Recortes relacionados:
        </Title>
      ) : (
        <></>
      )}

      <CardContentFooter topicId={topicId} clippings={relatedClippings ?? []} />
    </>
  );
};

export default CardContent;
