import { useMutation, UseMutationResult } from 'react-query';
import http from 'api/endpoints/http';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';

interface IResetPasswordDTO {
  password: string;
  confirmPassword: string;
}

export default function useResetPassword(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IResetPasswordDTO
> {
  const location = useLocation();
  const history = useHistory();

  if (!location.search) {
    history.push('/');
  }

  const key = location.search.split('=')[1];

  const mutation = useMutation(async (form: IResetPasswordDTO) => {
    return http.put('reset-password', {
      token: key,
      ...form,
    });
  });

  return mutation;
}
