import lawsuitProgressService from 'api/endpoints/lawsuitProgress';
import { IShareByEmailDTO } from 'types';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GET_ALL_LAWSUIT_PROGRESS } from 'utils/consts';
import { AxiosResponse } from 'axios';

export default function useLawsuitProgressShareByEmail(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IShareByEmailDTO
> {
  const client = useQueryClient();
  const mutation = useMutation(
    (params: IShareByEmailDTO) => lawsuitProgressService.shareByEmail(params),
    {
      onSuccess: () => client.invalidateQueries(GET_ALL_LAWSUIT_PROGRESS),
    },
  );

  return mutation;
}
