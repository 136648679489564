import React from 'react';

interface Props {
  className?: string;
  isActive: boolean;
}

const AdvancedFilter: React.FC<Props> = ({ children, isActive }) => (
  <div className={`advanced-filter${isActive ? ' is-active' : ''}`}>
    <div className="advanced-filter-content">{children}</div>
  </div>
);

export default AdvancedFilter;
