import React from 'react';

import { Dropdown, Menu, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  CommentAddIcon,
  PrintIcon,
  ResizeIcon,
  ShareAndroidIcon,
  UploadIcon,
  ThreeDotsIcon,
} from 'components/icons';
import { ILawsuitCard } from 'types';
import useMoveFolderModal from 'screens/ClippingsAndSummons/components/MoveFolderModal';
import useLawsuitReadMany from 'api/mutations/useLawsuitReadMany';
import useLawsuitUnreadMany from 'api/mutations/useLawsuitUnreadMany';
import useLawsuitProgressCreate from 'api/mutations/useLawsuitProgressCreate';

const CardListDropdown: React.FC<{
  lawsuit: ILawsuitCard;
}> = ({ lawsuit }) => {
  const { mutateAsync: readManyMutation } = useLawsuitReadMany();
  const { mutateAsync: unreadManyMutation } = useLawsuitUnreadMany();
  const { mutateAsync: createMutation, isLoading } = useLawsuitProgressCreate();

  const { showModal: showFolderModal, MoveFolderModal } = useMoveFolderModal();

  const handleMonitorarProgress = async (): Promise<void> => {
    try {
      await createMutation({
        lawsuitNumber: lawsuit?.lawsuitNumber ?? '',
      });
      message.success('O processo monitorado com sucesso!');
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  const handleMoveFolder = (): void => {
    showFolderModal();
  };

  const handleToggleRead = (): void => {
    try {
      if (lawsuit.isRead) {
        unreadManyMutation([lawsuit.id]);
        message.success(
          'Os recortes selecionados foram marcados como não-lidos com sucesso!',
        );
      } else {
        readManyMutation([lawsuit.id]);
        message.success(
          'Os recortes selecionados foram marcados como lidos com sucesso!',
        );
      }
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  if (isLoading) {
    return <LoadingOutlined />;
  }

  const itemCardDropdownData = [
    {
      key: 'mark-as-unread',
      label: 'Marcar como não lido',
      icon: <ResizeIcon />,
      action: () => handleToggleRead(),
      disabled: false,
    },
    {
      key: 'move-to',
      label: 'Mover para',
      icon: <CommentAddIcon />,
      action: () => handleMoveFolder(),
      disabled: false,
    },
    {
      key: 'share',
      label: 'Compartilhar',
      icon: <UploadIcon />,
      action: undefined,
      disabled: true,
    },
    {
      key: 'watch-progress',
      label: 'Monitorar andamento',
      icon: <PrintIcon />,
      action: () => handleMonitorarProgress(),
      disabled: false,
    },
    {
      key: 'wrong-content',
      label: 'Conteúdo incorreto',
      icon: <ShareAndroidIcon />,
      action: undefined,
      disabled: true,
    },
  ];
  const CardDropdownData = (
    <Menu>
      {itemCardDropdownData.map(({ key, disabled, icon, label, action }) => (
        <Menu.Item disabled={disabled} key={key} onClick={action}>
          {icon}
          {label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown key="more" overlay={CardDropdownData} trigger={['click']}>
        <ThreeDotsIcon />
      </Dropdown>

      <MoveFolderModal lawsuits={[lawsuit.id]} />
    </>
  );
};

export default CardListDropdown;
