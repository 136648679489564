import React from 'react';

const AlertCircleSvg = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 19.1668C4.93755 19.1668 0.833496 15.0628 0.833496 10.0002C0.833496 4.93755 4.93755 0.833496 10.0002 0.833496C15.0628 0.833496 19.1668 4.93755 19.1668 10.0002C19.1668 15.0628 15.0628 19.1668 10.0002 19.1668ZM10.0002 17.5002C14.1423 17.5002 17.5002 14.1423 17.5002 10.0002C17.5002 5.85803 14.1423 2.50016 10.0002 2.50016C5.85803 2.50016 2.50016 5.85803 2.50016 10.0002C2.50016 14.1423 5.85803 17.5002 10.0002 17.5002ZM10.0004 14.9988C9.54005 14.9988 9.16683 14.6257 9.16683 14.1655C9.16683 13.7052 9.54005 13.3321 10.0004 13.3321C10.4608 13.3321 10.834 13.7052 10.834 14.1655C10.834 14.6257 10.4608 14.9988 10.0004 14.9988ZM9.16929 4.99878H10.8365V11.6655H9.16929V4.99878Z"
      fill="#DE6600"
    />
  </svg>
);

export default AlertCircleSvg;
