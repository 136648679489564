import React from 'react';
import {
  CalendarIcon,
  CutIcon,
  DataPieIcon,
  DocumentAutosaveIcon,
  DocumentCopyIcon,
  EditIcon,
  LinkIcon,
  SendIcon,
  SettingsIcon,
} from 'components/icons';

const appMenuSidebarData = [
  {
    key: 'recortes-e-intimacoes',
    icon: <CutIcon />,
    tooltip:
      'O Recorte Eletrônico é um serviço de informação jurídica criado para evitar que o profissional do Direito transcorra horas do seu dia buscando manualmente suas intimações dos Diários Oficiais Justiça.',
    label: 'Recortes e intimações',
    link: '/recortes-e-intimacoes/andamento-processual/nao-lidos',
    disabled: false,
  },
  {
    key: 'andamento-processual',
    icon: <DocumentAutosaveIcon />,
    tooltip:
      'Permite a todos consultar o andamento processual e visualizar as peças de natureza pública – como despachos, decisões, sentenças e certidões – expedidas pelo juízo.',
    label: 'Andamento processual',
    link: '/andamento-processual',
    disabled: false,
  },
  {
    key: 'agenda-juridica',
    icon: <CalendarIcon />,
    tooltip:
      'Agenda jurídica é a principal aliada para conquistar diferencial competitivo no direito. Destinar tempo e atenção à organização e ao controle de agendas e calendários em diferentes plataformas e aplicativos pode não ser suficiente para suprir a necessidade de organização dos advogados.',
    label: 'Agenda jurídica',
    link: '/agenda-juridica',
    disabled: true,
  },

  {
    key: 'central-de-processos',
    icon: <DocumentCopyIcon />,
    tooltip: 'Permite verificar todos os processos que estão cadastrados. ',
    label: 'Central de processos',
    link: '/central-de-processos',
    disabled: true,
  },
  {
    key: 'jurimetria',
    icon: <DataPieIcon />,
    tooltip:
      'Jurimetria é a estatística aplicada ao direito, em uma análise simples e direta. Tem sido utilizada em conjunto com softwares jurídicos num modelo de tentar prever resultados e oferecer (daí a questão estatística) probabilidades e valores envolvidos nestas análises.',
    label: 'Jurimetria',
    link: '/jurimetria',
    disabled: true,
  },
  {
    key: 'peticoes',
    icon: <EditIcon />,
    tooltip:
      'Petição é um pedido a uma autoridade, mais comum a um funcionário governamental ou entidade pública. No sentido coloquial, uma petição é um documento oficial assinado por vários indivíduos. Uma petição pode ser oral e não escrita, e recentemente através da Internet.',
    label: 'Petições',
    link: '/peticoes',
    disabled: true,
  },
  {
    key: 'peticionamento',
    icon: <SendIcon />,
    tooltip:
      'Fazer uma solicitação por meio de petição: os trabalhadores peticionaram pelo aumento de salário; o advogado pretende peticionar. [Jurídico] Solicitar algo judicialmente, sendo esta solicitação fundamentada por um juiz ou órgão competente.',
    label: 'Peticionamento',
    link: '/peticionamento',
    disabled: true,
  },
  {
    key: 'Componentes',
    icon: <LinkIcon />,
    tooltip: 'Página de componentes',
    label: 'Componentes',
    link: '/pagina-de-componentes',
    disabled: true,
  },
  {
    key: 'configuracoes',
    icon: <SettingsIcon />,
    tooltip: 'Configuração Gerais da plataforma',
    label: 'Configurações',
    link: '/configuracoes',
    disabled: false,
  },
];

export default appMenuSidebarData;
