import { UseQueryResult, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { IBookDTO } from 'types';
import { GET_ALL_BOOKS } from 'utils/consts';
import bookService from 'api/endpoints/book';

export default function useBookGetAll(): UseQueryResult<
  AxiosResponse<Array<IBookDTO>>
> {
  const query = useQuery<AxiosResponse<Array<IBookDTO>>>(
    GET_ALL_BOOKS,
    bookService.getAll,
  );

  return query;
}
