import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import {
  ICompanyDTO,
  IResponsiblePageDTO,
  IUserSettingsCreateDTO,
  IUserSettingsPageDTO,
  IUserSettingsUpdateDTO,
  IUserSwitchCompanyDTO,
} from 'types';
import { PAGE_SIZE, PAGE_SIZE_MAX } from 'utils/consts';

const userService = {
  getAllResponsibles: (): Promise<AxiosResponse<IResponsiblePageDTO>> => {
    return http.get('/User', { params: { pageSize: PAGE_SIZE_MAX } });
  },
  getAllUserCompanies: (): Promise<AxiosResponse<ICompanyDTO[]>> => {
    return http.get('/User/logged/companies');
  },
  getAllUsers: ({
    pageNumber = 1,
    pageSize = PAGE_SIZE,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }): Promise<AxiosResponse<IUserSettingsPageDTO>> => {
    const params = {
      pageNumber,
      pageSize,
    };
    return http.get(`/customer/logged/members`, { params });
  },
  updateLoggedUserCompany: (
    companyId: string,
  ): Promise<AxiosResponse<IUserSwitchCompanyDTO>> => {
    return http.post(`/User/logged/companies/${companyId}/Switch`);
  },
  createUser: (
    params: IUserSettingsCreateDTO,
  ): Promise<AxiosResponse<void>> => {
    return http.post('/customer/logged/members', params);
  },
  updateUser: (
    params: IUserSettingsUpdateDTO,
  ): Promise<AxiosResponse<void>> => {
    const { id, ...body } = params;
    return http.put(`/customer/logged/members/${id}`, body);
  },
  activateUser: (userId: string): Promise<AxiosResponse<void>> => {
    return http.patch(`/customer/logged/members/${userId}/activate`);
  },
  deactivateUser: (userId: string): Promise<AxiosResponse<void>> => {
    return http.patch(`/customer/logged/members/${userId}/deactivate`);
  },
};

export default userService;
