import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';

const { Title, Text, Paragraph } = Typography

const WacTypography = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        Typography 
      </Divider>

      <Row gutter={[20, 20]}>
          <Col span={6}>
            <Title>h1. Title</Title>
            <Title level={2}>h2. Title</Title>
            <Title level={3}>h3. Title</Title>
            <Title level={4}>h4. Title</Title>
            <Title level={5}>h5. Title</Title>

            <Text>
              Texto com tag span
            </Text>

            <Paragraph>
              Paragrafo com tag div
            </Paragraph>
          </Col>
          <Col span={6}>
            <Title className='is-size-12'>
              Tamanho da fonte
            </Title>
            <Paragraph>
              is-size-1 = 48px <br />
              is-size-2 = 44px <br />
              is-size-3 = 40px <br />
              is-size-4 = 36px <br />
              is-size-5 = 32px <br />
              is-size-6 = 28px <br />
              is-size-7 = 26px <br />
              is-size-8 = 24px <br />
              is-size-9 = 22px <br />
              is-size-10 = 20px <br />
              is-size-11 = 18px <br />
              is-size-12 = 16px <br />
              is-size-13 = 14px <br />
              is-size-14 = 12px <br />
              is-size-15 = 10px
            </Paragraph>
          </Col>
          <Col span={6}>
            <Title className='is-size-12'>
              Cor e status da fonte
            </Title>
            <Paragraph>
              is-primary = @primary <br />
              is-secondary = @secondary <br />
              is-light = @primary-light <br />
              is-white = @white <br />
              is-dark = @dark <br />
              is-darkless = @darkless <br />
              is-danger = @danger <br />
              is-success = @success <br />
              is-warning = @warning
            </Paragraph>
          </Col>
          <Col span={6}>
            <Title className='is-size-12'>
              Peso da fonte
            </Title>
            <Paragraph>
              .is-100 = 100 <br />
              .is-200 = 200 <br />
              .is-300 = 300 <br />
              .is-400 = 400 <br />
              .is-500 = 500 <br />
              .is-600 = 600 <br />
              .is-700 = 700 <br />
              .is-800 = 800 <br />
              .is-900 = 900
            </Paragraph>
          </Col>
        </Row>
    </Box>
  );
}

export default WacTypography;
