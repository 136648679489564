import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Checkbox,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

const check = (
  <Paragraph code={true}>
    <Vspace />
    {`<Checkbox onChange={onChange}>`}
    <br />
      {`CheckBox básico`}
      <br />
    {`</Checkbox>`}
    <Vspace />
  </Paragraph>
);

const WacCheckbox = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Checkbox
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Checkbox onChange={onChange}>
              CheckBox básico
            </Checkbox>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {check}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCheckbox;
