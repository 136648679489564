import React, { useState, createContext, useContext, useEffect } from 'react';

export interface IProcessContext {
  processId: string;
  setProcess: React.Dispatch<React.SetStateAction<string>>;
}

export const ProcessContext = createContext<IProcessContext>(
  {} as IProcessContext,
);

export const ProcessProvider: React.FC = ({ children }) => {
  const [processId, setProcess] = useState('');

  const authContext = { processId, setProcess };

  return (
    <ProcessContext.Provider value={authContext}>
      {children}
    </ProcessContext.Provider>
  );
};

interface Props<IFilter> {
  callback: (processId: string) => void;
  filters: IFilter;
}

export function useProcess<IFilter>({
  callback,
  filters,
}: Props<IFilter>): void {
  const { processId } = useContext<IProcessContext>(ProcessContext);
  const filtersKey = Object.keys(filters).join(',');

  // TODO fix the deps
  return useEffect(() => {
    callback(processId);
  }, [processId, filtersKey]);
}
