import React from 'react';

const StarSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.64372 8.20797L11.3273 2.75967C11.6022 2.20153 12.398 2.20153 12.6729 2.75967L15.3565 8.20797L21.3674 9.07657C21.9829 9.16552 22.229 9.92197 21.7835 10.356L17.436 14.5922L18.4623 20.5779C18.5674 21.1908 17.9238 21.6581 17.3736 21.3682L12.0001 18.5378L6.62662 21.3682C6.07614 21.6582 5.43233 21.1904 5.53798 20.5773L6.56937 14.5922L2.217 10.3563C1.77112 9.9224 2.01704 9.16555 2.63282 9.07657L8.64372 8.20797ZM12.0001 4.78844L9.81479 9.22516C9.70554 9.44698 9.49397 9.60069 9.24924 9.63605L4.35438 10.3434L7.89865 13.7927C8.07585 13.9652 8.15667 14.2139 8.11468 14.4576L7.27478 19.3315L11.6506 17.0266C11.8693 16.9113 12.1308 16.9113 12.3496 17.0266L16.7268 19.3322L15.8909 14.457C15.8492 14.2136 15.9299 13.9653 16.1067 13.7931L19.6469 10.3435L14.7509 9.63605C14.5062 9.60069 14.2947 9.44698 14.1854 9.22516L12.0001 4.78844Z"
      fill="#828282"
    />
  </svg>
);

export default StarSvg;
