import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import lawsuitService from 'api/endpoints/lawsuit';
import { GET_ALL_LAWSUIT, GET_ALL_LAWSUIT_FOLDER } from 'utils/consts';
import { ILawsuitFolderDTO, IMoveManyResultDTO } from 'types';
import { message } from 'antd';

interface IMoveManyDTO {
  idList: string[];
  folderKey: string;
}

export default function useLawsuitMoveManyFolder(): UseMutationResult<
  AxiosResponse<IMoveManyResultDTO[]>,
  unknown,
  IMoveManyDTO
> {
  const queryClient = useQueryClient();
  const folders =
    queryClient.getQueryData<AxiosResponse<ILawsuitFolderDTO[]>>(
      GET_ALL_LAWSUIT_FOLDER,
    )?.data ?? [];
  const mutation = useMutation(
    ({
      idList,
      folderKey,
    }: IMoveManyDTO): Promise<AxiosResponse<IMoveManyResultDTO[]>> =>
      lawsuitService.setMoveManyFolder(idList, folderKey),
    {
      onSuccess: async (data) => {
        const id = data.data[0].userFolderId;
        const folderName = folders.find((folder) => folder.id === id)?.name;
        message.success(
          `Os recortes selecionados foram movidos para a pasta ${folderName}`,
        );
        await queryClient.invalidateQueries(GET_ALL_LAWSUIT);
      },
    },
  );

  return mutation;
}
