import React, { useState } from 'react';
import { Drawer, Button, Form, message } from 'antd';
import Group from 'components/custom/Group';
import { CloseOutlined } from '@ant-design/icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextField from 'components/form/TextField';
import SwitchField from 'components/form/SwitchField';
import TextMaskField from 'components/form/TextMaskField';
import ResponsibleSelectField from 'components/form/ResponsibleSelectField';
import useLawsuitProgressCreate from 'api/mutations/useLawsuitProgressCreate';

interface DrawerForm {
  lawsuitNumber: string;
  note: string;
  monitoring: boolean;
}

const TwoFactorSchema = yup.object().shape({
  lawsuitNumber: yup.string().required('O número do processo é obrigatório'),
  monitoring: yup.boolean(),
});

const DrawerAdd = (): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const { mutateAsync: createMutation } = useLawsuitProgressCreate();
  const { handleSubmit, control, errors } = useForm<DrawerForm>({
    resolver: yupResolver(TwoFactorSchema),
    defaultValues: {
      lawsuitNumber: '',
      note: '',
      monitoring: false,
    },
  });

  const showDrawer = (): void => setVisible(true);

  const onClose = (): void => {
    setVisible(false);
  };

  const onSubmit: SubmitHandler<DrawerForm> = async (form: DrawerForm) => {
    try {
      await createMutation(form);
      message.success('Processo cadastrado com sucesso!');
      onClose();
    } catch (errorDescription) {
      message.error(errorDescription);
    }
  };

  return (
    <>
      <Button className="is-primary" onClick={showDrawer}>
        Cadastrar
      </Button>
      <Drawer
        title="Cadastrar novo processo"
        placement="right"
        width={340}
        onClose={onClose}
        visible={visible}
        mask={false}
        closeIcon={<CloseOutlined />}
      >
        <Form name="lawsuit" onFinish={handleSubmit(onSubmit)}>
          <TextMaskField
            name="lawsuitNumber"
            mask="9999999-99.9999.9.99.9999"
            label="Número do Processo"
            control={control}
            errors={errors}
          />
          <ResponsibleSelectField
            name="responsibleUserId"
            label="Responsável"
            control={control}
            errors={errors}
          />
          <TextField
            name="note"
            initialValue=""
            label="Observação (opcional)"
            control={control}
            errors={errors}
          />
          <SwitchField
            name="monitoring"
            control={control}
            label="Monitorar processo?"
            errors={errors}
          />
          <Group className="is-right">
            <Button className="is-secondary is-small" onClick={onClose}>
              Cancelar
            </Button>
            <Button htmlType="submit" className="is-primary is-small">
              Salvar e fechar
            </Button>
          </Group>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerAdd;
