import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import { IBookDTO } from 'types';

const bookService = {
  getAll: (): Promise<AxiosResponse<Array<IBookDTO>>> => {
    return http.get('/Book');
  },
};

export default bookService;
