import axios from 'axios';
import env from 'environment';
import { getTokenSession, setTokenSession } from 'utils/session';
import { buildError } from 'utils/handlers';

const http = axios.create({
  baseURL: env.URL,
});

http.interceptors.request.use(
  (request) => {
    const token = getTokenSession();
    if (request.url !== 'authenticate' && token) {
      request.headers.Authorization = `Bearer ${token} `;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { location } = window;
    const { status } = error?.response;
    if (status === 401) {
      setTokenSession('');
      location.pathname = '/';
    } else if (status === 403) {
      location.pathname = '/2fa';
    }
    const errorDescription = buildError(error);
    return Promise.reject(errorDescription);
  },
);

export default http;
