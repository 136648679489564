import React from 'react';

import { Collapse, Typography } from 'antd';

interface Clipping {
  body: {
    id: string;
    title: string;
    subtitle: string;
    content: string;
  };
  header: string;
}

interface CardContentFooterProps {
  topicId: string;
  clippings: Clipping[];
}

const { Panel } = Collapse;
const { Paragraph, Title } = Typography;

const CardContentFooter: React.FC<CardContentFooterProps> = ({ clippings }) => {
  return (
    <>
      <Collapse>
        {clippings.map((clipping) => (
          <Panel key={clipping.body.id} header={clipping.header}>
            <Title level={1} className="is-size-12 is-600">
              {clipping.body.title}
            </Title>
            <Title level={3} className="is-size-13 is-600 is-pre-wrap">
              {clipping.body.subtitle}
            </Title>
            <Paragraph className="is-justify">
              {clipping.body.content}
            </Paragraph>
          </Panel>
        ))}
      </Collapse>
    </>
  );
};

export default CardContentFooter;
