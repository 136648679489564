import React from 'react';
import { LinkIcon } from 'components/icons';
import PDFProceduralFollow from 'screens/ProceduralFollow/components/PDFProceduralFollow';
import DrawerShare from 'screens/ProceduralFollow/components/DrawerShare';

interface Props {
  url: string;
  lawsuitNumber: string;
}

const GenExtra = ({ url, lawsuitNumber }: Props): React.ReactElement => (
  <>
    <LinkIcon
      href={url}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        window.open(url, '_blank');
      }}
    />
    <PDFProceduralFollow lawsuitNumber={lawsuitNumber} />
    <DrawerShare lawsuitNumber={lawsuitNumber} />
  </>
);

export default GenExtra;
