import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacModalDelete = () => {


  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        ModalDelete
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de modal de exclusão, composto por: 
            </div> 
            <div className='code-component'>
              {'<Title>'} {'<TextField>'} {'<Group>'} {'<GroupChild>'} {'<Tooltip>'}
            </div>
            <Vspace />
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<>`}
              <br />
                {`<BlockedIcon onClick={this.showModal}/>`}
                <br />
                {`<Modal`}
                <br />
                  {`title="Recorte incorreto"`}
                  <br />
                  {`visible={this.state.visible}`}
                  <br />
                  {`onOk={this.handleOk}`}
                  <br />
                  {`onCancel={this.handleCancel}>`}
                  <br />
                  {`<Paragraph>`}
                  <br />
                    {`Ajudenos a melhorar o Outprocess. Insira um comentário para que possamos com mais clareza compreender o motivo pelo qual você recebeu este recorte indevidamente.`}
                    <br />
                  {`</Paragraph>`}
                  <br />
                {`</Modal>`}
                <br />
              {`</>`}
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacModalDelete;