import React from 'react';

const CutSvg = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1196 11.0805L13.7463 13.0535L13.7177 13.0477L13.712 13.0188L15.6562 9.59478L14.6393 9L12.4826 12.798L12.4605 12.7936L11.5565 13.282C11.5328 13.2242 11.5064 13.1673 11.477 13.1113C11.142 12.4724 10.4905 12.0756 9.77699 12.0756C9.46405 12.0756 9.15208 12.1546 8.87435 12.3045C8.42058 12.5496 8.08788 12.9596 7.93787 13.4587C7.78765 13.9578 7.83812 14.4863 8.07967 14.9466C8.41452 15.5855 9.06583 15.9825 9.77953 15.9825C10.0927 15.9825 10.405 15.9033 10.683 15.7532C11.1365 15.5081 11.4692 15.0981 11.6192 14.5992C11.6194 14.5984 11.6196 14.5976 11.6198 14.5968L12.6422 14.0447L12.72 14.0604L12.7357 14.14L12.1904 15.1799C11.7092 15.3286 11.2967 15.6633 11.0526 16.1283C10.5541 17.0792 10.9104 18.2646 11.8471 18.7704C12.1248 18.9207 12.4372 18.9999 12.7503 18.9999C13.4638 18.9999 14.1153 18.6029 14.4502 17.9638C14.9489 17.0135 14.5928 15.8283 13.6557 15.3215C13.6009 15.2919 13.5438 15.2673 13.4865 15.243L13.9685 14.3239L13.964 14.3008L17.706 12.1123L17.1196 11.0805ZM10.4966 14.2516C10.4381 14.4462 10.3084 14.6062 10.1316 14.7019C10.0215 14.7614 9.90275 14.7916 9.77934 14.7916C9.50043 14.7916 9.24597 14.6367 9.11531 14.3872C9.02104 14.2075 9.00128 14.0015 9.05996 13.8065C9.11864 13.6119 9.24831 13.4517 9.42512 13.3561C9.53524 13.2967 9.65357 13.2667 9.77679 13.2667C10.0557 13.2667 10.3104 13.4216 10.441 13.6711C10.5355 13.8508 10.555 14.0568 10.4966 14.2516ZM13.4142 17.4044C13.2833 17.6539 13.0289 17.809 12.7499 17.809C12.6267 17.809 12.5086 17.7788 12.3983 17.7192C12.0327 17.5219 11.8938 17.0593 12.0883 16.6883C12.2191 16.4388 12.4738 16.2839 12.7525 16.2839C12.8757 16.2839 12.9938 16.3139 13.1036 16.3732C13.4695 16.571 13.6088 17.0335 13.4142 17.4044Z"
      fill="#DE6600"
    />
    <path
      d="M15.7924 2H7.48427C6.05786 2 4.89746 3.17754 4.89746 4.62529V19.3746C4.89746 20.8221 6.05765 21.9999 7.48427 21.9999H18.0756C19.5018 21.9999 20.6622 20.8223 20.6622 19.3746V7.52432L15.7924 2ZM16.1329 4.26272L18.6676 7.13778H16.1329V4.26272ZM18.0756 20.7489H7.48427C6.73738 20.7489 6.12996 20.1324 6.12996 19.3746V4.62529C6.12996 3.86743 6.73738 3.25093 7.48427 3.25093H14.9004V8.38871H19.4297V19.3746C19.4297 20.1324 18.8222 20.7489 18.0756 20.7489Z"
      fill="#DE6600"
    />
  </svg>
);

export default CutSvg;
