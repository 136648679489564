import React from 'react';

const ProceduralTransmissionSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8949 1.99976H6.58681C5.1604 1.99976 4 3.1773 4 4.62504V19.3743C4 20.8219 5.16019 21.9996 6.58681 21.9996H17.1781C18.6043 21.9996 19.7647 20.8221 19.7647 19.3743V7.52407L14.8949 1.99976ZM15.2355 4.26248L17.7701 7.13754H15.2355V4.26248ZM17.1781 20.7487H6.58681C5.83992 20.7487 5.2325 20.1322 5.2325 19.3743V4.62504C5.2325 3.86719 5.83992 3.25069 6.58681 3.25069H14.003V8.38847H18.5322V19.3743C18.5322 20.1322 17.9248 20.7487 17.1781 20.7487Z"
      fill="#828282"
    />
    <path
      d="M11.6055 9.16774L8.15864 12.8177L8.11358 12.8717C7.94837 13.0967 7.96339 13.422 8.15864 13.6288L8.20966 13.6765C8.42213 13.8514 8.72936 13.8355 8.9246 13.6288L11.4468 10.9578V18.1762L11.4505 18.2431C11.4818 18.5284 11.7107 18.7498 11.9885 18.7498L12.0516 18.7459C12.321 18.7128 12.5301 18.4704 12.5301 18.1762V10.9578L15.0523 13.6288L15.1033 13.6765C15.3158 13.8514 15.623 13.8355 15.8183 13.6288C16.0298 13.4048 16.0298 13.0416 15.8183 12.8177L12.3714 9.16774L12.3204 9.12003C12.1079 8.94509 11.8007 8.96099 11.6055 9.16774Z"
      fill="#828282"
    />
  </svg>
);

export default ProceduralTransmissionSvg;
