import React from 'react';

import { Typography, Tooltip } from 'antd';

import Group from 'components/custom/Group';
import GroupChild from 'components/custom/GroupChild';

import {
  ShareAndroidIcon,
  UploadIcon,
  CommentAddIcon,
  ResizeIcon,
  PrintIcon,
} from 'components/icons';

const { Title, Text } = Typography;

const itemHeadData = [
  {
    title: 'Tela cheia',
    content: <ResizeIcon />,
  },
  {
    title: 'Inserir comentário',
    content: <CommentAddIcon />,
  },
  {
    title: 'Exportar',
    content: <UploadIcon />,
  },
  {
    title: 'Imprimir',
    content: <PrintIcon />,
  },
  {
    title: 'Compartilhar',
    content: <ShareAndroidIcon />,
  },
  {
    title: 'Recorte incorreto',
    content: <ShareAndroidIcon />,
  },
];

const DrawerHeader = (): React.ReactElement => {
  return (
    <>
      <Title>Visualização de arquivo</Title>
      <br />
      <Text>Nome: Petição número 123242</Text>
      <br />
      <Text>Tipo:: Word</Text>
      <br />
      <Text>Tamanho:33kb</Text>
      <br />
      <Group className="is-spread">
        <GroupChild>
          {itemHeadData.map(({ title, content }) => (
            <Tooltip mouseEnterDelay={0.7} title={title}>
              {content}
            </Tooltip>
          ))}
        </GroupChild>
      </Group>
    </>
  );
};

export default DrawerHeader;
