import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import { IForumDTO } from 'types';

const forumService = {
  getAllByCourt: (
    courtId: string,
  ): Promise<AxiosResponse<Array<IForumDTO>>> => {
    return http.get('/Forum', { params: { courtId } });
  },
};

export default forumService;
