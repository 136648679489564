import SelectField from 'components/form/SelectField';
import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { IResponsibleInfoDTO } from 'types';
import useUserGetAllResponsibles from 'api/queries/useUserGetAllResponsibles';

interface Props {
  name: string;
  label: string;
  control: Control;
  errors: FieldErrors;
  mode?: 'tags' | 'multiple' | undefined;
}

const ResponsibleSelectField = ({
  name,
  label,
  control,
  errors,
  mode,
}: Props): React.ReactElement => {
  const { isLoading, data: result } = useUserGetAllResponsibles();

  if (isLoading) {
    return (
      <SelectField
        name=""
        initialValue=""
        label={label}
        options={[]}
        control={control}
        errors={errors}
        isLoading={isLoading}
      />
    );
  }

  const axiosData = result?.data;
  const responsibles = axiosData?.data ?? [];

  return (
    <SelectField
      name={name}
      label={label}
      options={responsibles.map(({ id, name: value }: IResponsibleInfoDTO) => ({
        value: id.toLowerCase(),
        label: value,
      }))}
      mode={mode}
      control={control}
      errors={errors}
    />
  );
};

export default ResponsibleSelectField;
