import { ILawsuitDetailDTO } from 'types';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';

import lawsuitProgressService from 'api/endpoints/lawsuitProgress';
import { useParams } from 'react-router-dom';
import { SelectValue } from 'antd/lib/select';
import { GET_LAWSUIT_PROGRESS_DETAIL } from 'utils/consts';

interface Props {
  degree?: SelectValue;
}

interface Params {
  topicId: string;
}

export default function useLawsuitProgressGetDetail({
  degree,
}: Props): UseQueryResult<AxiosResponse<ILawsuitDetailDTO>> {
  const params = useParams<Params>();
  const query = useQuery<AxiosResponse<ILawsuitDetailDTO>>(
    [GET_LAWSUIT_PROGRESS_DETAIL, { degree }],
    ({ queryKey }) => {
      const [_ignore, { degree: newDegree }] = queryKey;
      return lawsuitProgressService.getDetail({
        lawsuitNumber: params.topicId,
        degree: newDegree,
      });
    },
  );

  return query;
}
