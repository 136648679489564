import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Select,
  Button,
  Tooltip,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';
import Group from 'components/custom/Group';

const { Paragraph } = Typography

const tooltip = (
  <Paragraph code={true}>
    <Vspace />
    {`<Group className='is-around'>`}
    <br />
    {`<Tooltip mouseEnterDelay={0.7} title={'Texto tooltip'}>`}
    <br />
      {`Tooltip 1`}
      <br />
    {`</Tooltip>`}
    <Vspace />
    {`<Tooltip mouseEnterDelay={0.2} title={'Texto tooltip'}>`}
    <br />
      {`<Button className='is-secondary'>`}
      <br />
        {`Tooltip botão rápida`}
        <br />
      {`</Button>`}
      <br />
    {`</Tooltip>`}
    <Vspace />
    {`<Tooltip mouseEnterDelay={1.2} title={'Texto tooltip'}>`}
    <br />
      {`Tooltip longa`}
      <br />
    {`</Tooltip>`}
    <br />
    {`</Group>`}
    <Vspace />
  </Paragraph>
);

const WacTooltip = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Tooltip
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Group className='is-around'>
              <Tooltip mouseEnterDelay={0.7} title={'Texto tooltip'}>
                Tooltip 1
              </Tooltip>
              <Tooltip mouseEnterDelay={0.2} title={'Texto tooltip'}>
                <Button className='is-secondary'>
                  Tooltip botão rápida
                </Button>
              </Tooltip>
              <Tooltip mouseEnterDelay={1.2} title={'Texto tooltip'}>
                Tooltip longa
              </Tooltip>
            </Group>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {tooltip}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacTooltip;
