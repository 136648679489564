import React, { useState } from 'react';
import { ResizeIcon } from 'components/icons';
import ModalPDFContent from 'components/commons/PDFMenu/ModalPDFContent';
import { Modal, Tooltip, Typography } from 'antd';
import usePDFWorker from 'hooks/usePDFWorker';
import { LoadingOutlined } from '@ant-design/icons';
import Group from 'components/custom/Group';
import PDFIcon from 'components/commons/PDFMenu/PDFIcon';
import useLawsuitGetClippingDoc from 'api/queries/useLawsuitGetClippingDoc';

interface Props {
  lawsuitNumber: string;
}

const PDFModal: React.FC<Props> = ({ lawsuitNumber }): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const isWorkerLoading = usePDFWorker();
  const { isLoading, error, data: response } = useLawsuitGetClippingDoc({
    lawsuitNumber,
  });

  if (isLoading || isWorkerLoading) {
    return <LoadingOutlined />;
  }

  if (error) {
    return (
      <Tooltip title="Ocorreu um erro ao carregar o PDF. Tente recarregar a página!">
        <ResizeIcon />
      </Tooltip>
    );
  }

  const onResize = (): void => setVisible(true);

  const onClose = (): void => setVisible(false);

  return (
    <div>
      <ResizeIcon onClick={onResize} />
      <Modal
        onCancel={onClose}
        centered
        title={
          <Group className="is-center">
            <Typography.Title level={3} className="mr-5">
              Visualizar PDF
            </Typography.Title>
            <PDFIcon
              pdfData={response?.data}
              isLoading={isLoading}
              error={error}
            />
          </Group>
        }
        width="100%"
        visible={visible}
        footer={null}
      >
        <ModalPDFContent closeModal={onClose} pdfData={response?.data} />
      </Modal>
    </div>
  );
};

export default PDFModal;
