import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacCardContentFooter = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        CardContentFooter
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
            Componente filho do CardContent com o link dos processos relacionados, composto por:
            </div> 
            <div className='code-component'>
            {`<Link>`} {`<Box>`}
            </div>
          </div>
        </Col> 
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />
              {`<>`}
              <br />
                {`{content[0].related.map(({ id }) => (`}
                <br />
                  {`<Link to={'/'} key={id}>`}
                  <br />
                    {`<Box>`}
                    <br />
                      {`{id}`}
                      <br />
                    {`</Box>`}
                    <br />
                  {`</Link>`}
                  <br />
                {`))}`}
                <br />
              {`</>`}
              <br />
              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacCardContentFooter;