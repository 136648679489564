import { IShareByEmailDTO } from 'types';
import { useMutation, UseMutationResult } from 'react-query';
import lawsuitService from 'api/endpoints/lawsuit';
import { AxiosResponse } from 'axios';

export default function useLawsuitShareByEmail(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  IShareByEmailDTO
> {
  const mutation = useMutation((params: IShareByEmailDTO) => {
    return lawsuitService.shareByEmail(params);
  });

  return mutation;
}
