import { Button, Typography, Tooltip } from 'antd';
import { AlertIcon, CourtIcon, HelpCircleIcon } from 'components/icons';
import React from 'react';

import OverlaidContent from 'screens/Main/components/OverlaidContent';
import PopoverAddReport from 'screens/Main/components/PopoverAddReport';
import DropdownUser from 'screens/Main/components/DropdownUser';

const { Text } = Typography;
interface MainNavProps {
  heading?: string;
}

const MainNav: React.FC<MainNavProps> = ({ heading }) => {
  return (
    <>
      <OverlaidContent />
      <Text className="is-size-13 is-white is-600">{heading}</Text>
      <Tooltip
        key="nav-alert"
        mouseEnterDelay={0.7}
        title="Alertas"
        placement="bottomRight"
      >
        <Button key="nav-alert" type="text" icon={<AlertIcon />} />
      </Tooltip>
      <Tooltip
        key="nav-add"
        mouseEnterDelay={0.7}
        title="Cadastro de número de processo"
        placement="bottomRight"
      >
        <Button key="nav-btn-add" type="text" icon={<CourtIcon />} />
      </Tooltip>
      <Tooltip
        key="add-report"
        mouseEnterDelay={0.7}
        title="Boletins de recortes ou andamentos processuais"
        placement="bottomRight"
      >
        <PopoverAddReport />
      </Tooltip>
      <Tooltip
        key="add-help"
        mouseEnterDelay={0.7}
        title="Ajuda"
        placement="bottomRight"
      >
        <Button key="add-help" type="text" icon={<HelpCircleIcon />} />
      </Tooltip>
      <Tooltip mouseEnterDelay={0.7} title="Seu perfil">
        <DropdownUser />
      </Tooltip>
    </>
  );
};

export default MainNav;
