import React from 'react';

interface Props {
  className?: string;
}

const GroupChild: React.FC<Props> = ({ className, children }) => (
  <div className={`wac-group-child ${className}`}>{children}</div>
);

export default GroupChild;
