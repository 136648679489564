import { AxiosResponse } from 'axios';
import http from 'api/endpoints/http';
import { ICourtDTO } from 'types';
import { PAGE_SIZE_MAX } from 'utils/consts';

const getAllCourts = (
  implemented: boolean,
): Promise<AxiosResponse<ICourtDTO[]>> => {
  return http.get('/Court', {
    params: { pageSize: PAGE_SIZE_MAX, implemented },
  });
};

export { getAllCourts };
