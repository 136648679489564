import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const WacProcessPanel = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
        PanelContent
      </Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <div className='wac-box code-ex'>
            <div className='code-description'>
              Componente de painel expanssivo composto por:
            </div> 
            <div className='code-component'>
            {`<Collapse>`} {`<Panel>`}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            <Paragraph code={true}>
              <Vspace />

              {`<Collapse defaultActiveKey={['none']} onChange={callback}>`}
              <br />
                {`<Panel header={GenTitleSubTitle()} key="1" extra={GenExtra()}>`}
                <br />
                  {`{ProcessPanelContent()}`}
                  <br />
                {`</Panel>`}
                <br />
              {`</Collapse>`}
              <br />

              <Vspace />
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacProcessPanel;