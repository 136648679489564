import React from 'react';

const ResizeSvg = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1225 11.1111H14.4802C14.8279 11.1111 15.1098 11.4096 15.1098 11.7778C15.1098 12.1153 14.8729 12.3942 14.5656 12.4384L14.4802 12.4444H11.7521V15.3333C11.7521 15.7015 11.4702 16 11.1225 16C10.8038 16 10.5403 15.7492 10.4987 15.4238L10.4929 15.3333V11.7778C10.4929 11.4403 10.7298 11.1613 11.037 11.1172L11.1225 11.1111H14.4802H11.1225ZM0.629574 11.1111H3.9873C4.30603 11.1111 4.56944 11.3619 4.61113 11.6873L4.61688 11.7778V15.3333C4.61688 15.7015 4.33501 16 3.9873 16C3.66857 16 3.40516 15.7492 3.36348 15.4238L3.35773 15.3333V12.4444H0.629574C0.28187 12.4444 0 12.146 0 11.7778C0 11.4403 0.236849 11.1613 0.544145 11.1172L0.629574 11.1111H3.9873H0.629574ZM3.9873 0C4.30603 0 4.56944 0.250803 4.61113 0.576204L4.61688 0.666667V4.22222C4.61688 4.55973 4.38003 4.83866 4.07273 4.8828L3.9873 4.88889H0.629574C0.28187 4.88889 0 4.59041 0 4.22222C0 3.88471 0.236849 3.60579 0.544145 3.56164L0.629574 3.55556H3.35773V0.666667C3.35773 0.298477 3.6396 0 3.9873 0ZM11.1225 0C11.4412 0 11.7046 0.250803 11.7463 0.576204L11.7521 0.666667V3.55556H14.4802C14.8279 3.55556 15.1098 3.85403 15.1098 4.22222C15.1098 4.55973 14.8729 4.83866 14.5656 4.8828L14.4802 4.88889H11.1225C10.8038 4.88889 10.5403 4.63809 10.4987 4.31269L10.4929 4.22222V0.666667C10.4929 0.298477 10.7748 0 11.1225 0Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default ResizeSvg;
