import React from 'react';
import {
  Row,
  Col,
  Divider,
  Typography,
  Tag,
} from 'antd';
import Box from 'components/custom/Box';
import Vspace from 'components/custom/Vspace';

const { Paragraph } = Typography

const tag = (
  <Paragraph code={true}>
    <Vspace />
      {`<Tag>`}
      <br />
        {`Tag padrão`}
        <br />
      {`</Tag>`}
      <Vspace />
      {`<Tag className='is-secondary'>`}
      <br />
        {`Tag secundária`}
        <br />
      {`</Tag>`}
      <Vspace />
      {`<Tag className='is-danger is-rounded'>`}
      <br />
        {`Tag alerta e redonda`}
        <br />
      {`</Tag>`}
      <Vspace />
      {`<Tag className='is-success'>`}
      <br />
        {`Tag sucesso`}
        <br />
      {`</Tag>`}
      <Vspace />
      {`<Tag className='is-warning is-rounded'>`}
      <br />
        {`Tag de aviso e redonda`}
        <br />
      {`</Tag>`}
    <Vspace />
  </Paragraph>
);

const WacTag = () => {
  return (
    <Box className='is-large'>
      <Divider orientation='left' plain>
          Tag
      </Divider>

      <Vspace />
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Box className='code-ex'>
            <Tag>
              Tag padrão
            </Tag>
          </Box>
          <Box className='code-ex'>
            <Tag className='is-secondary'>
              Tag secundária
            </Tag>
          </Box>
          <Box className='code-ex'>
            <Tag className='is-danger is-rounded'>
              Tag alerta e redonda
            </Tag>
          </Box>
          <Box className='code-ex'>
            <Tag className='is-success'>
              Tag sucesso
            </Tag>
          </Box>
          <Box className='code-ex'>
            <Tag className='is-warning is-rounded'>
              Tag de aviso e redonda
            </Tag>
          </Box>
        </Col>
        <Col span={12}>
          <div className='code-box'>
            {tag}
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default WacTag;
