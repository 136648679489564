import React from 'react';

import { LogoIcon } from 'components/icons';
import Routes from 'screens/Main/components/Routes';
import { useAuth } from 'context/Auth';
import { PageHeader, Layout } from 'antd';
import AppSidebar from 'screens/Main/components/AppSidebar';
import AppMenu from 'screens/Main/components/AppMenu';

import MainNav from './components/MainNav';
import NavigationState from './components/NavigationState';

const { Content } = Layout;

const Main = (): React.ReactElement => {
  const { user } = useAuth();

  const navTitle = user?.CustomerName;
  return (
    <Layout>
      <NavigationState>
        <PageHeader
          className="site-page-header"
          title={<LogoIcon />}
          subTitle="Outprocess R15"
          extra={<MainNav heading={navTitle} />}
        />
        <AppMenu />
        <Layout>
          <AppSidebar />
          <Content>
            <Routes />
          </Content>
        </Layout>
      </NavigationState>
    </Layout>
  );
};

export default Main;
