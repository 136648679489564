import React from 'react';

const CourtSvg = (): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="32"
    viewBox="0 0 37 32"
  >
    <image
      id="Vector"
      width="37"
      height="32"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAgCAYAAACVU7GwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBzVeLcYMwDBUbsEEZgREYIRukG4QNwgZhA7IB3YBuQDdghIyg2ol8vAgHTMqn784XzsTS85NsCaIVwMyxGZ9mZPQfYIgczbhxj4r2gqhzATI1kMtoaxinqRmdELhJ6BIzWpnLaUsYhydQpBUyBxVC+5zS2pBw1eC49ITwKvMOR1oLokYHKmQqXDcMmXkuVlfMGG7ESSNkMISWbOJZg8QSWhqgklWoYhXCkXVXIB7TkoBdYyIfAtbFEOILLQkeJnnwkZdwr3d/8ePo33Nr5rp8tTCKg+6dXcNhKWhJSKI7Ut0ba10+LqOW5AYm+uxdL64WP+qbxZfadTLDBq47/VkxY+AMSlnjrpy0ocaFCOK9NoeH9c2dIiw9VYANXTddCnzQHPCwRckhL2p+voPOL2xk7K+bbi68NrKnRZF5JGJJHkCBs8eGQ8PDuhl23/mkntg59lF3YuK8gblc7FZol0Ny0Se1eo+51cJzzf2t7day2LI2dRpM1s2XUsO7wc5hPoX/pYpoPGZ3jIx2WKj3R7XzdMRWDGqkym5JIRBpn6RWDnSrO5kD3PdQ7LM7tVh/HsXwTre/c1oVu5lSVCo59MYHQgOH/OIqWB2gECap92uFtgBDMsKcvgrCjuzCxBohUKncajYLl4dUysObuKCdEFFPLDE/Lkw/URR90074Bf3xharKlnl2AAAAAElFTkSuQmCC"
    />
  </svg>
);

export default CourtSvg;
