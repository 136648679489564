import React from 'react';
import { Menu } from 'antd';

import {
  BookFormulaCompatibilityIcon,
  CertificateIcon,
  ChatHelpIcon,
  ClassIcon,
  CopyMoveIcon,
  MicOnIcon,
  NewsIcon,
  SignedIcon,
  TaskListIcon,
} from 'components/icons';

const appSidebarSubMenuData = [
  {
    key: 'sidesub-podcast',
    icon: <MicOnIcon />,
    label: 'Podcast',
    href: 'https://www.grifon.com.br/podcasts',
  },
  {
    key: 'sidesub-news',
    icon: <NewsIcon />,
    label: 'Jornal Grifon',
    href: 'https://www.grifon.com.br/jornal',
  },
  {
    key: 'sidesub-inquiry',
    icon: <TaskListIcon />,
    label: 'Consultas e Pareceres',
    href: 'https://www.grifon.com.br/consultas-e-pareceres',
  },
  {
    key: 'sidesub-ead',
    icon: <CertificateIcon />,
    label: 'Cursos EAD',
    href: 'https://ead.grifon.com.br/',
  },
  {
    key: 'sidesub-sign',
    icon: <SignedIcon />,
    label: 'Assine',
    href: 'https://www.grifon.com.br/#popup-assinatura-passo-1',
  },
  {
    key: 'sidesub-book',
    icon: <BookFormulaCompatibilityIcon />,
    label: 'Livro',
    href:
      'https://www.amazon.com.br/dp/B07ZTVVB28/ref=cm_sw_em_r_mt_dp_CXFFNQPNJP1659DWNCR9',
  },
  {
    key: 'sidesub-copy',
    icon: <CopyMoveIcon />,
    label: 'Retirada de cópia',
    href: 'https://www.grifon.com.br/retirada-de-copias',
  },
  {
    key: 'sidesub-contact',
    icon: <ChatHelpIcon />,
    label: 'Fale conosco',
    href: 'https://www.grifon.com.br/',
  },
  {
    key: 'sidesub-titles',
    icon: <ClassIcon />,
    label: 'Títulos',
    href: 'https://www.grifon.com.br/titulos',
  },
];

const AppSidebarSubMenu = (): React.ReactElement => {
  return (
    <>
      {appSidebarSubMenuData.map(({ key, icon, label, href }) => (
        <a target="_blank" rel="noopener noreferrer" href={href}>
          <Menu.Item className="wac-submenu is-inverted" key={key} icon={icon}>
            <span className="wac-menu-item-label">{label}</span>
          </Menu.Item>
        </a>
      ))}
    </>
  );
};

export default AppSidebarSubMenu;
