import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, message, Skeleton } from 'antd';
import Group from 'components/custom/Group';
import Vspace from 'components/custom/Vspace';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface ModalContentProps {
  closeModal: () => void;
  pdfData: number | undefined;
}

/** don't forget to call usePDFWorker hook! * */
const ModalPDFContent = ({
  pdfData,
  closeModal,
}: ModalContentProps): React.ReactElement => {
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);

  function onLoadSuccess({
    numPages: newNumPages,
  }: {
    numPages: number;
  }): void {
    setNumPages(newNumPages);
  }

  function onLoadError(): void {
    closeModal();
    message.error('Ocorreu um erro ao carregar o PDF');
  }

  const onNext = (): void => setPage((prev) => prev + 1);

  const onBack = (): void => setPage((prev) => prev - 1);

  const pageWidth = window.innerWidth * 0.75;
  return (
    <>
      <div className="container-pdf-modal">
        <Document
          loading={<Skeleton />}
          onLoadSuccess={onLoadSuccess}
          file={{ data: pdfData }}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
          onLoadError={onLoadError}
        >
          <Page pageNumber={page} width={pageWidth} />
        </Document>
      </div>
      <Vspace />
      <Group className="is-center">
        <Button disabled={page === 1} type="text" onClick={onBack}>
          <LeftOutlined />
        </Button>
        <Button disabled={page === numPages} type="text" onClick={onNext}>
          <RightOutlined />
        </Button>
      </Group>
    </>
  );
};

export default ModalPDFContent;
