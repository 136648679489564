import React from 'react';

const PrintSvg = (): React.ReactElement => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6875 1H14.4375C15.304 1 16.0121 1.67823 16.0599 2.53279L16.0625 2.625V3.9995L16.9375 4C18.3872 4 19.5625 5.17525 19.5625 6.625V13.375C19.5625 14.2725 18.835 15 17.9375 15L16.0625 14.9995V15.875C16.0625 16.7725 15.335 17.5 14.4375 17.5H6.6875C5.79004 17.5 5.0625 16.7725 5.0625 15.875V14.9995L3.1875 15C2.29004 15 1.5625 14.2725 1.5625 13.375V6.625C1.5625 5.17525 2.73775 4 4.1875 4L5.0625 3.9995V2.625C5.0625 1.75848 5.74073 1.05038 6.59529 1.00257L6.6875 1ZM14.4375 11.25H6.6875C6.48039 11.25 6.3125 11.4179 6.3125 11.625V15.875C6.3125 16.0821 6.48039 16.25 6.6875 16.25H14.4375C14.6446 16.25 14.8125 16.0821 14.8125 15.875V11.625C14.8125 11.4179 14.6446 11.25 14.4375 11.25ZM16.9375 5.25H4.1875C3.42811 5.25 2.8125 5.86561 2.8125 6.625V13.375C2.8125 13.5821 2.98039 13.75 3.1875 13.75L5.0625 13.7495V11.625C5.0625 10.7275 5.79004 10 6.6875 10H14.4375C15.335 10 16.0625 10.7275 16.0625 11.625V13.7495L17.9375 13.75C18.1446 13.75 18.3125 13.5821 18.3125 13.375V6.625C18.3125 5.86561 17.6969 5.25 16.9375 5.25ZM14.4375 2.25H6.6875C6.49765 2.25 6.34075 2.39108 6.31592 2.57411L6.3125 2.625V3.9995H14.8125V2.625C14.8125 2.43515 14.6714 2.27825 14.4884 2.25342L14.4375 2.25Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default PrintSvg;
