import React, { useState } from 'react';
import Vspace from 'components/custom/Vspace';
import { Typography } from 'antd';
import Option from 'screens/Settings/geral/Option';
import UserManagement from 'screens/Settings/geral/UserManagement';

const enabledItems = ['userManagement'];

const GeralMenu = (): React.ReactElement => {
  const [current, setCurrent] = useState<string>('userManagement');

  const onOptionClick = (name: string): void => {
    if (enabledItems.includes(name)) {
      setCurrent(name);
    }
  };

  const rightMenu: Record<string, React.ReactElement> = {
    userManagement: <UserManagement />,
  };

  return (
    <div className="w-100 d-flex justify-content-start">
      <div className="geral-menu">
        <Typography.Title level={4}>Dados da Assinatura</Typography.Title>
        <Vspace />
        <Option
          name="signature"
          onClick={onOptionClick}
          current={current}
          title="Minha assinatura"
          disabled
        />
        <Option
          name="logo"
          onClick={onOptionClick}
          current={current}
          title="Editar logomarca"
          disabled
        />
        <Option
          name="userManagement"
          onClick={onOptionClick}
          current={current}
          title="Gerenciar usuários"
        />
        <Option
          name="registrationData"
          onClick={onOptionClick}
          current={current}
          title="Atualizar dados cadastrais"
          disabled
        />
      </div>
      {rightMenu[current]}
    </div>
  );
};

export default GeralMenu;
