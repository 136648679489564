import { LoadingOutlined } from '@ant-design/icons';
import { message, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Group from 'components/custom/Group';
import {
  StarIcon,
  EyeIcon,
  EyeHideIcon,
  StarMarkedIcon,
  OutlineEditIcon,
} from 'components/icons';
import React, { MouseEvent, useState } from 'react';
import { ILawsuit, ILawsuitProgressInfoDTO, IUserDTO } from 'types';
import { renderAmount, renderDate, renderDegree } from 'utils/table';
import useLawsuitProgressSetRead from 'api/mutations/useLawsuitProgressSetRead';
import useLawsuitProgressSetStarred from 'api/mutations/useLawsuitProgressSetStarred';

const renderActions = () => (_omit: boolean, record: ILawsuit) => {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateReadMutation } = useLawsuitProgressSetRead();
  const { mutateAsync: updateStarMutation } = useLawsuitProgressSetStarred();

  const onReadClick = async (
    event: MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.stopPropagation();
    setLoading(true);
    try {
      const messageDescription = record.userHistories[0]?.read
        ? 'Processo marcado como não lido!'
        : 'Processo marcado como lido!';
      await updateReadMutation(record);
      message.success(messageDescription);
    } catch (errorDescription) {
      message.error(errorDescription);
    } finally {
      setLoading(false);
    }
  };

  const onStarClick = async (
    event: MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.stopPropagation();
    setLoading(true);
    try {
      const messageDescription = record.userHistories[0]?.starred
        ? 'Processo marcado como não favorito!'
        : 'Processo marcado como favorito!';
      await updateStarMutation(record);
      message.success(messageDescription);
    } catch (errorDescription) {
      message.error(errorDescription);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Group>
      {loading ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Tooltip title="Favorito">
            {record.userHistories[0]?.starred ? (
              <StarMarkedIcon onClick={onStarClick} />
            ) : (
              <StarIcon onClick={onStarClick} />
            )}
          </Tooltip>
          <Tooltip title="Visualizado">
            {record.userHistories[0]?.read ? (
              <EyeIcon onClick={onReadClick} />
            ) : (
              <EyeHideIcon onClick={onReadClick} />
            )}
          </Tooltip>
        </>
      )}
    </Group>
  );
};

const renderStatus = ({ onEdit }: ColumnProps) => (
  _omit: boolean,
  record: ILawsuit,
): React.ReactElement => {
  const showDrawer = (event: React.MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation();
    onEdit(record);
  };

  return (
    <div className="table-actions">
      <div className="show-on-hover">
        <Tooltip title="Editar">
          <OutlineEditIcon className="is-secondary" onClick={showDrawer} />
        </Tooltip>
      </div>
      <div className="show-status">
        <Tag className={record.monitoring ? 'is-primary' : 'is-secondary'}>
          {record.monitoring ? 'Monitorar' : 'Não monitorar'}
        </Tag>
      </div>
    </div>
  );
};

interface ColumnProps {
  onEdit: (data: ILawsuit) => void;
}

export const columns = ({ onEdit }: ColumnProps): ColumnsType<ILawsuit> => [
  {
    title: '',
    dataIndex: 'actions',
    render: renderActions(),
    width: 60,
  },
  {
    title: 'Número do processo',
    dataIndex: 'lawsuitNumber',
    sorter: (a, b) => a.lawsuitNumber.localeCompare(b.lawsuitNumber),
  },
  {
    title: 'Responsável',
    dataIndex: 'responsibleUserName',
    render: (value) => value || '-',
  },
  {
    title: 'Instância',
    dataIndex: 'degree',
    render: renderDegree,
  },
  {
    title: 'Tribunal',
    dataIndex: 'courtName',
    sorter: (a, b) => a.courtName.localeCompare(b.courtName),
  },
  {
    title: 'Valor da ação',
    dataIndex: 'amount',
    render: renderAmount,
  },
  {
    title: 'Protocolado em',
    dataIndex: 'creationDate',
    render: renderDate,
  },
  {
    title: 'Atualizado em',
    dataIndex: 'lastMovimentationDate',
    render: renderDate,
  },
  {
    title: 'Monitoramento',
    dataIndex: 'monitoring',
    render: renderStatus({ onEdit }),
  },
];

export const mapToTable = (
  data: Array<ILawsuitProgressInfoDTO> | undefined,
): Array<ILawsuit> => {
  return (data || []).reduce(
    (acum: Array<ILawsuit>, element: ILawsuitProgressInfoDTO) => {
      const res = {
        id: element.lawsuitNumber,
        lawsuitNumber: element.lawsuitNumber,
        responsibleUserName: element.responsibleUserName,
        responsibleUserId: element.responsibleUserId,
        degree: element.details?.degree,
        amount: element.details?.amount,
        courtName: element.court?.name,
        creationDate: element.creationDate,
        lastMovimentationDate: element.details?.lastMovimentationDate,
        userHistories: element.userHistories,
        monitoring: element.monitoring,
        responsible: element.responsibleUserName,
        observation: element.note,
      };
      return [...acum, res];
    },
    [],
  );
};

export const handleColorForUser = (user: IUserDTO | undefined): string => {
  const color = `#${user?.UserInitials.charCodeAt(0).toString()}${[
    ...(user?.UserName ?? 'empty').split(''),
  ]
    .reduce(
      (acc: number, ele: string) => (acc + Number(ele.charCodeAt(0))) % 255,
      0,
    )
    .toString(16)}${user?.UserInitials.charCodeAt(1).toString()}`;

  return color;
};
