import { useMutation, UseMutationResult } from 'react-query';
import { TwoFactorResult, useAuth } from 'context/Auth';
import { AxiosError } from 'axios';

interface ITwoFactorAuthDTO {
  input0: string;
  input1: string;
  input2: string;
  input3: string;
}

export default function useAuthenticateTwoFactor(): UseMutationResult<
  TwoFactorResult,
  unknown,
  ITwoFactorAuthDTO
> {
  const { authenticate } = useAuth();
  const mutation = useMutation<TwoFactorResult, AxiosError, ITwoFactorAuthDTO>(
    (form: ITwoFactorAuthDTO) =>
      authenticate(`${form.input0}${form.input1}${form.input2}${form.input3}`),
  );

  return mutation;
}
