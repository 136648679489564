import { Layout } from 'antd';
import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  RouteComponentProps,
} from 'react-router-dom';

import Toolbar from 'screens/ClippingsAndSummons/components/Toolbar';
import { ToolbarProvider } from 'context/Toolbar';
import Sidebar from 'screens/ClippingsAndSummons/components/Sidebar';
import ClippingDetail, {
  ClippingProps,
} from 'screens/ClippingsAndSummons/components/ClippingDetail';

const { Content } = Layout;

const ClippingsAndSummons = (): React.ReactElement => {
  const { url, path } = useRouteMatch();

  return (
    <ToolbarProvider>
      <Layout>
        <Toolbar />
        <Layout>
          <Sidebar />
          <Content>
            <Switch>
              <Route
                exact
                path={url}
                render={(renderProps: RouteComponentProps<ClippingProps>) => (
                  <ClippingDetail {...renderProps} />
                )}
              />

              <Route
                path={`${path}/andamento-processual/:status`}
                render={(renderProps: RouteComponentProps<ClippingProps>) => (
                  <ClippingDetail {...renderProps} />
                )}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </ToolbarProvider>
  );
};

export default ClippingsAndSummons;
