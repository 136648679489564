import React from 'react';

const PetitionSvg = (): React.ReactElement => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.292 1.48049C18.3415 2.59961 18.3415 4.41407 17.292 5.5332L8.3035 15.1178C8.09556 15.3395 7.83696 15.4995 7.55325 15.582L3.71124 16.6993C3.29076 16.8216 2.90494 16.4102 3.01961 15.9618L4.06743 11.865C4.14481 11.5625 4.29488 11.2868 4.50282 11.065L13.4913 1.48049C14.5409 0.361362 16.2425 0.361362 17.292 1.48049ZM12.7632 3.95577L5.29941 11.9144C5.23009 11.9884 5.18007 12.0803 5.15428 12.1811L4.36582 15.2638L7.25683 14.4231C7.3514 14.3956 7.43761 14.3423 7.50692 14.2683L14.9705 6.30939L12.7632 3.95577ZM14.2879 2.32989L13.5593 3.10609L15.7666 5.46052L16.4954 4.68379C17.105 4.03378 17.105 2.9799 16.4954 2.32989C15.8858 1.67988 14.8975 1.67988 14.2879 2.32989Z"
      fill="#828282"
    />
  </svg>
);

export default PetitionSvg;
