import React from 'react';

const StarMarkedSvg = (): React.ReactElement => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.64372 6.20797L10.3273 0.759671C10.6022 0.201529 11.398 0.201529 11.6729 0.759671L14.3565 6.20797L20.3674 7.07657C20.9829 7.16552 21.229 7.92197 20.7835 8.35603L16.436 12.5922L17.4623 18.5779C17.5674 19.1908 16.9238 19.6581 16.3736 19.3682L11.0001 16.5378L5.62662 19.3682C5.07614 19.6582 4.43233 19.1904 4.53798 18.5773L5.56937 12.5922L1.217 8.35634C0.771123 7.9224 1.01704 7.16555 1.63282 7.07657L7.64372 6.20797Z"
      fill="#F58220"
    />
  </svg>
);

export default StarMarkedSvg;
