import React, { useContext } from 'react';

import { Typography, Input } from 'antd';

import InputMask from 'react-input-mask';
import { ProcessContext } from 'context/Process';

const { Text } = Typography;

const OverlaidContent = (): React.ReactElement => {
  const { processId, setProcess } = useContext(ProcessContext);
  return (
    <>
      <div className="overlaid-header-content">
        <Text className="is-size-13 is-white no-shrink">Meus Processos</Text>

        <InputMask
          mask="9999999-99.9999.9.99.9999"
          placeholder="9999999-99.9999.9.99.9999"
          onChange={(e) => {
            setProcess(e.currentTarget.value);
          }}
          className="is-size-13 wac-box is-header-overlaid"
          value={processId}
        >
          <Input />
        </InputMask>
      </div>
    </>
  );
};

export default OverlaidContent;
