import { useQuery, UseQueryResult } from 'react-query';
import { ILawsuitClippingDTO, ILawsuitFiltersDTO } from 'types';
import { GET_LAWSUIT } from 'utils/consts';
import lawsuitService from 'api/endpoints/lawsuit';
import useLawsuitSetReadSingle from 'api/mutations/useLawsuitSetReadSingle';
import { RouteComponentProps } from 'react-router-dom';

interface HooksProps {
  topicId: string | null;
  filters: ILawsuitFiltersDTO;
}

interface RouteProps {
  status: string;
}

type Props = Partial<RouteComponentProps<RouteProps>> & HooksProps;

export default function useLawsuitGetById({
  topicId,
  filters,
  match,
}: Props): UseQueryResult<ILawsuitClippingDTO> {
  const { mutateAsync: setReadMutation } = useLawsuitSetReadSingle({
    match,
    filters,
  });

  const query = useQuery<ILawsuitClippingDTO>(
    [GET_LAWSUIT, topicId],
    async () => {
      if (!topicId)
        return new Promise((_, reject) => {
          reject();
        });
      const { data: lawsuit } = await lawsuitService.get(topicId);
      await setReadMutation(topicId);
      return lawsuit;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  return query;
}
