import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { GET_ALL_LAWSUIT_PROGRESS } from 'utils/consts';
import { ILawsuitProgressPageDTO, IProcessListFiltersDTO } from 'types';
import lawsuitProgressService from 'api/endpoints/lawsuitProgress';

interface Props {
  currentPage: number;
  filters: IProcessListFiltersDTO;
}

export default function useLawsuitProgressGetAll({
  currentPage,
  filters,
}: Props): UseQueryResult<AxiosResponse<ILawsuitProgressPageDTO>> {
  const query = useQuery<AxiosResponse<ILawsuitProgressPageDTO>>(
    [GET_ALL_LAWSUIT_PROGRESS, { pageNumber: currentPage, filters }],
    ({ queryKey }): Promise<AxiosResponse<ILawsuitProgressPageDTO>> => {
      const [_ignore, { filters: newFilters, pageNumber }] = queryKey;
      return lawsuitProgressService.getAll({
        pageNumber: pageNumber - 1,
        filters: newFilters,
      });
    },
  );

  return query;
}
